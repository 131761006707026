/* eslint-disable  react/display-name */
import React, { forwardRef, useMemo, useRef, useState } from 'react';
import { SidebarButtonAction, SidebarButtonLink } from './types';
import { useHistory, useLocation } from 'react-router-dom';

import { Overlay } from 'react-bootstrap';
import clsx from 'clsx';
import styles from './SidebarButton.module.scss';

interface Props extends SidebarButtonAction, SidebarButtonLink {
  onClick?: () => void;
  className?: string;
}

// eslint-disable-next-line no-underscore-dangle
const _SidebarButton = forwardRef<HTMLButtonElement, Props>(
  ({ Icon, label, path, onClick, className, subLabel, disabled }, ref) => {
    const history = useHistory();
    const location = useLocation();

    const active = useMemo(() => {
      return path && location.pathname === path;
    }, [location.pathname]);

    const handleClick = () => {
      if (path) {
        history.replace(path);
      } else {
        onClick?.();
      }
    };

    return (
      <button
        className={clsx(
          styles.sidebarButton,
          active && styles.sidebarButtonActive,
          className,
          disabled && styles.sidebarButtonDisabled,
        )}
        disabled={disabled}
        ref={ref}
        onClick={handleClick}>
        {Icon && (
          <Icon
            className={clsx(styles.sidebarButtonIcon, disabled && styles.sidebarButtonIconDisabled)}
          />
        )}
        <div className={styles.sidebarButtonLabel}>
          {label}
          {subLabel && <div className={styles.sidebarButtonSubLabel}>{subLabel}</div>}
        </div>
      </button>
    );
  },
);

const SidebarButton = ({
  Icon,
  label,
  path,
  onClick,
  buttons,
  className,
  subLabel,
  disabled,
}: Props) => {
  const [show, setShow] = useState(false);
  const target = useRef<HTMLButtonElement>(null);

  const handleOpen = () => setShow(true);

  return (
    <div onMouseEnter={handleOpen} onMouseLeave={() => setShow(false)}>
      <_SidebarButton
        className={className}
        disabled={disabled}
        Icon={Icon}
        label={label}
        path={path}
        permissions={[]}
        ref={target}
        subLabel={subLabel}
        onClick={buttons?.length ? handleOpen : onClick}
      />

      {buttons?.length && (
        <Overlay placement="right" show={show} target={target.current}>
          <div>
            {buttons.map((buttonData, index) => (
              <SidebarButton className={styles.sidebarButtonOverlay} key={index} {...buttonData} />
            ))}
          </div>
        </Overlay>
      )}
    </div>
  );
};

export default SidebarButton;
