import * as t from 'io-ts/lib/index';

import { FiltersState } from 'redux/activityHistory/types';

const Report = t.type({
  id: t.number,
  year: t.number,
  sprint: t.number,
});

const User = t.type({
  id: t.number,
  firstName: t.string,
  lastName: t.string,
});

const Tags = t.type({
  action: t.boolean,
  decision: t.boolean,
  needToKnow: t.boolean,
});

const Unit = t.type({
  id: t.number,
  name: t.string,
  roleName: t.string,
  users: t.array(User),
});

const Units = t.array(Unit);

const ActivityComment = t.type({
  id: t.number,
  created: t.string,
  author: User,
  activity: t.number,
  content: t.string,
  unit: t.number,
  status: t.string,
  tags: t.union([t.null, Tags]),
});

const History = t.type({
  report: Report,
  activityComments: t.array(ActivityComment),
});

const ActivityHistory = t.array(History);

export interface ActivityHistoryFetch {
  id: number;
  filters: FiltersState;
}

type Report = t.TypeOf<typeof Report>;
type User = t.TypeOf<typeof User>;
type Tags = t.TypeOf<typeof Tags>;
type Unit = t.TypeOf<typeof Unit>;
type ActivityComment = t.TypeOf<typeof ActivityComment>;
type History = t.TypeOf<typeof History>;
type ActivityHistory = t.TypeOf<typeof ActivityHistory>;
type Units = t.TypeOf<typeof Units>;

export { Report, User, Tags, Unit, ActivityComment, History, ActivityHistory, Units };
