import SubAreaPanelComponent, { SubAreaPanelBaseProps } from 'components/SubAreaPanel';

import React from 'react';
import { useGetProjects } from 'redux/report/reportSlice';

const SubAreaPanel = (props: SubAreaPanelBaseProps) => {
  const projects = useGetProjects();

  return <SubAreaPanelComponent {...props} projects={projects} />;
};

export default SubAreaPanel;
