import MultiSelect, { MultiSelectOption } from 'components/MultiSelect/MultiSelect';
import React, { useEffect, useState } from 'react';

import CustomRangePicker from 'components/CustomDatePicker';
import { FormControl } from 'react-bootstrap';
import styles from './IdeasSearch.module.scss';
import { useDebounce } from 'use-debounce';

export interface IdeasSearchBaseProps {
  dates: any;
  setDates: any;
  selectedUsers: MultiSelectOption[];
  setSelectedUsers: (users: MultiSelectOption[]) => void;
  userOptions: MultiSelectOption[];
  setSearchText: (text: string) => void;
  setSelectedTypes: (types: MultiSelectOption[]) => void;
  typeOptions: MultiSelectOption<string>[];
  selectedTypes: MultiSelectOption[];
}

const IdeasSearch = ({
  dates,
  setDates,
  userOptions,
  selectedUsers,
  setSelectedUsers,
  setSearchText,
  setSelectedTypes,
  selectedTypes,
  typeOptions,
}: IdeasSearchBaseProps) => {
  const [search, setSearch] = useState('');
  const [searchValue] = useDebounce(search, 200);

  useEffect(() => {
    setSearchText(searchValue);
  }, [searchValue]);

  return (
    <div className={styles.ideasSearch}>
      <FormControl
        className={styles.ideasSearchInput}
        placeholder="Search"
        value={search}
        onChange={(event) => setSearch(event.target.value)}
      />
      <CustomRangePicker
        className={styles.ideasSearchDate}
        endDate={dates?.endDate}
        placeholderText="Date from/to"
        portalId="dateSearch"
        startDate={dates?.startDate}
        wrapperClassName={styles.ideasSearchDateWrapper}
        onChange={([startDate, endDate]) => setDates({ startDate, endDate })}
      />
      <MultiSelect
        options={userOptions}
        placeholder="Name"
        value={selectedUsers}
        onChange={(event) => setSelectedUsers(event as MultiSelectOption[])}
      />
      <MultiSelect
        options={typeOptions}
        placeholder="Type"
        value={selectedTypes}
        onChange={(event) => setSelectedTypes(event as MultiSelectOption[])}
      />
    </div>
  );
};

export default IdeasSearch;
