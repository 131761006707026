import 'react-app-polyfill/ie11';
import 'react-app-polyfill/stable';
import './index.scss';
import 'bootstrap-icons/font/bootstrap-icons.css';

import store, { persistor } from './redux/store';

import App from './App';
import { PUBLIC_URL } from './redux/const';
import { PersistGate } from 'redux-persist/integration/react';
import { Provider } from 'react-redux';
import React from 'react';
import ReactDOM from 'react-dom';
import { BrowserRouter as Router } from 'react-router-dom';

ReactDOM.render(
  <Router basename={PUBLIC_URL}>
    <Provider store={store}>
      <PersistGate loading={null} persistor={persistor}>
        <App />
      </PersistGate>
    </Provider>
  </Router>,
  document.getElementById('root'),
);
