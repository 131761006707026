import {
  setSelectedSection,
  useGetCurrentOrganisation,
  useGetCurrentSection,
} from 'redux/organisation/organisationSlice';

import React from 'react';
import SectionSelectComponent from 'components/SectionSelect';
import { useAppDispatch } from 'redux/hooks';

const SectionSelect = () => {
  const currentOrganisation = useGetCurrentOrganisation();
  const currentSection = useGetCurrentSection();
  const dispatch = useAppDispatch();

  return (
    <SectionSelectComponent
      currentSection={currentSection?.id}
      sections={currentOrganisation?.reportingUnits || []}
      onChange={(section) => dispatch(setSelectedSection(section))}
    />
  );
};

export default SectionSelect;
