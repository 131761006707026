import { Labels, Tags } from '../../types/Report';

import { RangeDate } from 'components/CustomDatePicker';

export interface NewProject {
  area: string;
  comment: string;
  costCode: string;
  actis: string;
  title: string;
  subarea: string;
  sprint: number;
  year: number;
}

export interface ProjectUpdate {
  area: string;
  id: number;
  costCode: string;
  subarea: string;
  title: string;
  sprint: number;
  year: number;
  actis: string;
}

export interface CommentCreate {
  content: string;
  tags: Tags;
  projectId: number;
  status: string | number;
  unit: number;
  labels: Labels;
}

export interface CommentUpdate {
  content: string;
  tags: Tags;
  projectId: number;
  status: string | number;
  unit: number;
  commentId: number;
  labels: Labels;
}

export interface CommentRemove {
  projectId: number;
  commentId: number;
  unit: number;
}

export type RemarkPath = 'actions' | 'decisions' | 'needToKnows';

export type TagType = 'action' | 'decision' | 'needToKnow';

export interface RemarkCreate {
  desc: string;
  path: RemarkPath;
}

export interface RemarkUpdate {
  desc: string;
  id: number;
  path: RemarkPath;
}

export interface RemarkRemove {
  id: number;
  path: RemarkPath;
}

export interface EventCreate {
  startDate: Date;
  endDate: Date;
  desc: string;
}

export interface EventUpdate {
  startDate: string;
  endDate: string;
  desc: string;
  id: number;
}

export interface LockedPeriodCreate {
  unit: number;
  startDate: Date;
  endDate: Date;
  desc: string;
}

export interface LockedPeriodUpdate {
  id: number;
  unit: number;
  startDate: string;
  endDate: string;
  desc: string;
}

export enum VisibilityFilter {
  SHOW_ALL,
  HIDE_EMPTY,
  SHOW_ONLY_EMPTY,
}

export interface Filters {
  onlyMyActivities: boolean;
  onlyRecentUpdates: boolean;
  onlyActions: boolean;
  section: string | null;
  visibilityFilter: VisibilityFilter;
  search: string;
  author: number[];
  dates: RangeDate;
}

export interface ExportParams {
  commentLevel: number;
  includeHistory?: boolean;
  onlyIfStatusChanged?: boolean;
  activitiesAsTable?: boolean;
  includeComments?: boolean;
}

export interface SearchReportParams {
  area?: string;
  createdBefore?: string;
  createdAfter?: string;
  search?: string;
  status?: string;
  subarea?: string;
  types?: string;
  tags?: string;
  roles?: string;
  actis?: string;
  costCode?: string;
  labels?: string;
}

export interface GenerateReportStatistics {
  year: number | null;
  sprint: number | null;
}

export interface ProjectArchive {
  id: number;
}

export interface CommentCopyPrevious {
  id: number;
}

export interface DeleteActivity {
  id: number;
  sprint: number;
  year: number;
}
