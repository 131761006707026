import 'react-datepicker/dist/react-datepicker.css';

import FieldGroup, { FieldGroupProps } from 'components/FieldGroup';
import React, { forwardRef } from 'react';

import DatePicker from 'react-datepicker';
import type { ReactDatePickerProps } from 'react-datepicker';
import clsx from 'clsx';
import { createDateFormat } from 'utils/createDateFormat';
import styles from './CustomDatePicker.module.scss';
import { useGetUserConfig } from 'redux/userConfig/userConfigSlice';

// eslint-disable-next-line react/display-name
const CustomInput = forwardRef((props: any, ref) => <FieldGroup ref={ref} {...props} />);

export interface RangeDate {
  startDate: Date | null;
  endDate: Date | null;
}

interface CustomReactDatePickerProps<WithRange>
  extends Omit<ReactDatePickerProps, 'selectsRange' | 'onChange'> {
  selectsRange?: WithRange;
  onChange(
    date: WithRange extends false | undefined ? Date | null : [Date | null, Date | null],
    event: React.SyntheticEvent<any> | undefined,
  ): void;
  InputProps?: FieldGroupProps;
  wrapperClassName?: string;
  placeholderText?: string;
  error?: string;
}

export const CustomRangeDatePicker = (props: CustomReactDatePickerProps<true>) => {
  const { dateFormat, hourFormat } = useGetUserConfig();

  return (
    <div className={clsx(props.wrapperClassName, styles.customDateWrapper)}>
      <DatePicker
        calendarStartDay={1}
        className={props.wrapperClassName}
        customInput={
          <CustomInput
            {...props.InputProps}
            wrapperClassName={clsx(props.InputProps?.wrapperClassName, styles.input)}
          />
        }
        dateFormat={createDateFormat({ formatType: 'date', dateFormat, hourFormat })}
        isClearable
        selectsRange
        {...props}
      />
      <div className={styles.inputError}>{props.error}</div>
    </div>
  );
};
