import {
  useGetIsReportLoading,
  useGetReportStatistics,
  useGetSprint,
  useGetYear,
  useIsReportStatisticsLoading,
} from 'redux/report/reportSlice';

import React from 'react';
import { default as StatisticsPageComponent } from 'pages/StatisticsPage';
import { useGetReportStatisticsApi } from 'utils/hooks/statistics';

const StatisticsPage = () => {
  const statistics = useGetReportStatistics();
  const isLoading = useIsReportStatisticsLoading();
  const isReportLoading = useGetIsReportLoading();
  const year = useGetYear();
  const sprint = useGetSprint();

  const getReportStatistics = useGetReportStatisticsApi();

  return (
    <StatisticsPageComponent
      getReportStatistics={() => getReportStatistics({ year, sprint })}
      isLoading={isLoading || isReportLoading}
      sprint={sprint}
      statistics={statistics}
      year={year}
    />
  );
};

export default StatisticsPage;
