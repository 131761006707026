import { EVENT_DATE_FORMAT, ONLY_DAY_FORMAT, ONLY_DAY_MONTH_FORMAT } from '../constants';
import { format, isBefore, isSameDay, isSameMonth, isSameYear, isValid } from 'date-fns';

import isAfter from 'date-fns/isAfter';
import subDays from 'date-fns/subDays';

export const isLessThanDay = (date: Date | string): boolean => {
  if (!date || !isValid(new Date(date))) {
    return false;
  }

  return isAfter(new Date(date), subDays(new Date(), 1));
};

export const isBetweenDates = (date: Date, from: Date | null, to: Date | null): boolean => {
  if (!date || !isValid(date) || !from || !isValid(from) || !to || !isValid(to)) {
    return false;
  }

  return (
    (isAfter(date, from) || isSameDay(date, from)) && (isBefore(date, to) || isSameDay(date, to))
  );
};

export const formatRangeDate = (start: string | undefined, end: string | undefined): string => {
  if (!start || !end || !isValid(new Date(start)) || !isValid(new Date(end))) {
    return '-';
  }

  let startDate = new Date(start);
  let endDate = new Date(end);

  if (isSameDay(new Date(startDate), new Date(endDate))) {
    return format(startDate, EVENT_DATE_FORMAT);
  }

  if (isAfter(startDate, endDate)) {
    startDate = new Date(end);
    endDate = new Date(start);
  }

  if (isSameMonth(startDate, endDate)) {
    return `${format(startDate, ONLY_DAY_FORMAT)}-${format(endDate, EVENT_DATE_FORMAT)}`;
  }

  if (isSameYear(startDate, endDate)) {
    return `${format(startDate, ONLY_DAY_MONTH_FORMAT)}-${format(endDate, EVENT_DATE_FORMAT)}`;
  }

  return `${format(startDate, EVENT_DATE_FORMAT)}-${format(endDate, EVENT_DATE_FORMAT)}`;
};
