import {
  useCheckOneOfPermissions,
  useGetCurrentOrganisation,
  useGetCurrentOrganisationLabels,
  useGetCurrentOrganisationUnits,
  useGetCurrentSection,
  useGetOrganisationAreas,
  useGetOrganisationUsers,
} from 'redux/organisation/organisationSlice';
import {
  useGetActis,
  useGetCostCodes,
  useGetSearch,
  useIsSearchLoading,
} from 'redux/report/reportSlice';
import { useGetActisApi, useGetCostCodeApi, useSearchReportActionApi } from 'utils/hooks/report';
import {
  useGetAllCurrentOrganisationLabelsApi,
  useGetOrganisationAreaApi,
  useGetOrganisationUnitsApi,
  useGetOrganisationUsersApi,
} from 'utils/hooks/organisation';

import React from 'react';
import { default as SearchPageComponent } from 'pages/SearchPage';
import { UserPermission } from 'redux/organisation/types';
import uniqBy from 'lodash/uniqBy';

const SearchPage = () => {
  const currentSection = useGetCurrentSection();
  const areas = useGetOrganisationAreas();
  const users = useGetOrganisationUsers();
  const search = useGetSearch();
  const isLoading = useIsSearchLoading();
  const organisation = useGetCurrentOrganisation();
  const units = useGetCurrentOrganisationUnits();
  const actis = useGetActis();
  const costCodes = useGetCostCodes();
  const labels = useGetCurrentOrganisationLabels();
  const getActis = useGetActisApi();
  const getCostCodes = useGetCostCodeApi();

  const getOrganisationUnits = useGetOrganisationUnitsApi();
  const getOrganisationUsers = useGetOrganisationUsersApi();
  const getOrganisationLabels = useGetAllCurrentOrganisationLabelsApi();
  const getOrganisationArea = useGetOrganisationAreaApi();

  const searchReport = useSearchReportActionApi();

  const canViewArea = useCheckOneOfPermissions(UserPermission.VIEW_AREA);

  const roles = uniqBy(
    units?.map((unit) => ({ id: unit.id, name: unit.roleName })),
    'name',
  );

  return (
    <SearchPageComponent
      actis={actis}
      areas={areas}
      canViewArea={canViewArea}
      costCodes={costCodes}
      currentSection={currentSection}
      getActis={getActis}
      getAreas={getOrganisationArea}
      getCostCodes={getCostCodes}
      getLabels={getOrganisationLabels}
      getRoles={getOrganisationUnits}
      getUsers={getOrganisationUsers}
      isLoading={isLoading}
      labels={labels}
      organisation={organisation}
      roles={roles ?? []}
      search={search}
      searchReport={searchReport}
      users={users}
    />
  );
};

export default SearchPage;
