import { DATE_FORMAT } from '../constants';
import { DEBUG } from '../redux/const';

export type Format = 'date' | 'dateTime';

export type HourFormat = '12h' | '24h' | null;

export type IdeaEmailFrequency = 0 | 1 | 2 | null;

const HoursFormat = new Proxy(
  {
    '12h': 'hh:mm a',
    '24h': 'HH:mm',
  },
  { get: (handler, type) => handler[type] || 'HH:mm' },
);

export const createDateFormat = ({
  dateFormat,
  hourFormat,
  formatType,
}: {
  dateFormat: string | null;
  hourFormat: HourFormat;
  formatType?: Format;
}): string => {
  if (formatType === 'date') {
    return dateFormat || DATE_FORMAT;
  }

  if (formatType === 'dateTime') {
    return `${dateFormat || DATE_FORMAT} ${HoursFormat[hourFormat as string]}`;
  }

  if (DEBUG) {
    console.warn("Probably error in user's config. Default format is used.");
  }

  return DATE_FORMAT;
};
