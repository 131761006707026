import React, { useEffect } from 'react';
import {
  useCheckOneOfPermissions,
  useGetCurrentOrganisation,
  useGetCurrentOrganisationUnits,
  useGetOrganisationLockedPeriods,
  useIsOrganisationLockedPeriodsLoading,
  useIsOrganisationUnitsLoading,
} from 'redux/organisation/organisationSlice';
import {
  useDeleteLockedPeriodApi,
  useGetOrganisationLockedPeriodsApi,
  useGetOrganisationUnitsApi,
  useUpdateLockedPeriodApi,
} from 'utils/hooks/organisation';

import { default as AdminPanelComponent } from 'components/AdminPanel';
import { LockedPeriod } from 'types/Report';
import { UserPermission } from 'redux/organisation/types';

const AdminPanel = () => {
  const lockedPeriods: LockedPeriod[] = useGetOrganisationLockedPeriods();

  const getLockedPeriods = useGetOrganisationLockedPeriodsApi();
  const getUnits = useGetOrganisationUnitsApi();
  const organisation = useGetCurrentOrganisation();
  const units = useGetCurrentOrganisationUnits().filter((unit) => unit.reportingLevel);
  const deleteLockedPeriod = useDeleteLockedPeriodApi();
  const updateLockedPeriod = useUpdateLockedPeriodApi();

  const canViewLockedPeriods = useCheckOneOfPermissions(UserPermission.VIEW_LOCKED_PERIOD);
  const canDeleteLockedPeriod = useCheckOneOfPermissions(UserPermission.DELETE_LOCKED_PERIOD);
  const canUpdateLockedPeriod = useCheckOneOfPermissions(UserPermission.CHANGE_LOCKED_PERIOD);
  const canAddLockedPeriod = useCheckOneOfPermissions(UserPermission.ADD_LOCKED_PERIOD);

  const isLockedPeriodsLoading = useIsOrganisationLockedPeriodsLoading();
  const isUnitsLoading = useIsOrganisationUnitsLoading();

  useEffect(() => {
    getUnits();
    getLockedPeriods();
  }, [organisation]);

  return (
    <AdminPanelComponent
      canAddLockedPeriod={canAddLockedPeriod}
      canDeleteLockedPeriod={canDeleteLockedPeriod}
      canUpdateLockedPeriod={canUpdateLockedPeriod}
      canViewLockedPeriods={canViewLockedPeriods}
      isLoading={isLockedPeriodsLoading || isUnitsLoading}
      lockedPeriods={lockedPeriods}
      removeLockedPeriod={deleteLockedPeriod}
      sections={units}
      updateLockedPeriod={function (
        id: number,
        desc: string,
        startDate: string,
        endDate: string,
        unit: number,
      ) {
        updateLockedPeriod({ id, desc, startDate, endDate, unit });
      }}
    />
  );
};

export default AdminPanel;
