export const USER_ONLINE_STATUS = 'USER_ONLINE_STATUS';
export const ACTIVITY_CREATED = 'ACTIVITY_CREATED';
export const ACTIVITY_UPDATED = 'ACTIVITY_UPDATED';
export const ACTIVITY_DELETED = 'ACTIVITY_DELETED';
export const ACTIVITY_ARCHIVED = 'ACTIVITY_ARCHIVED';
export const ACTIVITY_UNARCHIVED = 'ACTIVITY_UNARCHIVED';
export const ACTION_CREATED = 'ACTION_CREATED';
export const ACTION_DELETED = 'ACTION_DELETED';
export const ACTION_UPDATED = 'ACTION_UPDATED';
export const DECISION_CREATED = 'DECISION_CREATED';
export const DECISION_DELETED = 'DECISION_DELETED';
export const DECISION_UPDATED = 'DECISION_UPDATED';
export const NEED_TO_KNOW_CREATED = 'NEED_TO_KNOW_CREATED';
export const NEED_TO_KNOW_DELETED = 'NEED_TO_KNOW_DELETED';
export const NEED_TO_KNOW_UPDATED = 'NEED_TO_KNOW_UPDATED';
export const REMARK_CREATED = 'REMARK_CREATED';
export const REMARK_DELETED = 'REMARK_DELETED';
export const REMARK_UPDATED = 'REMARK_UPDATED';
export const EVENT_CREATED = 'UPCOMINGEVENT_CREATED';
export const EVENT_DELETED = 'UPCOMINGEVENT_DELETED';
export const EVENT_UPDATED = 'UPCOMINGEVENT_UPDATED';
export const COMMENT_CREATED = 'COMMENT_CREATED';
export const COMMENT_DELETED = 'COMMENT_DELETED';
export const COMMENT_UPDATED = 'COMMENT_UPDATED';
export const LOCKED_PERIOD_CREATED = 'LOCKED_PERIOD_CREATED';
export const LOCKED_PERIOD_DELETED = 'LOCKED_PERIOD_DELETED';
export const LOCKED_PERIOD_UPDATED = 'LOCKED_PERIOD_UPDATED';
