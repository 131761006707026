import {
  getArchivedProjects as getArchivedProjectsAction,
  unarchiveProject as unarchiveProjectAction,
} from 'redux/archivedProjects/archivedProjectsActions';

import ArchivedProjectsPageComponent from 'pages/ArchivedProjectsPage';
import React from 'react';
import { useFetchData } from 'utils/fetchData';
import { useGetArchivedProjects } from 'redux/archivedProjects/archivedProjectsSlice';

const ArchivedProjectsPage = () => {
  const projects = useGetArchivedProjects();

  const getArchivedProjects = useFetchData(getArchivedProjectsAction, { model: 'Activity' });
  const unarchiveProject = useFetchData<number>(unarchiveProjectAction, {
    model: 'Activity',
    errorMessage: 'Failed to unarchive activity',
    successMessage: 'Activity has been unarchived successfully',
  });

  return (
    <ArchivedProjectsPageComponent
      getArchivedProjects={getArchivedProjects}
      projects={projects}
      unarchive={unarchiveProject}
    />
  );
};

export default ArchivedProjectsPage;
