import {
  ACTION_CREATED,
  ACTION_DELETED,
  ACTION_UPDATED,
  ACTIVITY_ARCHIVED,
  ACTIVITY_CREATED,
  ACTIVITY_DELETED,
  ACTIVITY_UNARCHIVED,
  ACTIVITY_UPDATED,
  COMMENT_CREATED,
  COMMENT_DELETED,
  COMMENT_UPDATED,
  DECISION_CREATED,
  DECISION_DELETED,
  DECISION_UPDATED,
  EVENT_CREATED,
  EVENT_DELETED,
  EVENT_UPDATED,
  LOCKED_PERIOD_CREATED,
  LOCKED_PERIOD_DELETED,
  LOCKED_PERIOD_UPDATED,
  NEED_TO_KNOW_CREATED,
  NEED_TO_KNOW_DELETED,
  NEED_TO_KNOW_UPDATED,
  REMARK_CREATED,
  REMARK_DELETED,
  REMARK_UPDATED,
  USER_ONLINE_STATUS,
} from './reportActionTypes';
import {
  activityArchived,
  activityCreated,
  activityDeleted,
  activityUnarchived,
  activityUpdated,
  commentCreated,
  commentDeleted,
  commentUpdated,
  eventCreated,
  eventDeleted,
  eventUpdated,
  lockedPeriodCreated,
  lockedPeriodDeleted,
  lockedPeriodUpdated,
  remarkCreated,
  remarkDeleted,
  remarkUpdated,
  userOnlineStatus,
} from './reportActions';
import createSocketMiddleware, { connectSocket, disconnectSocket } from '../createSocketMiddleware';

import { WS_URL } from '../const';
import { camelizeKeys } from 'humps';

const socketMessageToAction = new Proxy(
  {
    [USER_ONLINE_STATUS]: userOnlineStatus,
    [ACTIVITY_CREATED]: activityCreated,
    [ACTIVITY_UPDATED]: activityUpdated,
    [ACTIVITY_DELETED]: activityDeleted,
    [ACTIVITY_ARCHIVED]: activityArchived,
    [ACTIVITY_UNARCHIVED]: activityUnarchived,
    [ACTION_CREATED]: remarkCreated,
    [ACTION_DELETED]: remarkDeleted,
    [ACTION_UPDATED]: remarkUpdated,
    [DECISION_CREATED]: remarkCreated,
    [DECISION_DELETED]: remarkDeleted,
    [DECISION_UPDATED]: remarkUpdated,
    [NEED_TO_KNOW_CREATED]: remarkCreated,
    [NEED_TO_KNOW_DELETED]: remarkDeleted,
    [NEED_TO_KNOW_UPDATED]: remarkUpdated,
    [REMARK_CREATED]: remarkCreated,
    [REMARK_DELETED]: remarkDeleted,
    [REMARK_UPDATED]: remarkUpdated,
    [EVENT_CREATED]: eventCreated,
    [EVENT_DELETED]: eventDeleted,
    [EVENT_UPDATED]: eventUpdated,
    [COMMENT_CREATED]: commentCreated,
    [COMMENT_DELETED]: commentDeleted,
    [COMMENT_UPDATED]: commentUpdated,
    [LOCKED_PERIOD_CREATED]: lockedPeriodCreated,
    [LOCKED_PERIOD_DELETED]: lockedPeriodDeleted,
    [LOCKED_PERIOD_UPDATED]: lockedPeriodUpdated,
  },
  { get: (handler, type) => handler[type] || null },
);

const getActionPayload = new Proxy(
  {
    ACTION_DELETED: (payload) => camelizeKeys({ ...payload, type: 'action' }),
    DECISION_DELETED: (payload) => camelizeKeys({ ...payload, type: 'decision' }),
    NEED_TO_KNOW_DELETED: (payload) => camelizeKeys({ ...payload, type: 'need_to_know' }),
  },
  {
    get: (handler, type) => (payload) =>
      handler[type] ? handler[type](payload) : camelizeKeys(payload),
  },
);

const socketType = 'report';

const onMessage = (message, store) => {
  const action = socketMessageToAction[message.action];
  const payload = getActionPayload[message.action](message.payload);

  if (action) {
    return store.dispatch(action(payload));
  }
};

export const connectReportSocket = (reportId: number, accessToken: string | undefined) => {
  if (accessToken) {
    const host = `${WS_URL}/reports/${reportId}/?token=${accessToken}`;

    return connectSocket(socketType, host);
  }

  return () => null;
};
export const disconnectReportSocket = () => disconnectSocket(socketType);

export default createSocketMiddleware(socketType, { onMessage });
