import * as Yup from 'yup';

import React, { FC } from 'react';

import WideInput from './WideInput';
import { remarkFormSchema } from 'schemas/RemarkForm';
import { useForm } from 'react-hook-form';
import { yupResolver } from '@hookform/resolvers/yup';

export interface CreateRemarkFormData {
  desc: string;
}

export interface NewRemarkCreationBaseProps {
  title: string;
}

interface Props extends NewRemarkCreationBaseProps {
  editable: boolean;
  createRemark: (desc: string) => any;
}

const NewRemarkCreation: FC<Props> = ({ title, createRemark, editable }) => {
  const { handleSubmit, control, reset } = useForm<CreateRemarkFormData>({
    resolver: yupResolver<Yup.SchemaOf<CreateRemarkFormData>>(remarkFormSchema),
  });

  return (
    <WideInput
      control={control}
      disabled={!editable}
      maxLength={200}
      name={'desc'}
      placeholder={title}
      onSubmit={handleSubmit(({ desc }) => {
        createRemark(desc);
        reset({ desc: '' });
      })}
    />
  );
};

export default NewRemarkCreation;
