import React from 'react';
import get from 'lodash/get';
import styles from './ExpandedIdea.module.scss';

const sections = [
  { title: 'Idea description', key: 'desc' },
  { title: 'Main output / result', key: 'output' },
  { title: 'Estimation of effort needed (time / money)', key: 'effort' },
];

const ExpandedIdea = ({ ...row }) => {
  return (
    <div className={styles.expandedIdea}>
      {sections.map((section) => (
        <>
          <div className={styles.expandedIdeaTitle}>{section.title}</div>
          <div className={styles.expandedIdeaText}>{get(row, section.key, '')}</div>
        </>
      ))}
    </div>
  );
};

export default ExpandedIdea;
