import { Area, Project, SubArea } from 'types/Report';
import React, { useMemo } from 'react';

import { Accordion } from 'react-bootstrap';
import ProjectList from 'containers/ProjectList';
import ProjectToggle from 'containers/ProjectToggle';
import { styles as areasPanelStyles } from 'components/AreasPanel';
import { useAreaVisibilityData } from 'utils/reportHelpers';
import { useGetSelectedProject } from 'utils/useGetSelectedProject';

export interface SubAreaPanelBaseProps {
  area: Area;
  subArea: SubArea;
}

export interface SubAreaPanelProps extends SubAreaPanelBaseProps {
  projects: Project[];
}

const SubAreaPanel = ({ area, subArea, projects }: SubAreaPanelProps) => {
  const selectedSubArea = useGetSelectedProject(projects)?.subarea?.id;
  const subareaActivities = useMemo(
    () =>
      projects?.filter(
        (project) => project.area.id === area.id && project.subarea?.id === subArea?.id,
      ),
    [area, projects],
  );
  const visibilityData = useAreaVisibilityData(subareaActivities || []);

  return (
    <Accordion>
      <ProjectToggle
        badgeCount={visibilityData.visibleActivities.length}
        data-testid="subArea-level"
        eventKey="1"
        initialOpen={selectedSubArea === subArea.id}>
        {subArea.name}
      </ProjectToggle>
      <Accordion.Collapse
        aria-label="subarea"
        className={areasPanelStyles.levelMargin}
        eventKey="1"
        role="treeitem">
        <ProjectList area={area} subArea={subArea} />
      </Accordion.Collapse>
    </Accordion>
  );
};

export default SubAreaPanel;
