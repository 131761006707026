import { Badge } from 'react-bootstrap';
import ButtonsCell from '../Table/ButtonsCell';
import DateFormatter from 'containers/DateFormatter';
import React from 'react';
import { StatefulTextarea } from '../StatefulTextarea';
import { isLessThanDay } from 'utils/timeUtils';
import styles from './RemarksPanel.module.scss';

export default ({
  title,
  removeRemark,
  editing,
  setEditing,
  desc,
  setDesc,
  handleSubmit,
  handleCancel,
  handleLinkClick,
  editable,
}) => [
  {
    Header: 'Date',
    accessor: 'created',
    collapse: true,
    Cell: ({ value }) => {
      const date = new Date(value);

      return <DateFormatter date={date} formatType="date" />;
    },
  },
  {
    Header: 'Name',
    accessor: 'author',
    Cell: ({ value: { firstName, lastName } }) => `${firstName} ${lastName}`,
  },
  {
    Header: title,
    accessor: 'desc',
    Cell: ({ row: { original } }) => {
      if (editing === original.id) {
        return <StatefulTextarea desc={desc} maxLength={200} setDesc={setDesc} />;
      }

      const content = original.desc?.split('\n');
      if (content?.length > 1) {
        return <div className={styles.remarkDesc}>{`${content[0]}...`}</div>;
      }

      return <div className={styles.remarkDesc}>{original.desc}</div>;
    },
  },
  {
    Header: 'tags',
    id: 'tag',
    collapse: true,
    center: true,
    Cell: ({
      row: {
        original: { created },
      },
    }) => {
      return isLessThanDay(created) && <Badge>New</Badge>;
    },
  },
  {
    Header: 'Link',
    id: 'link',
    collapse: true,
    center: true,
    Cell: ({ row: { original } }) => {
      if (original?.isFromComment) {
        return (
          <Badge
            className={styles.go}
            data-testid={`remarkLink`}
            role="button"
            onClick={() => handleLinkClick(original)}>
            {'GO'}
          </Badge>
        );
      }

      return null;
    },
  },
  {
    Header: 'Actions',
    collapse: true,
    center: true,
    Cell: ({ row: { original } }) => {
      return (
        <ButtonsCell
          editMode={editing === original.id}
          id="remark"
          onCancel={handleCancel}
          onEdit={
            editable && setEditing
              ? () => {
                  setDesc(original.desc);
                  setEditing(original.id);
                }
              : undefined
          }
          onRemove={editable && removeRemark ? () => removeRemark(original) : undefined}
          onSubmit={() => handleSubmit(original)}
        />
      );
    },
  },
];
