import { ReactComponent as NextSprintImg } from 'assets/icons/arrow_right.svg';
import { ReactComponent as PreviousSprintImg } from 'assets/icons/arrow_left.svg';
import { ReactComponent as PreviousSprintImgDisabled } from 'assets/icons/arrow_left_disabled.svg';
import React from 'react';
import styles from './SprintSelector.module.scss';

interface Props {
  hasPrevious: boolean | null;
  sprint: number | null;
  year: number | null;
  setPrevSprint: () => void;
  setNextSprint: () => void;
  label: string;
}

const SprintSelector = ({
  hasPrevious,
  sprint,
  year,
  setPrevSprint,
  setNextSprint,
  label,
}: Props) => (
  <div className={styles.sprintSelector}>
    <button className="clear-button mb-1" disabled={!hasPrevious} onClick={setPrevSprint}>
      {hasPrevious ? (
        <PreviousSprintImg className={styles.sprintSelectorIcon} />
      ) : (
        <PreviousSprintImgDisabled className={styles.sprintSelectorIcon} />
      )}
    </button>
    <div className={styles.sprintSelectorLabel}>Sprint</div>
    <div className={styles.sprintSelectorValue}>{`${sprint} / ${year}`}</div>
    <div className={styles.sprintSelectorStatus}>{label}</div>
    <button className="clear-button mb-1" onClick={setNextSprint}>
      <NextSprintImg className={styles.sprintSelectorIcon} />
    </button>
  </div>
);

export default SprintSelector;
