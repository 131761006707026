import { Comment, Project, User } from 'types/Report';
import { Filters, VisibilityFilter } from 'redux/report/types';
import { useGetCommentsFromActivities, useGetFilters } from 'redux/report/reportSlice';

import { MultiSelectOption } from 'components/MultiSelect/MultiSelect';
import { isLessThanDay } from './timeUtils';
import { useGetUser } from 'redux/session/sessionSlice';

export const getUsersOptions = (authors: User[]) =>
  authors?.map(
    (author): MultiSelectOption => ({
      value: author.id,
      label: `${author.firstName} ${author.lastName}`,
    }),
  ) ?? [];

export const shouldActivityBeVisible = (
  activity: Project,
  flatComments: Comment[],
  filters: Filters,
  userId?: number,
): boolean => {
  const hasVisibleComments = flatComments.some((comment) =>
    shouldCommentBeVisible(comment, filters, userId),
  );

  // if (filters.onlyMyActivities) {
  //   if (activity.author.id !== userId) {
  //     return false;
  //   }
  // }

  if (!hasVisibleComments && (filters.onlyActions || filters.onlyMyActivities)) {
    return false;
  }

  const searchLower = filters?.search?.toLowerCase();

  if (searchLower) {
    if (activity.title?.toLowerCase().includes(searchLower)) {
      return true;
    }

    return false;
  }

  if (filters.visibilityFilter === VisibilityFilter.SHOW_ALL) {
    return true;
  }

  if (filters.visibilityFilter === VisibilityFilter.HIDE_EMPTY && flatComments.length === 0) {
    return false;
  }

  if (filters.visibilityFilter === VisibilityFilter.SHOW_ONLY_EMPTY && flatComments.length === 0) {
    return true;
  }

  if (filters.visibilityFilter === VisibilityFilter.HIDE_EMPTY && !hasVisibleComments) {
    return false;
  }

  if (filters.visibilityFilter === VisibilityFilter.SHOW_ONLY_EMPTY && hasVisibleComments) {
    return false;
  }

  return true;
};

export const shouldCommentBeVisible = (
  comment: Comment,
  filters: Filters,
  userId?: number,
): boolean => {
  if (filters.onlyActions) {
    const hasTags = comment.tags?.action;
    if (!hasTags) {
      return false;
    }
  }

  if (filters.onlyRecentUpdates) {
    if (!isLessThanDay(comment.modified)) {
      return false;
    }
  }

  if (filters.onlyMyActivities) {
    if (comment.author.id !== userId) {
      return false;
    }
  }

  // const searchLower = filters?.search?.toLowerCase();

  // if (searchLower) {
  //   return comment.content?.toLowerCase().includes(searchLower);
  // }

  return true;
};

export interface AreaVisibilityData {
  visibleAreas: number[];
  visibleSubareas: number[];
  visibleActivities: number[];
}

interface ActivityWithComments {
  activity: Project;
  comments: Comment[];
}

export const createAreaVisibilityData = (
  activities: ActivityWithComments[],
  filters: Filters,
  userId?: number,
): AreaVisibilityData => {
  const filteredActivities = activities.filter((activity) =>
    shouldActivityBeVisible(activity.activity, activity.comments, filters, userId),
  );

  let areaIds: number[] = [];
  let subAreasIds: number[] = [];

  if (
    filters.visibilityFilter === VisibilityFilter.SHOW_ALL &&
    !filters.search &&
    !filters.onlyMyActivities &&
    !filters.onlyRecentUpdates &&
    !filters.onlyActions
  ) {
    areaIds = activities.map((activity) => activity.activity.area.id);
    subAreasIds = activities.map((activity) => activity.activity.subarea.id);
  } else {
    areaIds = filteredActivities.map((activity) => activity.activity.area.id);
    subAreasIds = filteredActivities.map((activity) => activity.activity.subarea.id);
  }

  return {
    visibleSubareas: [...new Set(subAreasIds)],
    visibleAreas: [...new Set(areaIds)],
    visibleActivities: filteredActivities.map((activity) => activity.activity.id),
  };
};

export const useAreaVisibilityData = (activities: Project[]): AreaVisibilityData => {
  const commentsUnit = useGetCommentsFromActivities(activities);
  const filters = useGetFilters();
  const user = useGetUser();

  return createAreaVisibilityData(commentsUnit, filters, user?.id);
};
