import React, { useCallback } from 'react';

import AsyncCreatableSelect from 'react-select/async-creatable';
import type { Props } from 'react-select';
import clsx from 'clsx';

export interface CreatableCustomSelectOption {
  label: string;
  value: string;
}

export interface BasicCreatableCustomSelectProps extends Props {
  invalid?: boolean;
  customStyles?: any;
  textColor?: string;
  primaryColor?: string;
  secondaryColor?: string;
}

interface CreatableCustomSelectProps extends BasicCreatableCustomSelectProps {
  loadOptions?: (query: string) => Promise<CreatableCustomSelectOption[]>;
  initialOptions?: CreatableCustomSelectOption[];
}

const CreatableCustomSelect = ({
  invalid = false,
  customStyles = null,
  textColor = '#003348',
  primaryColor = '#7eede2',
  secondaryColor = '#7eede2',
  loadOptions,
  initialOptions,
  ...props
}: CreatableCustomSelectProps) => {
  const handleLoadOptions = useCallback(
    (query: string) => {
      if (!loadOptions || !query) {
        return Promise.resolve(initialOptions ?? []);
      }

      return loadOptions(query);
    },
    [initialOptions],
  );

  return (
    <AsyncCreatableSelect
      className={clsx(customStyles, invalid && 'is-invalid')}
      loadOptions={handleLoadOptions}
      options={initialOptions}
      theme={(theme) => ({
        ...theme,
        borderRadius: 10,
        colors: {
          ...theme.colors,
          text: textColor,
          primary: primaryColor,
          primary25: secondaryColor,
          primary50: secondaryColor,
          primary75: secondaryColor,
        },
      })}
      cacheOptions
      defaultOptions
      isMulti
      {...props}
    />
  );
};

export default CreatableCustomSelect;
