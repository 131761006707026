/* eslint-disable max-statements */
/* eslint-disable max-lines */
import {
  ActivityComments,
  Comment,
  Comments,
  Event,
  FullActisList,
  Project,
  Remark,
  Report,
  ReportStatistics,
  ReportSwitch,
  SearchReport,
  User,
} from 'types/Report';
import { Filters, RemarkPath, TagType, VisibilityFilter } from './types';
import {
  activityArchived,
  activityCreated,
  activityDeleted,
  activityUnarchived,
  activityUpdated,
  addProjectComment,
  archiveProject,
  commentCreated,
  commentDeleted,
  commentUpdated,
  copyAllPreviousComments,
  copyPreviousComment,
  createEvent,
  createProject,
  createRemark,
  deleteProjectComment,
  eventCreated,
  eventDeleted,
  eventUpdated,
  getActis,
  getActisProject,
  getCostCodes,
  getLatestReport,
  getNextReport,
  getPreviousReport,
  getReport,
  getReportStatistics,
  getWlms,
  remarkCreated,
  remarkDeleted,
  remarkUpdated,
  removeEvent,
  removeProject,
  removeRemark,
  searchReport,
  updateEvent,
  updateProjectComment,
  updateProjectSettings,
  updateRemark,
  userOnlineStatus,
} from './reportActions';
import { createSlice, isAnyOf } from '@reduxjs/toolkit';
import { reduceReport, remarkTypeToPath } from './helpers';

import type { PayloadAction } from '@reduxjs/toolkit';
import { SimpleReduxState } from 'redux/types';
import get from 'lodash/get';
import sortBy from 'lodash/sortBy';
import { useAppSelector } from '../hooks';

export interface ReportState {
  report: {
    [year: string]: {
      [sprint: string]: Report;
    };
  };
  comments: {
    [year: string]: {
      [sprint: string]: {
        [projectId: string]: Comments;
      };
    };
  };
  next: ReportSwitch | null;
  previous: ReportSwitch | null;
  sprint: number | null;
  year: number | null;
  filters: Filters;
  error: string | null;
  actis: any; // TODO: Define type
  newActis: FullActisList;
  costCodes: any;
  wlms: any; // TODO: Define type
  listMode: 'collapsed' | 'expanded';
  isLoadingReport: boolean;
  hasErrorReport: boolean;
  search: SimpleReduxState<SearchReport | null>;
  statistics: SimpleReduxState<ReportStatistics | null>;
  newUnarchivedActivities: number[];
  reportUnavailable: boolean;
}

const initialState: ReportState = {
  isLoadingReport: false,
  report: {},
  comments: {},
  next: null,
  previous: null,
  actis: null,
  newActis: [],
  costCodes: [],
  wlms: null,
  sprint: null,
  year: null,
  filters: {
    visibilityFilter: VisibilityFilter.SHOW_ALL,
    onlyMyActivities: false,
    onlyRecentUpdates: false,
    onlyActions: false,
    section: null,
    search: '',
    author: [],
    dates: {
      startDate: null,
      endDate: null,
    },
  },
  listMode: 'expanded',
  error: null,
  search: {
    isLoading: false,
    hasError: false,
    data: null,
  },
  statistics: {
    isLoading: false,
    hasError: false,
    data: null,
  },
  newUnarchivedActivities: [],
  reportUnavailable: false,
  hasErrorReport: false,
};

const initComments = ({ state, year, sprint, report }) => {
  if (!state.comments[year]) {
    state.comments[year] = state.comments[year] || {};
  }
  if (!state.comments[year][sprint]) {
    state.comments[year][sprint] = state.comments[year][sprint] || {};
  }
  state.comments[year][sprint] = {};
  report.activities.forEach((activity) => {
    state.comments[year][sprint][activity.id] = report.units.map(({ id, name }) => ({
      id,
      name,
      comments: activity.comments.filter((comm) => comm.unit === id),
    }));
  });
};

const setReport = (state: ReportState, action) => {
  const { report, sprint, year } = reduceReport(action);

  if (report && year && sprint) {
    state.report = {
      ...state.report,
      [year]: {
        ...state.report[year],
        [sprint]: report,
      },
    };
    state.sprint = sprint;
    state.year = year;
    state.previous = report.previous;
    state.next = report.next;
    state.isLoadingReport = false;
    state.reportUnavailable = false;
    state.hasErrorReport = false;
    initComments({ state, year, sprint, report });
  }
};

export const reportSlice = createSlice({
  name: 'report',
  initialState,
  reducers: {
    setSectionFilter: (state, action) => {
      state.filters.section = action.payload && action.payload !== '' ? action.payload : null;
    },
    changeListMode: (state, action) => {
      state.listMode = action.payload;
    },
    toggleOnlyMyActivities: (state) => {
      state.filters.onlyMyActivities = !state.filters.onlyMyActivities;
    },
    toggleOnlyRecentUpdates: (state) => {
      state.filters.onlyRecentUpdates = !state.filters.onlyRecentUpdates;
    },
    toggleOnlyActions: (state) => {
      state.filters.onlyActions = !state.filters.onlyActions;
    },
    setSearchFilter: (state, action) => {
      state.filters.search = action.payload;
    },
    setAuthorFilter: (state, action) => {
      state.filters.author = action.payload;
    },
    setSearchDateFilter: (state, action) => {
      state.filters.dates = action.payload;
    },
    setVisibilityFilter: (state, action) => {
      state.filters.visibilityFilter = action.payload;
    },
    reportFatalError: (state, action) => {
      window.scrollTo(0, 0); // TODO: This was copied from App.tsx, make sure it is still needed
      state.error = action.payload;
      state.report = {};
    },
    addUnarchivedProject: (state, action: PayloadAction<Project>) => {
      state.report[state.year!][state.sprint!].activities.push(action.payload);
    },
    clearNewUnarchivedActivieties: (state) => {
      state.newUnarchivedActivities = [];
    },
    setReportError: (state) => {
      state.hasErrorReport = true;
    },
  },
  extraReducers: (builder) => {
    builder.addCase(archiveProject.fulfilled, (state, action) => {
      const { id } = action.meta.arg;
      const { year, sprint } = state;
      const report = state.report[year!][sprint!];
      report.activities = report.activities.filter((project) => project.id !== id);
    });

    builder.addCase(getActisProject.fulfilled, (state: ReportState, action) => {
      state.actis = action.payload;
    });
    builder.addCase(getActis.fulfilled, (state: ReportState, action) => {
      state.newActis = action.payload;
    });
    builder.addCase(getCostCodes.fulfilled, (state: ReportState, action) => {
      state.costCodes = action.payload;
    });
    builder.addCase(getWlms.fulfilled, (state: ReportState, action) => {
      state.wlms = action.payload;
    });
    builder.addCase(getReportStatistics.fulfilled, (state: ReportState, action) => {
      state.statistics.data = action.payload;
      state.statistics.isLoading = false;
    });
    builder.addCase(getReportStatistics.pending, (state: ReportState) => {
      state.statistics.isLoading = true;
    });
    builder.addCase(copyPreviousComment.fulfilled, (state: ReportState, action) => {
      const { sprint, year } = state;
      if (year && sprint) {
        const newComment = action.payload as Comment;
        const { id: projectId } = action.meta.arg;
        const { unit } = newComment;
        state.comments[year]?.[sprint]?.[projectId]
          .find((comment) => comment.id === unit)
          ?.comments.unshift(newComment);
      }
    });
    builder.addCase(copyAllPreviousComments.fulfilled, (state: ReportState, action) => {
      const { sprint, year } = state;
      if (year && sprint) {
        const newComments = action.payload as ActivityComments;

        for (const newComment of newComments) {
          const { unit, activity } = newComment;
          state.comments[year]?.[sprint]?.[activity]
            .find((comment) => comment.id === unit)
            ?.comments.unshift(newComment);
        }
      }
    });
    builder.addCase(searchReport.fulfilled, (state: ReportState, action) => {
      state.search.data = action.payload;
      state.search.isLoading = false;
      state.search.hasError = false;
    });
    builder.addCase(searchReport.pending, (state: ReportState) => {
      state.search.isLoading = true;
      state.search.hasError = false;
    });
    builder.addCase(searchReport.rejected, (state: ReportState) => {
      state.search.isLoading = false;
      state.search.hasError = true;
    });
    builder.addCase(getLatestReport.rejected, (state: ReportState, action: any) => {
      state.isLoadingReport = false;
      if (action?.payload?.status === 404) {
        state.reportUnavailable = true;
      }
      state.hasErrorReport = true;
    });
    builder.addMatcher(isAnyOf(userOnlineStatus), (state: ReportState, action) => {
      const { sprint, year } = state;
      if (state.statistics.data && year && sprint && state.report[year][sprint]) {
        state.statistics.data.usersOnline = action.payload.report.usersOnline;
      }
    });
    builder.addMatcher(
      isAnyOf(
        getLatestReport.pending,
        getReport.pending,
        getPreviousReport.pending,
        getNextReport.pending,
      ),
      (state) => {
        state.isLoadingReport = true;
      },
    );
    builder.addMatcher(
      isAnyOf(getReport.rejected, getPreviousReport.rejected, getNextReport.rejected),
      (state) => {
        state.isLoadingReport = false;
      },
    );
    builder.addMatcher(
      isAnyOf(
        getLatestReport.fulfilled,
        getReport.fulfilled,
        getPreviousReport.fulfilled,
        getNextReport.fulfilled,
      ),
      setReport,
    );

    builder.addMatcher(isAnyOf(activityArchived), (state, action) => {
      const { id } = action.payload;
      const { year, sprint } = state;
      const report = state.report[year!][sprint!];
      report.activities = report.activities.filter((project) => project.id !== id);
    });

    builder.addMatcher(isAnyOf(activityUnarchived), (state, action) => {
      const { year, sprint } = state;
      const report = state.report[year!][sprint!];

      if (!state.newUnarchivedActivities.includes(action.payload.id)) {
        state.newUnarchivedActivities.push(action.payload.id);
      }

      report.activities.push(action.payload);
    });

    builder.addMatcher(
      isAnyOf(createProject.fulfilled, activityCreated),
      (state: ReportState, action) => {
        const { sprint, year } = state;
        const projectId = action.payload.id;
        if (year && sprint) {
          const alreadyExists = state.report[year][sprint].activities.some(
            (project) => project.id === projectId,
          );

          if (!alreadyExists) {
            const report = state.report[year][sprint];
            report.activities.push(action.payload);
            state.comments[year][sprint][projectId] = report.units.map(({ id, name }) => ({
              id,
              name,
              comments: [],
            }));
          }
        }
      },
    );
    builder.addMatcher(
      isAnyOf(updateProjectSettings.fulfilled, activityUpdated),
      (state: ReportState, action) => {
        const { sprint, year } = state;
        const { payload } = action;

        if (year && sprint && get(state.report, `${year}.${sprint}.activities`)) {
          state.report[year][sprint].activities = state.report[year][sprint].activities.map(
            (project) => (project.id === payload.id ? { ...project, ...payload } : project),
          );
        }
      },
    );
    builder.addMatcher(
      isAnyOf(removeProject.fulfilled, activityDeleted),
      (state: ReportState, action) => {
        const { sprint, year } = state;
        const { id } = action.payload;

        if (year && sprint && get(state.report, `${year}.${sprint}.activities`)) {
          state.report[year][sprint].activities = state.report[year][sprint].activities.filter(
            (project) => project.id !== id,
          );
        }
      },
    );

    builder.addMatcher(
      isAnyOf(createRemark.fulfilled, remarkCreated),
      (state: ReportState, action) => {
        const { sprint, year } = state;
        const path = remarkTypeToPath[action.payload.type];

        if (year && sprint) {
          const remarks = state.report[year][sprint][path];
          const alreadyExists =
            remarks && remarks.some((remark) => remark.id === action.payload.id);

          if (!alreadyExists) {
            state.report[year][sprint][path].push(action.payload);
          }
        }
      },
    );
    builder.addMatcher(
      isAnyOf(removeRemark.fulfilled, remarkDeleted),
      (state: ReportState, action: any) => {
        // action: any beacause both actions have different payload
        const { sprint, year } = state;
        const { id } = action.payload;
        const { path: _path, type } = action.meta?.arg || action.payload;
        const path = _path || remarkTypeToPath[type];

        if (year && sprint && get(state.report, `${year}.${sprint}.${path}`)) {
          state.report[year][sprint][path] = state.report[year][sprint][path].filter(
            (remark) => remark.id !== id,
          );
        }
      },
    );
    builder.addMatcher(
      isAnyOf(updateRemark.fulfilled, remarkUpdated),
      (state: ReportState, action) => {
        const { sprint, year } = state;
        const { id, desc, type } = action.payload;
        const path = remarkTypeToPath[type];

        if (year && sprint && get(state.report, `${year}.${sprint}.${path}`)) {
          state.report[year][sprint][path] = state.report[year][sprint][path].map((remark) =>
            remark.id === id ? { ...remark, desc } : remark,
          );
        }
      },
    );

    builder.addMatcher(
      isAnyOf(createEvent.fulfilled, eventCreated),
      (state: ReportState, action) => {
        const { sprint, year } = state;

        if (year && sprint) {
          const events = state.report[year][sprint].events;
          const alreadyExists = events && events.some((event) => event.id === action.payload.id);

          if (!alreadyExists && events) {
            state.report[year][sprint].events.push(action.payload);
          }
        }
      },
    );
    builder.addMatcher(
      isAnyOf(removeEvent.fulfilled, eventDeleted),
      (state: ReportState, action) => {
        const { sprint, year } = state;
        const { id } = action.payload;

        if (year && sprint && get(state.report, `${year}.${sprint}.events`)) {
          state.report[year][sprint].events = state.report[year][sprint].events.filter(
            (event: any) => event.id !== id,
          );
        }
      },
    );
    builder.addMatcher(
      isAnyOf(updateEvent.fulfilled, eventUpdated),
      (state: ReportState, action) => {
        const { sprint, year } = state;
        const { id } = action.payload;

        if (year && sprint && get(state.report, `${year}.${sprint}.events`)) {
          state.report[year][sprint].events = state.report[year][sprint].events.map((event: any) =>
            event.id === id ? { ...event, ...action.payload } : event,
          );
        }
      },
    );
    builder.addMatcher(
      isAnyOf(addProjectComment.fulfilled, commentCreated),
      (state: ReportState, action) => {
        const { sprint, year } = state;
        const { activity: projectId, unit, id } = action.payload;

        if (year && sprint) {
          const unitComments = state.comments[year]?.[sprint]?.[projectId].find(
            (comment) => comment.id === unit,
          )?.comments;
          const alreadyExists = unitComments?.some((comment) => comment.id === id);

          if (!alreadyExists) {
            state.comments[year]?.[sprint]?.[projectId]
              .find((comment) => comment.id === unit)
              ?.comments.unshift(action.payload);
          }
        }
      },
    );
    builder.addMatcher(
      isAnyOf(updateProjectComment.fulfilled, commentUpdated),
      (state: ReportState, action) => {
        const { sprint, year } = state;
        const {
          id,
          activity: projectId,
          unit,
          content,
          status,
          created,
          tags,
          author,
          modified,
          previous,
          labels,
        } = action.payload;

        if (year && sprint !== undefined && sprint !== null) {
          const commentUnit = state.comments[year]?.[sprint]?.[projectId].find(
            (comment) => comment.id === unit,
          );
          if (commentUnit) {
            const comment = commentUnit?.comments.find((comment) => comment.id === id);
            if (comment) {
              comment.content = content;
              comment.created = created;
              comment.status = status;
              comment.tags = tags;
              comment.author = author;
              comment.modified = modified;
              comment.previous = previous;
              comment.unit = unit;
              comment.labels = labels;
            }
          }
        }
      },
    );
    builder.addMatcher(
      isAnyOf(deleteProjectComment.fulfilled, commentDeleted),
      (state: ReportState, action) => {
        const { sprint, year } = state;
        const { activity: projectId, id, unit } = action.payload;

        if (year && sprint !== undefined && sprint !== null) {
          const commentsUnit = state.comments[year]?.[sprint]?.[projectId].find(
            (comment) => comment.id === unit,
          );
          if (commentsUnit) {
            commentsUnit.comments = commentsUnit.comments.filter((comment) => comment.id !== id);
          }
        }
      },
    );
  },
});

export const useGetCurrentReport = () =>
  useAppSelector((state) => {
    const { report, sprint, year } = state.report;

    if (sprint && year && report && report[year] && report[year][sprint]) {
      return report[year][sprint];
    }

    return null;
  });

export const useGetNewUnarchivedActivities = () =>
  useAppSelector((state) => {
    return state.report.newUnarchivedActivities;
  });

export const useGetIsReportLoading = () => useAppSelector((state) => state.report.isLoadingReport);

export const useGetComments = ({ year, sprint, projectId }) =>
  useAppSelector((state) => state.report.comments?.[year]?.[sprint]?.[projectId] || []);

export const useGetCommentsFromActivities = (activities: Project[]) => {
  return useAppSelector((state) => {
    const { comments, sprint, year } = state.report;
    if (sprint && year && comments && comments[year] && comments[year][sprint]) {
      return activities.map((activity) => ({
        activity,
        comments:
          comments?.[year]?.[sprint]?.[activity.id]?.flatMap(
            (commentSection) => commentSection.comments,
          ) || [],
      }));
    } else {
      return activities.map((activity) => ({
        activity,
        comments: [],
      }));
    }
  });
};

export const useGetUsersFromReportCommentAndRemarks = (type: TagType, path: RemarkPath) => {
  const comments = useGetAllCurrentSprintCommentsByType(type);
  const remarks = useGetRemarks(path);

  const commentsUser: User[] = comments.map((comment) => comment.author);

  const remarksUser: User[] = remarks.map((remark) => remark.author);

  return commentsUser.concat(remarksUser).reduce((accumulator, user) => {
    return accumulator.find((author) => user.id === author.id)
      ? accumulator
      : [...accumulator, user];
  }, [] as User[]);
};

export const useGetAllCurrentSprintComments = () =>
  useAppSelector((state) => {
    const { comments, sprint, year } = state.report;

    if (sprint && year && comments && comments[year] && comments[year][sprint]) {
      return Object.keys(comments[year][sprint])
        .flatMap((projectId) => comments[year][sprint][projectId])
        .flatMap((data) => data.comments);
    }

    return [];
  });

export const useGetAllCurrentSprintCommentsByType = (type: TagType) => {
  const comments = useGetAllCurrentSprintComments();

  return comments.filter((comment) => comment?.tags?.[type]);
};

export const useGetAllRemarksFromProjects = (type: TagType, path: RemarkPath) => {
  const comments = useGetAllCurrentSprintCommentsByType(type);

  return comments.map((comment) => ({
    desc: comment.content,
    id: comment.id,
    type: path,
    created: comment.created,
    author: comment.author,
    comment,
  }));
};

export const useGetUsersFromEvents = (): User[] => {
  const events: Event[] = useGetEvents();
  const eventsUser: User[] = events.map((event) => event.author);

  return eventsUser.reduce((accumulator, user) => {
    return accumulator.find((author) => user.id === author.id)
      ? accumulator
      : [...accumulator, user];
  }, [] as User[]);
};

export const useGetRemarks = (path: RemarkPath): Remark[] => {
  const currentReport = useGetCurrentReport();

  return currentReport ? currentReport[path] : [];
};

export const useGetEvents = (): Event[] => {
  const currentReport = useGetCurrentReport();

  return currentReport ? currentReport['events'] : [];
};

export const useGetProjects = () => {
  const currentReport = useGetCurrentReport();

  return currentReport ? sortBy(currentReport.activities, ['area', 'subarea', 'title']) : [];
};

export const useGetReportLocked = () => {
  const currentReport = useGetCurrentReport();

  return currentReport?.locked;
};

export const useGetEditable = () => {
  const currentReport = useGetCurrentReport();

  return currentReport?.status !== 'past';
};

export const useGetPrevious = () => useAppSelector((state) => state.report.previous);

export const useGetIsReportUnavailable = () =>
  useAppSelector((state) => state.report.reportUnavailable);
export const useGetHasReportError = () => useAppSelector((state) => state.report.hasErrorReport);
export const useGetYear = () => useAppSelector((state) => state.report.year);
export const useGetSprint = () => useAppSelector((state) => state.report.sprint);
export const useGetActis = () => useAppSelector((state) => state.report.newActis);
export const useGetActisData = () => useAppSelector((state) => state.report.actis);
export const useGetCostCodes = () => useAppSelector((state) => state.report.costCodes);
export const useGetWlmsData = () => useAppSelector((state) => state.report.wlms);
export const useGetError = () => useAppSelector((state) => state.report.error);
export const useGetFilters = () => useAppSelector((state) => state.report.filters);
export const useGetListMode = () => useAppSelector((state) => state.report.listMode);
export const useGetSearch = () => useAppSelector((state) => state.report.search.data);
export const useIsSearchLoading = () => useAppSelector((state) => state.report.search.isLoading);
export const useGetReportStatistics = () => useAppSelector((state) => state.report.statistics.data);
export const useIsReportStatisticsLoading = () =>
  useAppSelector((state) => state.report.statistics.isLoading);

export const useGetCurrentRole = (): string => {
  const currentReport = useGetCurrentReport();

  return currentReport?.units?.[0]?.roleName ?? '';
};

export const {
  setSectionFilter,
  toggleOnlyMyActivities,
  changeListMode,
  toggleOnlyRecentUpdates,
  toggleOnlyActions,
  setVisibilityFilter,
  reportFatalError,
  setSearchFilter,
  setAuthorFilter,
  setSearchDateFilter,
  addUnarchivedProject,
  clearNewUnarchivedActivieties,
} = reportSlice.actions;

export const reportReducer = reportSlice.reducer;
