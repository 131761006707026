import AreasPanel from 'containers/AreasPanel';
import React from 'react';
import ReportPage from 'pages/ReportPage';

const ActivitiesPage = () => (
  <ReportPage
    onlyMyActivitiesLabel="Show only my activities"
    showActivityButtons
    showVisibilityFilter>
    <AreasPanel />
  </ReportPage>
);

export default ActivitiesPage;
