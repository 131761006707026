import React, { ReactNode } from 'react';
import { useGetHasReportError, useGetIsReportUnavailable } from 'redux/report/reportSlice';

import MenuBar from '../containers/MenuBar';
import { User } from 'types/Report';

export interface BaseReportPageProps {
  children: ReactNode;
  showActivityButtons?: boolean;
  showVisibilityFilter?: boolean;
  showBasicFilters?: boolean;
  showAuthorFilter?: boolean;
  showSearchDateFilter?: boolean;
  hideOnlyActionsFilter?: boolean;
  hideOnlyMyActivities?: boolean;
  authors?: User[];
  searchDatePlaceholder?: string;
  onlyMyActivitiesLabel?: string;
}

const ReportPage = ({
  authors,
  children,
  showActivityButtons,
  showVisibilityFilter,
  showBasicFilters,
  showAuthorFilter,
  showSearchDateFilter,
  searchDatePlaceholder,
  hideOnlyActionsFilter,
  hideOnlyMyActivities,
  onlyMyActivitiesLabel,
}: BaseReportPageProps) => {
  const isReportUnavailable = useGetIsReportUnavailable();
  const hasError = useGetHasReportError();
  if (hasError) {
    return (
      <div className="standard-container-wrapper-centralised">
        {isReportUnavailable ? 'Active sprint does not exist.' : 'Cannot retrieve the report.'}
      </div>
    );
  }

  return (
    <div className="standard-container-wrapper">
      <MenuBar
        authors={authors}
        hideOnlyActionsFilter={hideOnlyActionsFilter}
        hideOnlyMyActivities={hideOnlyMyActivities}
        onlyMyActivitiesLabel={onlyMyActivitiesLabel}
        searchDatePlaceholder={searchDatePlaceholder}
        showActivityButtons={showActivityButtons}
        showAuthorFilter={showAuthorFilter}
        showBasicFilters={showBasicFilters}
        showSearchDateFilter={showSearchDateFilter}
        showVisibilityFilter={showVisibilityFilter}
      />
      {children}
    </div>
  );
};

export default ReportPage;
