import { EditCommentBaseProps, default as EditCommentComponent } from '../components/EditComment';

import { Comment } from 'types/Report';
import { CommentUpdate } from 'redux/report/types';
import React from 'react';
import { updateProjectComment as updateProjectCommentAction } from '../redux/report/reportActions';
import { useFetchData } from 'utils/fetchData';

interface EditCommentContainerProps extends EditCommentBaseProps {
  comment: Comment;
  onClose?: () => void;
}

const EditComment = ({ comment, onClose, ...props }: EditCommentContainerProps) => {
  const updateProjectComment = useFetchData<CommentUpdate>(updateProjectCommentAction, {
    model: 'Comment',
    errorMessage: 'Failed to update comment',
    successMessage: 'Comment has been updated successfully',
    onFinish: onClose,
  });

  return (
    <EditCommentComponent
      {...props}
      comment={comment}
      limitText={false}
      onSubmit={({ content, tags, status, labels }) =>
        updateProjectComment({
          commentId: comment.id,
          content,
          tags,
          unit: comment.unit,
          status,
          projectId: comment.activity,
          labels,
        })
      }
    />
  );
};

export default EditComment;
