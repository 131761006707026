import FieldGroup, { FieldGroupProps } from 'components/FieldGroup';

import React from 'react';
import styles from './SidebarSelect.module.scss';

const SidebarSelect = ({ children, id, label, ...props }: FieldGroupProps) => (
  <FieldGroup
    {...props}
    as="select"
    className={styles.sidebarSelect}
    id={id}
    label={label}
    labelClassName={styles.sidebarSelectLabel}
    wrapperClassName={styles.sidebarSelectWrapper}>
    {children}
  </FieldGroup>
);

export default SidebarSelect;
