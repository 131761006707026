import { Card, Spinner } from 'react-bootstrap';
import React, { FC } from 'react';
import { RemarkPath, TagType } from '../../redux/report/types';
import RemarksTable, { RemarksTableData } from './RemarksTable';

import { NewRemarkCreation } from '../../containers';
import styles from './RemarksPanel.module.scss';

export interface RemarksPanelBaseProps {
  path: RemarkPath;
  tagType: TagType;
  title: string;
}

export type RemarkData = RemarksTableData;

interface Props extends RemarksPanelBaseProps {
  remarks: RemarkData[];
  removeRemark?: (item: RemarkData) => any;
  updateRemark?: (item: RemarkData, desc: string) => any;
  onLinkClick: (item: RemarkData) => any;
  isLoading: boolean;
  canAdd: boolean;
}

const RemarksPanel: FC<Props> = ({
  path,
  title,
  remarks,
  removeRemark,
  updateRemark,
  onLinkClick,
  isLoading,
  canAdd,
}: Props) => (
  <Card className={styles.remarksPanel}>
    <Card.Body>
      {isLoading ? (
        <div className={styles.loadingWrapper} data-testid="remark-spinner">
          <Spinner animation="border" />
        </div>
      ) : (
        <>
          {canAdd && <NewRemarkCreation canAdd={canAdd} path={path} title={title} />}
          <RemarksTable
            remarks={remarks}
            removeRemark={removeRemark}
            title={title}
            updateRemark={updateRemark}
            onLinkClick={onLinkClick}
          />
        </>
      )}
    </Card.Body>
  </Card>
);

export default RemarksPanel;
