export const connectSocket = (socketType: string, host: string) => ({
  type: 'WS_CONNECT',
  host,
  socketType,
});
export const disconnectSocket = (socketType: string) => ({
  type: 'WS_DISCONNECT',
  socketType,
});

const createSocketMiddleware = (
  socketType,
  { onOpen = (store) => null, onClose = (store) => null, onMessage = (message, store) => null },
) => {
  let socket: WebSocket | null = null;

  const handleOpen = (store) => () => onOpen && onOpen(store);

  const handleClose = (store) => () => onClose && onClose(store);

  const handleMessage = (store) => (event) => {
    const message = JSON.parse(event.data);

    return onMessage && onMessage(message, store);
  };

  return (store) => (next) => (action) => {
    switch (action.type) {
      case 'WS_CONNECT':
        if (socketType === action.socketType) {
          if (socket) {
            socket.close();
          }
          try {
            socket = new WebSocket(action.host);
            socket.onmessage = handleMessage(store);
            socket.onclose = handleClose(store);
            socket.onopen = handleOpen(store);
          } catch (e) {
            // TODO: we should do something on websocket fail
            console.error(e);
          }
        }
        break;
      case 'WS_DISCONNECT':
        if (socketType === action.socketType) {
          if (socket) {
            socket.close();
          }
          socket = null;
        }
        break;

      default:
        return next(action);
    }
  };
};

export default createSocketMiddleware;
