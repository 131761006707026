import React, { useMemo } from 'react';

import { Form } from 'react-bootstrap';
import clsx from 'clsx';
import styles from './SearchPage.module.scss';

interface Props {
  title: string;
  sectionName: string;
  options: any[];
  expanded: boolean;
  state: any;
  onOptionChange: (sectionName: string, name: string) => void;
}

const SearchFormFilterSection = ({
  title,
  sectionName,
  options,
  expanded,
  state,
  onOptionChange,
}: Props) => {
  const selectedOptions = useMemo(() => Object.values(state).filter((o) => !!o).length, [state]);

  return (
    <div className={styles.searchPageFormSection}>
      <div className={styles.searchPageFormSectionHeader}>
        <div className={styles.searchPageFormSectionTitle}>{title}</div>
        {selectedOptions > 0 && (
          <div className={styles.searchPageFormSectionBadge}>{selectedOptions}</div>
        )}
      </div>
      {options && expanded && (
        <div className={styles.searchPageFormSectionOptions}>
          {options.map((option) => {
            const { label, name } = option;
            const checked = state[name];

            return (
              <Form.Check
                checked={checked}
                className={clsx(checked && styles.searchPageFormSectionOptionChecked)}
                id={`${title}-${name}`}
                key={name}
                label={label}
                type="checkbox"
                onChange={() => onOptionChange(sectionName, name)}
              />
            );
          })}
        </div>
      )}
    </div>
  );
};

export default SearchFormFilterSection;
