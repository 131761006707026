import envBool from 'env-bool';

export const NODE_ENV = process.env.NODE_ENV; // eslint-disable-line

export const PUBLIC_URL = process.env.PUBLIC_URL; // eslint-disable-line

export const API_URL = window._env_?.API_URL; // eslint-disable-line

export const WS_URL = window._env_?.WS_URL; // eslint-disable-line

export const GIT_COMMIT_SHA = window._env_?.GIT_COMMIT_SHA; // eslint-disable-line

export const DEBUG = envBool(window._env_?.DEBUG, true); // eslint-disable-line

export const WORD_REPORT_CREATE_QUERY = 'wordreport/create/';
export const WORD_REPORT_WITH_HISTORY_CREATE_QUERY = 'wordreportwithhistory/create/';

export const MSG_CONNECTION_LOST =
  'Error connecting to server, your changes may be lost! Please try to reload the page.';
