import {
  Area,
  Areas,
  Labels,
  LockedPeriod,
  LockedPeriods,
  Organisations,
  Units,
} from 'types/Report';

import { LockedPeriodCreate } from 'redux/report/types';
import { ORGANISATIONS_QUERY } from './const';
import { OUT_EVENT_DATE_FORMAT } from '../../constants';
import { ReportAxiosRequestConfig } from 'types/Axios';
import apiCall from '../apiCall';
import { createAsyncThunk } from '@reduxjs/toolkit';
import formatFns from 'date-fns/format';
import { validateResponse } from 'utils/validateResponse';

export const getOrganisations = createAsyncThunk(
  'organisation/getOrganisations',
  async (_, { signal }) => {
    const response = await apiCall.get<Organisations>(ORGANISATIONS_QUERY, { signal });

    return validateResponse(Organisations, response.data);
  },
);

export const getOrganisationUnits = createAsyncThunk('organisation/getUnits', async () => {
  const response = await apiCall.get<Units>('units/', {
    withOrganisation: true,
  } as ReportAxiosRequestConfig);

  return validateResponse(Units, response.data);
});

export const getOrganisationAreas = createAsyncThunk('organisation/getAreas', async () => {
  const response = await apiCall.get<Area[]>(`areas/`, {
    withOrganisation: true,
    withSection: true,
  } as ReportAxiosRequestConfig);

  return validateResponse(Areas, response.data);
});

export const searchCurrentOrganisationLabels = createAsyncThunk(
  'organisation/searchLabels',
  async (query: string) => {
    const response = await apiCall.get(`labels/?search=${query}`, {
      withOrganisation: true,
    } as ReportAxiosRequestConfig);

    return validateResponse(Labels, response.data);
  },
);

export const getAllCurrentOrganisationLabels = createAsyncThunk(
  'organisation/allCurrOrgLabels',
  async () => {
    const response = await apiCall.get(`labels/`, {
      withOrganisation: true,
    } as ReportAxiosRequestConfig);

    return validateResponse(Labels, response.data);
  },
);

export const getAllOrganisationLabels = createAsyncThunk(
  'organisation/allOrgLabels',
  async (id: number, { signal }) => {
    const response = await apiCall.get(`reptool/organisations/${id}/labels/`, {
      signal,
    } as ReportAxiosRequestConfig);

    return validateResponse(Labels, response.data);
  },
);

export const getOrganisationUsers = createAsyncThunk(
  'organisation/getOrganisationUsers',
  async () => {
    const response = await apiCall.get('users/', {
      withOrganisation: true,
    } as ReportAxiosRequestConfig);

    return response.data;
  },
);

export const getOrganisationLockedPeriods = createAsyncThunk(
  'organisation/getOrganisationLockedPeriods',
  async () => {
    const response = await apiCall.get('locked-periods/', {
      withOrganisation: true,
    } as ReportAxiosRequestConfig);

    return validateResponse(LockedPeriods, response.data);
  },
);

export const createOrganisationLockedPeriod = createAsyncThunk(
  'organisation/locked-period/create',
  async (data: LockedPeriodCreate, { rejectWithValue }) => {
    try {
      const { desc, unit } = data;
      const startDate = formatFns(data.startDate, OUT_EVENT_DATE_FORMAT);
      const endDate = formatFns(data.endDate, OUT_EVENT_DATE_FORMAT);

      const response = await apiCall.post(
        `locked-periods/`,
        {
          desc,
          startDate,
          endDate,
          unit,
        },
        {
          withOrganisation: true,
        } as ReportAxiosRequestConfig,
      );

      return validateResponse(LockedPeriod, response.data);
    } catch (error: any) {
      return rejectWithValue(error?.response);
    }
  },
);

export const updateOrganisationLockedPeriod = createAsyncThunk(
  'organisation/locked-period/update',
  async (data: LockedPeriod, { rejectWithValue }) => {
    try {
      const { desc, unit, id } = data;
      const startDate = formatFns(new Date(data.startDate), OUT_EVENT_DATE_FORMAT);
      const endDate = formatFns(new Date(data.endDate), OUT_EVENT_DATE_FORMAT);

      const response = await apiCall.put(
        `locked-periods/${id}/`,
        {
          desc,
          startDate,
          endDate,
          unit,
        },
        {
          withOrganisation: true,
        } as ReportAxiosRequestConfig,
      );

      return validateResponse(LockedPeriod, response.data);
    } catch (error: any) {
      return rejectWithValue(error?.response);
    }
  },
);

export const deleteOrganisationLockedPeriod = createAsyncThunk(
  'organisation/locked-period/delete',
  async (id: number, { rejectWithValue }) => {
    try {
      const response = await apiCall.delete(`locked-periods/${id}/`, {
        withOrganisation: true,
      } as ReportAxiosRequestConfig);

      return response.data;
    } catch (error: any) {
      return rejectWithValue(error?.response);
    }
  },
);
