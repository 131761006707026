import type { AxiosInstance } from 'axios';
import store from '../redux/store';
export default (axiosInstance: AxiosInstance) => {
  axiosInstance.interceptors.request.use((config) => {
    const token = store.getState?.()?.session?.token?.accessToken;
    if (token && config.headers) {
      config.headers.Authorization = `Bearer ${token}`;
    }

    return config;
  });
};
