import {
  FLUSH,
  PAUSE,
  PERSIST,
  PURGE,
  REGISTER,
  REHYDRATE,
  persistReducer,
  persistStore,
} from 'redux-persist';
import { combineReducers, configureStore } from '@reduxjs/toolkit';

import { DEBUG } from './const';
import { activityHistoryReducer } from './activityHistory/activityHistorySlice';
import { archivedProjectsReducer } from './archivedProjects/archivedProjectsSlice';
import { createLogger } from 'redux-logger';
import { ideaReducer } from './idea/ideaSlice';
import { organisationReducer } from './organisation/organisationSlice';
import { reportReducer } from './report/reportSlice';
import reportSocketMiddleware from './report/reportSocketMiddleware';
import { sessionReducer } from './session/sessionSlice';
import storage from 'redux-persist/lib/storage/session';
import { userConfigReducer } from './userConfig/userConfigSlice';

const rootReducer = combineReducers({
  idea: ideaReducer,
  session: sessionReducer,
  userConfig: userConfigReducer,
  report: reportReducer,
  activityHistory: activityHistoryReducer,
  archivedProjects: archivedProjectsReducer,
  organisation: organisationReducer,
});

const persistConfig = {
  key: 'root',
  storage,
  blacklist: ['report', 'archivedProjects', 'idea', 'activityHistory', 'organisation'],
};

const persistedReducer = persistReducer(persistConfig, rootReducer);

const store = configureStore({
  reducer: persistedReducer,
  middleware: (getDefaultMiddleware) => {
    const middlewares = getDefaultMiddleware({
      serializableCheck: {
        ignoredActions: [FLUSH, REHYDRATE, PAUSE, PERSIST, PURGE, REGISTER],
      },
    }).concat(reportSocketMiddleware);

    if (DEBUG) {
      middlewares.push(createLogger({ diff: true }));
    }

    return middlewares;
  },
});

export const persistor = persistStore(store);

export type RootState = ReturnType<typeof store.getState>;
export type AppDispatch = typeof store.dispatch;

export default store;
