export enum UserPermission {
  UNKNOWN = 'unknown',
  ADD_COMMENT = 'reptool.add_comment',
  CHANGE_COMMENT = 'reptool.change_comment',
  DELETE_COMMENT = 'reptool.delete_comment',
  VIEW_COMMENT = 'reptool.view_comment',
  ADD_IDEA = 'reptool.add_idea',
  CHANGE_IDEA = 'reptool.change_idea',
  DELETE_IDEA = 'reptool.delete_idea',
  VIEW_IDEA = 'reptool.view_idea',
  ADD_PROJECT = 'reptool.add_project',
  CHANGE_PROJECT = 'reptool.change_project',
  DELETE_PROJECT = 'reptool.delete_project',
  VIEW_PROJECT = 'reptool.view_project',
  ADD_REMARK = 'reptool.add_remark',
  CHANGE_REMARK = 'reptool.change_remark',
  DELETE_REMARK = 'reptool.delete_remark',
  VIEW_REMARK = 'reptool.view_remark',
  ADD_REPORT = 'reptool.add_report',
  CHANGE_REPORT = 'reptool.change_report',
  DELETE_REPORT = 'reptool.delete_report',
  VIEW_REPORT = 'reptool.view_report',
  VIEW_REPORT_STATISTIC = 'reptool.view_report_statistics',
  ADD_UPCOMING_EVENT = 'reptool.add_upcomingevent',
  CHANGE_UPCOMING_EVENT = 'reptool.change_upcomingevent',
  DELETE_UPCOMING_EVENT = 'reptool.delete_upcomingevent',
  VIEW_UPCOMING_EVENT = 'reptool.view_upcomingevent',
  ADD_ORGANISATION = 'reptool.add_organisation',
  CHANGE_ORGANISATION = 'reptool.change_organisation',
  DELETE_ORGANISATION = 'reptool.delete_organisation',
  VIEW_ORGANISATION = 'reptool.view_organisation',
  ADD_COMMENT_TAGS = 'reptool.add_commenttags',
  CHANGE_COMMENT_TAGS = 'reptool.change_commenttags',
  DELETE_COMMENT_TAGS = 'reptool.delete_commenttags',
  VIEW_COMMENT_TAGS = 'reptool.view_commenttags',
  ADD_AREA = 'reptool.add_area',
  CHANGE_AREA = 'reptool.change_area',
  DELETE_AREA = 'reptool.delete_area',
  VIEW_AREA = 'reptool.view_area',
  ADD_UNIT = 'reptool.add_unit',
  CHANGE_UNIT = 'reptool.change_unit',
  DELETE_UNIT = 'reptool.delete_unit',
  VIEW_UNIT = 'reptool.view_unit',
  ADD_SUBAREA = 'reptool.add_subarea',
  CHANGE_SUBAREA = 'reptool.change_subarea',
  DELETE_SUBAREA = 'reptool.delete_subarea',
  VIEW_SUBAREA = 'reptool.view_subarea',
  ADD_LOCKED_PERIOD = 'reptool.add_lockedperiod',
  CHANGE_LOCKED_PERIOD = 'reptool.change_lockedperiod',
  DELETE_LOCKED_PERIOD = 'reptool.delete_lockedperiod',
  VIEW_LOCKED_PERIOD = 'reptool.view_lockedperiod',
  ADD_UNIT_SETTINGS = 'reptool.add_unitsettings',
  CHANGE_UNIT_SETTINGS = 'reptool.change_unitsettings',
  DELETE_UNIT_SETTINGS = 'reptool.delete_unitsettings',
  VIEW_UNIT_SETTINGS = 'reptool.view_unitsettings',
  ADD_UNIT_ACTIS = 'reptool.add_actis',
  CHANGE_UNIT_ACTIS = 'reptool.change_actis',
  DELETE_UNIT_ACTIS = 'reptool.delete_actis',
  VIEW_UNIT_ACTIS = 'reptool.view_actis',
  ADD_COST_CODE = 'reptool.add_costcode',
  CHANGE_COST_CODE = 'reptool.change_costcode',
  DELETE_COST_CODE = 'reptool.delete_costcode',
  VIEW_COST_CODE = 'reptool.view_costcode',
  ADD_MILESTONEACTIS = 'reptool.add_milestoneactis',
  CHANGE_MILESTONEACTIS = 'reptool.change_milestoneactis',
  DELETE_MILESTONEACTIS = 'reptool.delete_milestoneactis',
  VIEW_MILESTONEACTIS = 'reptool.view_milestoneactis',
  ARCHIVE_IDEA = 'reptool.archive_idea',
  UNARCHIVE_IDEA = 'reptool.unarchive_idea',
  VIEW_ARCHIVED_IDEA = 'reptool.view_archived_idea',
  ARCHIVE_PROJECT = 'reptool.archive_project',
  UNARCHIVE_PROJECT = 'reptool.unarchive_project',
  VIEW_ARCHIVED_PROJECT = 'reptool.view_archived_project',
  ARCHIVE_REPORT = 'reptool.archive_report',
  UNARCHIVE_REPORT = 'reptool.unarchive_report',
  VIEW_ARCHIVED_REPORT = 'reptool.view_archived_report',
  ADD_ACTION = 'reptool.add_action',
  CHANGE_ACTION = 'reptool.change_action',
  DELETE_ACTION = 'reptool.delete_action',
  VIEW_ACTION = 'reptool.view_action',
  ADD_DECISION = 'reptool.add_decision',
  CHANGE_DECISION = 'reptool.change_decision',
  DELETE_DECISION = 'reptool.delete_decision',
  VIEW_DECISION = 'reptool.view_decision',
  ADD_NEEDTOKNOW = 'reptool.add_needtoknow',
  CHANGE_NEEDTOKNOW = 'reptool.change_needtoknow',
  DELETE_NEEDTOKNOW = 'reptool.delete_needtoknow',
  VIEW_NEEDTOKNOW = 'reptool.view_needtoknow',
  ADD_ACTIVITY = 'reptool.add_activity',
  CHANGE_ACTIVITY = 'reptool.change_activity',
  DELETE_ACTIVITY = 'reptool.delete_activity',
  VIEW_ACTIVITY = 'reptool.view_activity',
  ARCHIVE_ACTIVITY = 'reptool.archive_activity',
  UNARCHIVE_ACTIVITY = 'reptool.unarchive_activity',
  VIEW_ARCHIVED_ACTIVITY = 'reptool.view_archived_activity',
  COPY_ALL_COMMENTS = 'reptool.copy_all_comments_from_previous_activity',
}
