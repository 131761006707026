import * as Yup from 'yup';

import { Button, Card, Form } from 'react-bootstrap';
import { Controller, UseFormWatch, useForm } from 'react-hook-form';
import React, { ElementType } from 'react';

import CharacterCounter from 'components/CharacterCounter';
import { ReactComponent as CloseIcon } from 'assets/icons/status/close.svg';
import LoadingSelect from '../LoadingSelect';
import clsx from 'clsx';
import styles from './CustomForm.module.scss';
import { yupResolver } from '@hookform/resolvers/yup';

const Options = ({ options, getOptionValue, getOptionLabel, field }) => (
  <>
    {options.map((option) => (
      <option key={`${field.name}-${getOptionValue(option)}`} value={getOptionValue(option)}>
        {getOptionLabel(option)}
      </option>
    ))}
  </>
);

interface Section {
  required?: boolean;
  label: string;
  name: string;
  type?: ElementType<any> | any;
  options?: any[];
  getOptions?: (formState: UseFormWatch<any>) => any[];
  getOptionLabel?: (option: any) => string;
  getOptionValue?: (option: any) => string | number;
  renderEmptyOption?: boolean;
  fetchDataFunc?: () => Promise<any>;
  rows?: number;
  column?: number;
  maxLength?: number;
}

interface CustomFormProps {
  title: string;
  sections: Section[];
  initialState?: any;
  columns?: number;
  onSubmit: (data: any) => Promise<void>;
  onCancel?: () => void;
  disabled?: boolean;
  schema?: Yup.AnyObjectSchema;
  submitLabel?: string;
  resultValidate?: boolean;
}

const CustomForm = ({
  title,
  sections,
  initialState = {},
  columns = 1,
  onSubmit,
  onCancel,
  schema,
  disabled = false,
  submitLabel = 'Save',
  resultValidate,
}: CustomFormProps) => {
  const { control, formState, watch, handleSubmit, setError } = useForm({
    defaultValues: initialState,
    resolver: schema && yupResolver<Yup.AnyObjectSchema>(schema),
  });

  const processSubmit = (data: any) => {
    if (resultValidate) {
      onSubmit(data).catch((errors) => {
        Object.keys(errors).forEach((key) => {
          setError(key, { type: 'custom', message: errors[key]?.[0]?.message });
        });
      });
    } else {
      onSubmit(data);
    }
  };

  const renderSection = (
    {
      label,
      type,
      options,
      getOptions,
      getOptionLabel = (value) => value,
      getOptionValue = (value) => value,
      renderEmptyOption = true,
      fetchDataFunc,
      ...sectionProps
    }: Section,
    { field, fieldState: { error, invalid } }: any,
  ) => {
    let input;
    // * * Text form control cannot has closing tag, that's a reason of using two different approaches
    if (type === 'select') {
      input = (
        <LoadingSelect onLoad={fetchDataFunc}>
          <Form.Control
            as={type as any}
            isInvalid={invalid}
            type={type as any}
            {...sectionProps}
            {...field}>
            {renderEmptyOption && <option value="">Select</option>}
            {options && (
              <Options
                field={field}
                getOptionLabel={getOptionLabel}
                getOptionValue={getOptionValue}
                options={options}
              />
            )}
            {!options && getOptions && (
              <Options
                field={field}
                getOptionLabel={getOptionLabel}
                getOptionValue={getOptionValue}
                options={getOptions(watch)}
              />
            )}
          </Form.Control>
        </LoadingSelect>
      );
    } else if (type === 'checkbox') {
      input = (
        <Form.Check inline>
          <Form.Check.Input
            isInvalid={invalid}
            type={type}
            {...sectionProps}
            {...field}
            checked={field.value}
          />
          <Form.Check.Label className={styles.formLabel}>{label}</Form.Check.Label>
        </Form.Check>
      );
    } else {
      input = (
        <CharacterCounter maxLength={sectionProps.maxLength ?? 0} value={field.value}>
          <Form.Control
            as={type}
            isInvalid={invalid}
            type={type as any}
            {...sectionProps}
            {...field}
          />
        </CharacterCounter>
      );
    }

    return (
      <>
        {input}
        {error && <Form.Control.Feedback type="invalid">{error.message}</Form.Control.Feedback>}
      </>
    );
  };

  const areSomeFieldsRequired = sections.some((section) => section.required);

  return (
    <Card className={styles.form}>
      <div className={styles.formTopBar}>
        <div className={styles.formTitle}>{title}</div>
        <Button variant="icon-small" onClick={onCancel}>
          <CloseIcon />
        </Button>
      </div>
      <Form
        className={clsx(styles.formGrid, columns > 1 && styles.formColumnGrid)}
        style={{ gridTemplateColumns: `repeat(${columns},1fr)` }}
        validated={formState.isSubmitted}>
        {sections.map((section) => {
          const { name, label, type, column, required } = section;

          return (
            <Form.Group controlId={name} key={name} style={{ gridColumn: column }}>
              {type !== 'checkbox' && (
                <Form.Label className={styles.formLabel}>
                  {`${label}`}
                  {required && <span className={styles.formLabelRequired}>*</span>}
                </Form.Label>
              )}
              <Controller
                control={control}
                name={name}
                render={(props: any) => renderSection(section, props)}
              />
            </Form.Group>
          );
        })}
      </Form>
      {areSomeFieldsRequired && (
        <div className={styles.formMandatorySection}>
          Fields marked with <span className={styles.formLabelRequired}>*</span> are mandatory.
        </div>
      )}
      <div className={styles.formButtons}>
        <Button disabled={disabled} onClick={handleSubmit(processSubmit)}>
          {submitLabel}
        </Button>
        <Button variant="outline-secondary" onClick={onCancel}>
          Cancel
        </Button>
      </div>
    </Card>
  );
};

export default CustomForm;
