import { setToken, setUserData } from '../redux/session/sessionSlice';

import React from 'react';
import { default as SamlLoginComponent } from '../components/SamlLogin';
import { TokenData } from '../types/Session';
import { loginSaml } from '../redux/session/sessionActions';
import { useAppDispatch } from '../redux/hooks';
import { useHistory } from 'react-router-dom';

const SamlLogin = () => {
  const dispatch = useAppDispatch();
  const history = useHistory();

  return (
    <SamlLoginComponent
      login={() => dispatch(loginSaml())}
      setToken={(accessToken: string, refreshToken: string) =>
        dispatch(setToken({ accessToken, refreshToken }))
      }
      setUserData={(tokenData: TokenData) => {
        dispatch(setUserData(tokenData));
        history.replace('/');
      }}
    />
  );
};

export default SamlLogin;
