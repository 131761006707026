import {
  setSelectedOrganisation,
  useGetCurrentOrganisation,
  useGetOrganisations,
} from 'redux/organisation/organisationSlice';

import OrganisationSelectComponent from 'components/OrganisationSelect';
import React from 'react';
import { useAppDispatch } from 'redux/hooks';

const OrganisationSelect = () => {
  const organisations = useGetOrganisations();
  const currentOrganisation = useGetCurrentOrganisation();
  const dispatch = useAppDispatch();

  return (
    <OrganisationSelectComponent
      organisation={currentOrganisation?.id}
      organisations={organisations}
      onChange={(organisation) => dispatch(setSelectedOrganisation(organisation))}
    />
  );
};

export default OrganisationSelect;
