import * as t from 'io-ts/lib/index';

import { Unit } from './Report';

const TokenDecoded = t.type({
  tokenType: t.string,
  exp: t.number,
  iat: t.number,
  jti: t.string,
  userId: t.number,
  firstName: t.string,
  lastName: t.string,
  email: t.string,
  units: t.union([t.undefined, t.null, t.array(Unit)]),
});

type TokenDecoded = t.TypeOf<typeof TokenDecoded>;

export interface TokenData {
  tokenType: string;
  expirationDate: number;
  issuedAt: number;
  id: string;
  userId: number;
  firstName: string;
  lastName: string;
  email: string;
  units: Array<Unit>;
}

export interface RefreshTokenResponse {
  access: string;
}

export interface CurrentUser {
  firstName: string;
  lastName: string;
  id: number;
  email: string;
}

export { TokenDecoded };
