import { Organisation, SearchReport, Unit } from 'types/Report';
import React, { useEffect, useMemo } from 'react';

import ExpandedSearchResult from './ExpandedSearchResult';
import { SearchReportParams } from 'redux/report/types';
import { Spinner } from 'react-bootstrap';
import Table from '../../components/Table';
import createColumns from './createColumns';
import styles from './SearchTable.module.scss';

interface Props {
  search: SearchReport | null;
  searchReport: (params: SearchReportParams) => any;
  currentSection: Unit | null;
  organisation: Organisation | null;
  isLoading: boolean;
}

interface SearchRow {
  area?: string;
  date: string;
  label?: { [remark: string]: boolean } | null;
  name: string;
  report?: string;
  status?: string;
  type: string;
}

const SearchTable = ({ search, searchReport, organisation, currentSection, isLoading }: Props) => {
  useEffect(() => organisation && searchReport({}), [organisation?.id, currentSection?.id]);

  const columns = createColumns();

  const data = useMemo(() => {
    let newData: SearchRow[] = [];

    if (search?.comments) {
      const commentData = search.comments.map((comment) => ({
        type: 'Comment',
        date: comment.created,
        name: `${comment.author.firstName} ${comment.author.lastName}`,
        status: comment.status,
        report: comment.content,
        tag: comment.tags,
        role: comment.unit.roleName,
        labels: comment.labels,
        area: `${comment.activityArea?.name} / ${comment.activitySubarea?.name}`,
      }));

      newData = [...newData, ...commentData];
    }

    if (search?.activities) {
      const projectData = search.activities.map((project) => ({
        type: 'Activity',
        date: project.created,
        name: `${project.author.firstName} ${project.author.lastName}`,
        area: `${project.area.name} / ${project.subarea.name}`,
        title: project.title,
      }));

      newData = [...newData, ...projectData];
    }

    const addRemarks = (remarks) => {
      const remarkData = remarks.map((remark) => ({
        type: remark.type,
        date: remark.created,
        name: `${remark.author.firstName} ${remark.author.lastName}`,
        report: remark.desc,
      }));

      newData = [...newData, ...remarkData];
    };

    if (search?.actions) {
      addRemarks(search?.actions);
    }

    if (search?.needToKnows) {
      addRemarks(search?.needToKnows);
    }

    if (search?.decisions) {
      addRemarks(search?.decisions);
    }

    if (search?.upcomingEvents) {
      const eventData = search.upcomingEvents.map((event) => ({
        type: 'Event',
        date: event.created,
        name: `${event.author.firstName} ${event.author.lastName}`,
        report: event.desc,
      }));

      newData = [...newData, ...eventData];
    }

    return newData;
  }, [search]);

  if (isLoading) {
    return (
      <div className={styles.searchTableLoadingWrapper} data-testid="search-spinner">
        <Spinner animation="border" />
      </div>
    );
  }

  return (
    <div className={styles.searchTable}>
      <Table
        columns={columns}
        data={data}
        initialSortBy="date"
        RowSubComponent={ExpandedSearchResult}
        expandable
      />
    </div>
  );
};

export default SearchTable;
