import { IdeaCreate } from 'types/Ideas';
import IdeasFormPage from '../pages/IdeasFormPage';
import React from 'react';
import { createIdea as createIdeaAction } from '../redux/idea/ideaActions';
import { useFetchData } from 'utils/fetchData';

const CreateNewIdeaPage = ({ onClose }) => {
  const createIdea = useFetchData<IdeaCreate>(createIdeaAction, {
    model: 'Idea',
    errorMessage: 'Failed to add idea',
    successMessage: 'Idea has been added successfully',
    onFinish: onClose,
  });

  return <IdeasFormPage title="Create new idea" onCancel={onClose} onSubmit={createIdea} />;
};

export default CreateNewIdeaPage;
