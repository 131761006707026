import { AccordionContext, useAccordionButton } from 'react-bootstrap';
import React, { useContext, useEffect } from 'react';

import clsx from 'clsx';
import styles from './CustomToggle.module.scss';

interface CustomToggleProps extends React.HTMLProps<HTMLElement> {
  children: React.ReactNode;
  eventKey: string;
  Wrapper: React.FunctionComponent<any> | React.ComponentClass<any> | string;
  openState?: boolean;
  initialOpen?: boolean;
}

const CustomToggle = ({
  children,
  eventKey,
  Wrapper,
  openState,
  initialOpen,
  ...props
}: CustomToggleProps) => {
  const { activeEventKey, onSelect } = useContext(AccordionContext);
  const decoratedOnClick = useAccordionButton(eventKey);

  const isActivated = activeEventKey === eventKey;

  useEffect(() => {
    if (openState && !isActivated) {
      onSelect?.(eventKey, {} as any);
    }
    if (!openState && isActivated) {
      onSelect?.(null, {} as any);
    }
  }, [openState]);

  useEffect(() => {
    if (initialOpen) {
      onSelect?.(eventKey, {} as any);
    }
  }, []);

  return (
    <Wrapper eventKey={eventKey} onClick={decoratedOnClick} {...props}>
      <i
        className={clsx(['bi bi-chevron-right', styles.icon, isActivated && styles.iconActivated])}
      />
      {children}
    </Wrapper>
  );
};

export default CustomToggle;
