import Form from 'react-bootstrap/Form';
import type { FormControlProps } from 'react-bootstrap/FormControl';
import React from 'react';

export interface FieldGroupProps extends Omit<FormControlProps, 'onChange'> {
  onChange?: (
    event: React.ChangeEvent<HTMLInputElement>,
  ) => boolean | void | Promise<boolean | void>;
  label?: string;
  name?: string;
  wrapperClassName?: string;
  labelClassName?: string;
  multiple?: boolean;
  ref?: React.ForwardedRef<HTMLDivElement>;
}

const FieldGroup = ({
  id,
  size,
  label,
  onChange,
  children,
  wrapperClassName,
  labelClassName,
  ...props
}: FieldGroupProps) => (
  <Form.Group className={wrapperClassName} controlId={id}>
    {label && <Form.Label className={labelClassName}>{label}</Form.Label>}
    <Form.Control size={size} onChange={onChange as any} {...props}>
      {children}
    </Form.Control>
  </Form.Group>
);

export default FieldGroup;
