import React, { useEffect } from 'react';

import RedirectToPage from './RedirectToPage';
import extractToken from '../utils/extractToken';

const SamlLogin = ({ login, setToken, setUserData }) => {
  useEffect(() => {
    const urlParams = new URLSearchParams(window.location.search);
    const accessToken = urlParams.get('access');
    const refreshToken = urlParams.get('refresh');

    if (accessToken && refreshToken) {
      setToken(accessToken, refreshToken);

      const tokenData = extractToken(accessToken);
      setUserData(tokenData);
    } else {
      setTimeout(() => {
        login();
      }, 1000);
    }
  }, []);

  return <RedirectToPage pageLabel="login" />;
};

export default SamlLogin;
