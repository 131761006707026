import React, { useMemo } from 'react';

import { IdeaUpdate } from 'types/Ideas';
import IdeasFormPage from 'pages/IdeasFormPage';
import { updateIdea as updateIdeaAction } from 'redux/idea/ideaActions';
import { useFetchData } from 'utils/fetchData';
import { useGetIdeas } from 'redux/idea/ideaSlice';

interface Props {
  id: number | null;
  onClose: () => void;
}

const EditIdeaPage = ({ id, onClose }: Props) => {
  const ideas = useGetIdeas();

  const ideaId = id!;

  const idea = useMemo(() => ideas.find((idea) => idea.id === ideaId), [ideaId, ideas]);

  const updateIdea = useFetchData<IdeaUpdate>(updateIdeaAction, {
    model: 'Idea',
    errorMessage: 'Failed to update idea',
    successMessage: 'Idea has been updated successfully',
    onFinish: onClose,
  });

  const handleSubmit = (data) => {
    updateIdea({ id: ideaId, data });
  };

  return (
    <IdeasFormPage
      initialState={idea}
      title="Edit idea"
      onCancel={onClose}
      onSubmit={handleSubmit}
    />
  );
};

export default EditIdeaPage;
