import ProjectToggleComponent, { ProjectToggleBaseProps } from 'components/ProjectToggle';

import React from 'react';
import { useGetListMode } from 'redux/report/reportSlice';

const ProjectToggle = (props: ProjectToggleBaseProps) => {
  const listMode = useGetListMode();

  return <ProjectToggleComponent listMode={listMode} {...props} />;
};

export default ProjectToggle;
