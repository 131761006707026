import { Badge } from 'react-bootstrap';
import React from 'react';
import get from 'lodash/get';
import styles from './ExpandedSearchResult.module.scss';

const sections = [{ title: 'Description', key: 'report' }];

const ExpandedSearchResult = ({ ...row }) => {
  return (
    <div className={styles.expandedSearchResult}>
      {sections.map((section) => (
        <>
          <div className={styles.expandedSearchResultTitle}>{section.title}</div>
          <div className={styles.expandedSearchResultText}>{get(row, section.key, '')}</div>
        </>
      ))}
      <div className={styles.expandedSearchResultTitle}>Labels</div>
      <div className={styles.expandedSearchResultLabels}>
        {get(row, 'labels', [])?.map((label) => (
          <Badge bg="label" key={label.id} role="status">
            {label.name}
          </Badge>
        ))}
      </div>
    </div>
  );
};

export default ExpandedSearchResult;
