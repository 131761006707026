import { Organisation } from 'types/Report';
import React from 'react';
import SidebarSelect from 'components/Sidebar/SidebarSelect';

interface OrganisationSelectProps {
  organisations: Organisation[];
  onChange: (data: string) => any;
  organisation: number | undefined;
}

const OrganisationSelect = ({ organisations, organisation, onChange }: OrganisationSelectProps) => (
  <SidebarSelect
    id="organisation"
    label="Organisation"
    value={organisation}
    onChange={(event) => onChange(event.target.value)}>
    {organisations.map((organisation) => (
      <option key={organisation.id} value={organisation.id}>
        {organisation.name}
      </option>
    ))}
  </SidebarSelect>
);

export default OrganisationSelect;
