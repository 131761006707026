import * as t from 'io-ts/lib/index';

import { User } from './Report';

const IdeaCreateProps = t.type({
  title: t.string,
  desc: t.string,
  type: t.string,
  output: t.string,
  effort: t.string,
  archived: t.boolean,
});

const Idea = t.type({
  id: t.number,
  author: User,
  created: t.string,
  ...IdeaCreateProps.props,
});

const Ideas = t.type({
  count: t.number,
  next: t.union([t.string, t.null]),
  previous: t.union([t.string, t.null]),
  results: t.array(Idea),
});

const IdeaUpdate = t.type({
  id: t.number,
  data: IdeaCreateProps,
});

const IdeaCreate = IdeaCreateProps;

type Ideas = t.TypeOf<typeof Ideas>;
type Idea = t.TypeOf<typeof Idea>;
type IdeaCreate = t.TypeOf<typeof IdeaCreate>;
type IdeaUpdate = t.TypeOf<typeof IdeaUpdate>;

export interface IdeaFieldUpdate {
  id: number;
  fieldToUpdate: string;
  value: string;
}

export { Idea, Ideas, IdeaCreate, IdeaUpdate };
