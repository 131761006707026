import CustomForm from './CustomForm';
import React from 'react';

const ExportForm = ({ onClose, units, handleExport }) => {
  const sections = [
    {
      label: 'Comment level',
      name: 'commentLevel',
      type: 'select',
      getOptionValue: (unit) => unit.level,
      getOptionLabel: (unit) => unit.roleName,
      getOptions: () => units || [],
      renderEmptyOption: false,
    },
    // {
    //   label: 'Include history',
    //   name: 'includeHistory',
    //   type: 'checkbox',
    // },
    // {
    //   label: 'Only if status changed',
    //   name: 'onlyIfStatusChanged',
    //   type: 'checkbox',
    // },
    {
      label: 'Activities as table',
      name: 'activitiesAsTable',
      type: 'checkbox',
    },
    {
      label: 'Include comments',
      name: 'includeComments',
      type: 'checkbox',
    },
  ];

  const initialState = {
    commentLevel: units?.[0]?.level?.toString(),
    includeComments: true,
  };

  const handleSubmit = (data): Promise<void> => {
    handleExport(data);
    onClose();

    return Promise.resolve();
  };

  return (
    <CustomForm
      initialState={initialState}
      sections={sections}
      submitLabel="Submit"
      title="Export report"
      onCancel={onClose}
      onSubmit={handleSubmit}
    />
  );
};

export default ExportForm;
