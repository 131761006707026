import React, { PropsWithChildren, useEffect, useState } from 'react';

import { Spinner } from 'react-bootstrap';
import styles from './LoadingSelect.module.scss';

interface LoadingSelectProps {
  onLoad?: () => Promise<any>;
}

const LoadingSelect = ({ children, onLoad }: PropsWithChildren<LoadingSelectProps>) => {
  const [isLoading, setIsLoading] = useState<boolean>(true);

  useEffect(() => {
    if (onLoad) {
      onLoad().finally(() => {
        setIsLoading(false);
      });
    } else {
      setIsLoading(false);
    }
  }, []);

  return (
    <div className={styles.loadingSelectContainer}>
      {children}
      {isLoading && (
        <div className={styles.loadingSelectWrapper}>
          <Spinner animation="border" />
        </div>
      )}
    </div>
  );
};
export default LoadingSelect;
