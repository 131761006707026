import {
  removeEvent as removeEventAction,
  updateEvent as updateEventAction,
} from '../redux/report/reportActions';
import { useGetEvents, useGetIsReportLoading } from '../redux/report/reportSlice';

import { Event } from 'types/Report';
import { EventUpdate } from 'redux/report/types';
import { default as EventsPanelComponent } from '../components/EventsPanel';
import React from 'react';
import { UserPermission } from 'redux/organisation/types';
import { useCheckOneOfPermissions } from 'redux/organisation/organisationSlice';
import { useFetchData } from 'utils/fetchData';

const EventsPanel = ({ ...props }) => {
  const events: Event[] = useGetEvents();
  const isLoading = useGetIsReportLoading();

  const canEdit = useCheckOneOfPermissions(UserPermission.CHANGE_UPCOMING_EVENT);
  const canDelete = useCheckOneOfPermissions(UserPermission.DELETE_UPCOMING_EVENT);
  const canAdd = useCheckOneOfPermissions(UserPermission.ADD_UPCOMING_EVENT);

  const removeEvent = useFetchData<number>(removeEventAction, {
    model: 'Event',
    errorMessage: 'Failed to remove event',
    successMessage: 'Event has been removed successfully',
  });

  const updateEvent = useFetchData<EventUpdate>(updateEventAction, {
    model: 'Event',
    errorMessage: 'Failed to update event',
    successMessage: 'Event has been added successfully',
  });

  const handleUpdateEvent = (id: number, desc: string, startDate: string, endDate: string) =>
    updateEvent({ id, desc, startDate, endDate });

  return (
    <EventsPanelComponent
      {...props}
      canAdd={canAdd}
      events={events}
      isLoading={isLoading}
      removeEvent={canDelete ? removeEvent : undefined}
      updateEvent={canEdit ? handleUpdateEvent : undefined}
    />
  );
};

export default EventsPanel;
