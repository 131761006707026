import React from 'react';
import { RemarksPanel } from '../containers';
import ReportPage from 'pages/ReportPage';
import { UserPermission } from 'redux/organisation/types';
import { useCheckOneOfPermissions } from 'redux/organisation/organisationSlice';
import { useGetUsersFromReportCommentAndRemarks } from 'redux/report/reportSlice';

const ActionsPage = () => {
  const authors = useGetUsersFromReportCommentAndRemarks('action', 'actions');

  const canEdit = useCheckOneOfPermissions(UserPermission.CHANGE_ACTION);
  const canDelete = useCheckOneOfPermissions(UserPermission.DELETE_ACTION);
  const canAdd = useCheckOneOfPermissions(UserPermission.ADD_ACTION);

  return (
    <ReportPage authors={authors} hideOnlyActionsFilter showAuthorFilter>
      <RemarksPanel
        canAdd={canAdd}
        canDelete={canDelete}
        canEdit={canEdit}
        path="actions"
        tagType="action"
        title="Action request"
      />
    </ReportPage>
  );
};

export default ActionsPage;
