import { Card, Spinner } from 'react-bootstrap';
import React, { FC } from 'react';

import { Event } from 'types/Report';
import EventsTable from './EventsTable';
import { NewEventCreation } from '../../containers';
import styles from './EventsPanel.module.scss';

type Props = {
  events: Event[];
  removeEvent?: (id: number) => any;
  updateEvent?: (id: number, desc: string, startDate: string, endDate: string) => any;
  isLoading: boolean;
  canAdd: boolean;
};

const EventsPanel: FC<Props> = ({ events, removeEvent, updateEvent, isLoading, canAdd }) => (
  <Card className={styles.eventsPanel}>
    <Card.Body>
      {isLoading ? (
        <div className={styles.loadingWrapper} data-testid="event-spinner">
          <Spinner animation="border" />
        </div>
      ) : (
        <>
          {canAdd && <NewEventCreation canAdd={canAdd} />}
          <EventsTable events={events} removeEvent={removeEvent} updateEvent={updateEvent} />
        </>
      )}
    </Card.Body>
  </Card>
);

export default EventsPanel;
