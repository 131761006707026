import { DATE_FORMAT } from '../constants';
import { DEBUG } from '../redux/const';
import React from 'react';
import formatFns from 'date-fns/format';
import { isValid } from 'date-fns';

export interface DateFormatterPropsBase {
  date: string | number | Date;
}

interface DateFormatterProps extends DateFormatterPropsBase {
  format: string;
}

const DateFormatter = ({ format, date }: DateFormatterProps) => {
  const readyDate = date && new Date(date);
  if (!date || !isValid(readyDate) || !(readyDate instanceof Date)) {
    return <>-</>;
  }

  try {
    return <>{formatFns(readyDate, format)}</>;
  } catch {
    if (DEBUG) {
      console.warn('Wrong date format:', format);
    }

    return <>{formatFns(readyDate, DATE_FORMAT)}</>;
  }
};

export default DateFormatter;
