import { useAppDispatch } from 'redux/hooks';
import { useNotification } from './useNotification';

const ERROR_ABORT_NAME = 'AbortError';
const REQUEST_STATUS_FULFILLED = 'fulfilled';
const REQUEST_STATUS_REJECTED = 'rejected';

interface ResponseError {
  status: number;
  data: {
    message: string;
  };
}

interface dispatchHandlerArgs<T, E> {
  onSuccess?: (data: T) => void;
  onFail?: (data: E) => void;
  onFinish?: () => void;
  onAbort?: () => void;
}

export const dispatchHandler = <T, E>(
  func: Promise<any>,
  { onSuccess, onFail, onFinish, onAbort }: dispatchHandlerArgs<T, E>,
): Promise<any> => {
  return func.then((data) => {
    if (data.meta.requestStatus === REQUEST_STATUS_FULFILLED) {
      onSuccess?.(data.payload);
    } else if (data.meta.requestStatus === REQUEST_STATUS_REJECTED) {
      if (data.error.name === ERROR_ABORT_NAME) {
        onAbort?.();
      } else {
        onFail?.(data.payload);
      }
    }
    onFinish?.();

    return Promise.resolve(data);
  });
};

export interface UseFetchDataOptions {
  model: string;
  errorMessage?: string;
  successMessage?: string;
  onFinish?: () => void;
  onSuccess?: () => void;
}

export const useFetchData = <T>(func: any, options: UseFetchDataOptions): ((arg?: T) => any) => {
  const notify = useNotification();
  const dispatch = useAppDispatch();

  return (args?: T): any => {
    const dispatcher = dispatch(func(args));

    dispatchHandler<T, ResponseError>(dispatcher, {
      onSuccess: () => {
        if (options?.successMessage) {
          notify.success(options.successMessage);
        }
      },
      onFail: (error) => {
        notify.error(
          error?.data?.message || options?.errorMessage || 'Cannot retrieve the data!',
          options.model,
        );
      },
      onFinish: options?.onFinish,
    });

    return dispatcher;
  };
};

export const useFetchDataWithResult = <T>(
  func: any,
  options: UseFetchDataOptions,
): ((arg?: T) => Promise<any>) => {
  const notify = useNotification();
  const dispatch = useAppDispatch();

  return (args?: T): any => {
    const dispatcher = dispatch(func(args));

    return new Promise<any>((resolve, reject) => {
      dispatchHandler<T, ResponseError>(dispatcher, {
        onSuccess: (data) => {
          if (options?.successMessage) {
            notify.success(options.successMessage);
          }
          options?.onSuccess?.();
          resolve(data);
        },
        onFail: (error) => {
          notify.error(
            error?.data?.message || options?.errorMessage || 'Cannot retrieve the data!',
            options.model,
          );
          reject(error?.data);
        },
        onFinish: options?.onFinish,
      });
    });
  };
};
