import { ProjectListBaseProps, default as ProjectListComponent } from '../components/ProjectList';
import { useGetCurrentReport, useGetProjects } from '../redux/report/reportSlice';

import React from 'react';

const ProjectList = ({ ...props }: ProjectListBaseProps) => {
  const report = useGetCurrentReport();
  const projects = useGetProjects();

  return <ProjectListComponent {...props} projects={projects} report={report} />;
};

export default ProjectList;
