import { Badge } from 'react-bootstrap';
import ButtonsCell from 'components/Table/ButtonsCell';
import DateFormatter from 'containers/DateFormatter';
import { Event } from 'types/Report';
import React from 'react';
import { Row } from 'react-table';
import { StatefulCustomDatePicker } from 'components/CustomDatePicker/StatefulCustomDatePicker';
import { StatefulTextarea } from 'components/StatefulTextarea';
import { isAfter } from 'date-fns';
import { isLessThanDay } from 'utils/timeUtils';
import styles from './EventsTable.module.scss';

export default ({
  removeEvent,
  editing,
  handleCancel,
  handleEdit,
  handleSubmit,
  setDesc,
  desc,
  rangeDate,
  setRangeDate,
}) => [
  {
    Header: 'Date',
    accessor: 'created',
    className: styles.eventsTableDate,
    collapse: true,
    Cell: ({ value }) => {
      const date = new Date(value);

      return <DateFormatter date={date} formatType="date" />;
    },
  },
  {
    Header: 'Name',
    accessor: 'author',
    className: styles.eventsTableName,
    collapse: true,
    Cell: ({ value: { firstName, lastName } }) => `${firstName} ${lastName}`,
  },
  {
    Header: 'Event Date',
    accessor: 'date',
    Cell: ({
      row: {
        original: { id, startDate, endDate },
      },
    }) => {
      if (editing === id) {
        return (
          <div className={styles.eventsTableRangeDate}>
            <StatefulCustomDatePicker
              format="dateTime"
              placeholderText="Event date"
              portalId="dateSearch"
              selected={new Date(rangeDate[0])}
              timeInputLabel="Time:"
              showConfirmBtn
              showTimeInput
              onChange={(value) => {
                setRangeDate([value, new Date(rangeDate[1])]);
              }}
            />
            <StatefulCustomDatePicker
              format="dateTime"
              placeholderText="Event date"
              portalId="dateSearch"
              selected={new Date(rangeDate[1])}
              timeInputLabel="Time:"
              showConfirmBtn
              showTimeInput
              onChange={(value) => {
                setRangeDate([new Date(rangeDate[0]), value]);
              }}
            />
          </div>
        );
      }

      return (
        <>
          <DateFormatter date={startDate} formatType="dateTime" />
          {' - '}
          <DateFormatter date={endDate} formatType="dateTime" />
        </>
      );
    },
    sortType: (rowA: Row<Event>, rowB: Row<Event>): number => {
      const startDateA = new Date(rowA.original.startDate).getTime();
      const startDateB = new Date(rowB.original.startDate).getTime();
      if (startDateA > startDateB) {
        return 1;
      }
      if (startDateA < startDateB) {
        return -1;
      }

      return 0;
    },
  },
  {
    Header: 'Upcoming Events',
    accessor: 'desc',
    Cell: ({
      row: {
        original: { id, desc: originalDesc },
      },
    }) => {
      if (editing === id) {
        return (
          <StatefulTextarea
            desc={desc}
            maxLength={200}
            setDesc={setDesc}
            testId="eventDescriptionInput"
          />
        );
      }

      const content = originalDesc?.split('\n');
      if (content?.length > 1) {
        return `${content[0]}...`;
      }

      return <div className={styles.eventDesc}>{originalDesc}</div>;
    },
  },
  {
    Header: 'tags',
    collapse: true,
    center: true,
    Cell: ({
      row: {
        original: { created },
      },
    }) => {
      return isLessThanDay(created) && <Badge>NEW</Badge>;
    },
  },
  {
    Header: 'Actions',
    collapse: true,
    center: true,
    Cell: ({
      row: {
        original: { id, startDate, endDate, desc },
      },
    }) => {
      if (isAfter(new Date(), new Date(endDate))) {
        return <></>;
      }

      return (
        <ButtonsCell
          editMode={editing === id}
          id="event"
          onCancel={handleCancel}
          onEdit={handleEdit ? () => handleEdit(id, startDate, endDate, desc) : undefined}
          onRemove={removeEvent ? () => removeEvent(id) : undefined}
          onSubmit={handleSubmit}
        />
      );
    },
  },
];
