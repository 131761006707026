/* eslint-disable react/jsx-max-depth */
/* eslint-disable max-statements */

import type { Area, Project as ProjectType, Report, SubArea } from '../types/Report';
import React, { FC } from 'react';

import Project from '../containers/Project';
import { useGetSelectedProject } from 'utils/useGetSelectedProject';

export interface ProjectListBaseProps {
  area: Area;
  subArea: SubArea;
}

interface Props extends ProjectListBaseProps {
  report: Report | null;
  projects: ProjectType[] | null;
}

const ProjectList: FC<Props> = ({ area, subArea, projects, report }) => {
  const selectedProject = useGetSelectedProject(projects)?.id;

  return (
    <div>
      {projects
        ?.filter((project) => {
          return project.area.id === area.id && project.subarea?.id === subArea?.id;
        })
        .map((project) => (
          <Project
            isSelectedProject={selectedProject === project.id}
            key={project.id}
            project={project}
            report={report!}
          />
        ))}
    </div>
  );
};

export default ProjectList;
