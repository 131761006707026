import React, { useMemo } from 'react';
import {
  useGetActis,
  useGetCostCodes,
  useGetProjects,
  useGetSprint,
  useGetYear,
} from 'redux/report/reportSlice';
import { useGetActisApi, useGetCostCodeApi } from 'utils/hooks/report';

import ActivityFormPage from 'pages/ActivityFormPage';
import { ProjectUpdate } from 'redux/report/types';
import { updateProjectSettings as updateProjectSettingsAction } from 'redux/report/reportActions';
import { useFetchData } from 'utils/fetchData';
import { useGetOrganisationAreaApi } from 'utils/hooks/organisation';
import { useGetOrganisationAreas } from 'redux/organisation/organisationSlice';

interface Props {
  id: number | null;
  onClose: () => void;
}

const EditActivityPage = ({ id, onClose }: Props) => {
  const year = useGetYear()!;
  const sprint = useGetSprint()!;
  const areas = useGetOrganisationAreas();
  const projects = useGetProjects();
  const actis = useGetActis();
  const costCodes = useGetCostCodes();
  const getCostCode = useGetCostCodeApi();
  const getActis = useGetActisApi();
  const getAreas = useGetOrganisationAreaApi();

  const projectId = id!;

  const project = useMemo(() => {
    const foundProject = projects.find((project) => project.id === projectId);
    if (foundProject) {
      return {
        ...foundProject,
        area: foundProject.area.id,
        subarea: foundProject.subarea.id,
        actis: foundProject.actis?.id,
        costCode: foundProject.costCode?.id,
      };
    }
  }, [projectId, projects]);

  const updateProjectSettings = useFetchData<ProjectUpdate>(updateProjectSettingsAction, { model: 'Activity',
    errorMessage: 'Failed to update activity',
    successMessage: 'Activity has been updated successfully',
    onFinish: onClose,
  });

  return (
    <ActivityFormPage
      actis={actis}
      areas={areas}
      costCodes={costCodes}
      getActis={getActis}
      getAreas={getAreas}
      getCostCodes={getCostCode}
      initialState={project}
      title={`Edit Activity - ${project?.title}`}
      onCancel={() => onClose()}
      onSubmit={(data) => updateProjectSettings({ ...data, year, sprint, id: projectId })}
    />
  );
};

export default EditActivityPage;
