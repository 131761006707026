import React from 'react';
import RedirectToPage from 'components/RedirectToPage';

interface StartPageProps {
  pageLabel?: string;
}

const StartPage = ({ pageLabel = 'login' }: StartPageProps) => (
  <RedirectToPage pageLabel={pageLabel} />
);

export default StartPage;
