import { History, Unit } from 'types/ActivityHistory';
import { getActivity, getActivityHistory, getActivityUnits } from './activityHistoryActions';

import { Project } from 'types/Report';
import { SimpleReduxState } from 'redux/types';
import { createSlice } from '@reduxjs/toolkit';
import { useAppSelector } from 'redux/hooks';

export interface ActivityHistoryState {
  units: SimpleReduxState<Unit[]>;
  history: SimpleReduxState<History[]>;
  activity: SimpleReduxState<Project | null>;
}

const initialState: ActivityHistoryState = {
  history: {
    data: [],
    isLoading: false,
    hasError: false,
  },
  units: {
    data: [],
    isLoading: false,
    hasError: false,
  },
  activity: {
    data: null,
    isLoading: false,
    hasError: false,
  },
};

export const activityHistorySlice = createSlice({
  name: 'activityHistory',
  initialState,
  reducers: {},
  extraReducers: (builder) => {
    builder.addCase(getActivity.fulfilled, ({ activity: state }, action) => {
      state.data = action.payload;
      state.isLoading = false;
      state.hasError = false;
    });
    builder.addCase(getActivity.rejected, ({ activity: state }) => {
      state.isLoading = false;
      state.hasError = true;
    });
    builder.addCase(getActivity.pending, ({ activity: state }) => {
      state.isLoading = true;
      state.hasError = false;
    });

    builder.addCase(getActivityHistory.fulfilled, ({ history: state }, action) => {
      state.data = action.payload;
      state.isLoading = false;
      state.hasError = false;
    });
    builder.addCase(getActivityHistory.rejected, ({ history: state }) => {
      state.isLoading = false;
      state.hasError = true;
    });
    builder.addCase(getActivityHistory.pending, ({ history: state }) => {
      state.isLoading = true;
      state.hasError = false;
    });

    builder.addCase(getActivityUnits.fulfilled, ({ units: state }, action) => {
      state.data = action.payload;
      state.isLoading = false;
      state.hasError = false;
    });
    builder.addCase(getActivityUnits.rejected, ({ units: state }) => {
      state.isLoading = false;
      state.hasError = true;
    });
    builder.addCase(getActivityUnits.pending, ({ units: state }) => {
      state.isLoading = true;
      state.hasError = false;
    });
  },
});

export const useGetActivityHistoryUnits = (): Unit[] =>
  useAppSelector((state) => state.activityHistory.units.data);
export const useIsActivityHistoryUnitsLoading = (): boolean =>
  useAppSelector((state) => state.activityHistory.units.isLoading);

export const useGetHistory = (): History[] =>
  useAppSelector((state) => state.activityHistory.history.data);
export const useIsHistoryLoading = (): boolean =>
  useAppSelector((state) => state.activityHistory.history.isLoading);

export const useGetActivity = (): Project | null =>
  useAppSelector((state) => state.activityHistory.activity.data);
export const useIsActivityLoading = (): boolean =>
  useAppSelector((state) => state.activityHistory.activity.isLoading);

export const activityHistoryReducer = activityHistorySlice.reducer;
