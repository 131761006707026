import React from 'react';
import get from 'lodash/get';
import styles from './ExpandedEvent.module.scss';

const sections = [{ title: 'Description', key: 'desc' }];

const ExpandedEvent = ({ ...row }) => {
  return (
    <div className={styles.expandedEvent}>
      {sections.map((section) => (
        <>
          <div className={styles.expandedEventTitle}>{section.title}</div>
          <div className={styles.expandedEventText}>{get(row, section.key, '')}</div>
        </>
      ))}
    </div>
  );
};

export default ExpandedEvent;
