import * as Yup from 'yup';

import { Controller, useForm } from 'react-hook-form';

import { CustomDatePicker } from '../CustomDatePicker/CustomDatePicker';
import React from 'react';
import WideInput from '../WideInput';
import clsx from 'clsx';
import { eventFormSchema } from '../../schemas/EventForm';
import styles from './NewEventCreation.module.scss';
import { yupResolver } from '@hookform/resolvers/yup';

export interface EventCreationFormData {
  desc: string;
  startDate: Date;
  endDate: Date;
}

interface Props {
  createEvent: (desc: string, startDate: Date, endDate: Date) => any;
  editable: boolean;
}

const NewEventCreation = ({ editable, createEvent }: Props) => {
  const { handleSubmit, control, reset, setError, getFieldState } = useForm({
    resolver: yupResolver<Yup.SchemaOf<EventCreationFormData>>(eventFormSchema),
  });

  const mapKey: Map<string, string> = new Map<string, string>();
  mapKey.set('desc', 'desc');
  mapKey.set('startDate', 'startDate');
  mapKey.set('endDate', 'endDate');

  const handleSend = (desc, startDate, endDate) => {
    createEvent(desc, startDate, endDate)
      .then(() => {
        reset({ desc: '', startDate: null, endDate: null });
      })
      .catch((errors) => {
        Object.keys(errors)
          .filter((key) => mapKey.has(key))
          .forEach((key) => {
            const newKey = mapKey.get(key)!;
            setError(newKey, { type: 'custom', message: errors[key]?.[0]?.message });
          });
      });
  };

  return (
    <div className={styles.newEventCreation}>
      <Controller
        control={control}
        name="startDate"
        render={({ field: { value, onChange }, fieldState: { invalid, error } }) => (
          <CustomDatePicker
            className={clsx(styles.newEventCreationDateSelector, invalid && 'is-invalid')}
            disabled={!editable}
            error={error?.message}
            format="dateTime"
            placeholderText="Start event date"
            portalId="dateSearch"
            selected={value}
            timeInputLabel="Time:"
            wrapperClassName={styles.newEventCreationDateSelectorWrapper}
            showConfirmBtn
            showTimeInput
            onChange={onChange}
          />
        )}
      />
      <Controller
        control={control}
        name="endDate"
        render={({ field: { value, onChange }, fieldState: { invalid, error } }) => (
          <CustomDatePicker
            className={clsx(styles.newEventCreationDateSelector, invalid && 'is-invalid')}
            disabled={!editable}
            error={error?.message}
            format="dateTime"
            placeholderText="End event date"
            portalId="dateSearch"
            selected={value}
            timeInputLabel="Time:"
            wrapperClassName={styles.newEventCreationDateSelectorWrapper}
            showConfirmBtn
            showTimeInput
            onChange={onChange}
          />
        )}
      />

      <WideInput
        className={styles.newEventCreationInput}
        control={control}
        disabled={!editable}
        error={getFieldState('desc')?.error?.message}
        maxLength={200}
        name={'desc'}
        placeholder={'Upcoming events'}
        onSubmit={handleSubmit(({ desc, startDate, endDate }) => {
          handleSend(desc, startDate, endDate);
        })}
      />
    </div>
  );
};

export default NewEventCreation;
