import React, { useMemo } from 'react';

import { default as NewLockedPeriodCreationComponent } from '../components/AdminPanel/NewLockedPeriodCreation';
import { useCreateLockedPeriodApi } from 'utils/hooks/organisation';
import { useGetCurrentOrganisationUnits } from 'redux/organisation/organisationSlice';
import { useGetEditable } from '../redux/report/reportSlice';

const NewLockedPeriodCreation = () => {
  const editable = useGetEditable();

  const createLockedPeriod = useCreateLockedPeriodApi();
  const sections = useGetCurrentOrganisationUnits().filter((unit) => unit.reportingLevel);

  const unitOptions = useMemo(
    () => sections?.map((section) => ({ label: `${section.name}`, value: section.id })),
    [sections],
  );

  return (
    <NewLockedPeriodCreationComponent
      create={(desc: string, startDate: Date, endDate: Date, unit: number) =>
        createLockedPeriod({ desc, startDate, endDate, unit })
      }
      editable={editable}
      options={unitOptions}
    />
  );
};

export default NewLockedPeriodCreation;
