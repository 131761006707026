import { useState } from 'react';

export const useIsFirstLoaded = () => {
  const [isFirstReportLoading, setIsFirstReportLoading] = useState<boolean>(true);

  const setData = (value: boolean) => {
    setIsFirstReportLoading(value);
  };

  return [isFirstReportLoading, setData] as const;
};
