import { Badge } from 'react-bootstrap';
import DateFormatter from 'containers/DateFormatter';
import React from 'react';
import Status from 'components/Status';
import styles from './SearchTable.module.scss';

const typeToString = new Proxy(
  {
    ['action']: 'Action',
    ['decision']: 'Decision',
    ['need_to_know']: 'Need to know',
  },
  {
    get: (handler, type: string) => handler[type] || type || '',
  },
);

export default () => [
  {
    Header: 'Date',
    accessor: 'date',
    Cell: ({ value }) => {
      const date = new Date(value);

      return <DateFormatter date={date} formatType="date" />;
    },
  },
  { Header: 'Name', accessor: 'name' },
  {
    Header: 'Type',
    accessor: 'type',
    Cell: ({ value }) => typeToString[value],
  },
  { Header: 'Area/Subarea', accessor: 'area' },
  {
    Header: 'Status',
    accessor: 'status',
    className: styles.searchTableStatus,
    collapse: true,
    Cell: ({ value }) => (value ? <Status level={value} /> : ''),
  },
  { Header: 'Entry type', accessor: 'role' },
  { Header: 'Title', accessor: 'title' },
  {
    Header: 'Description',
    accessor: 'report',
    Cell: ({ value }) => <div className={styles.searchTableReportCell}>{value ?? ''}</div>,
  },
  {
    Header: 'Label',
    accessor: 'labels',
    collapse: true,
    center: true,
    Cell: ({ value }) => {
      if (!value || value.length === 0) {
        return '';
      }
      const firstLabel = value[0];

      return <Badge bg="label">{firstLabel.name}</Badge>;
    },
  },
  {
    Header: 'Tag',
    accessor: 'tag',
    collapse: true,
    center: true,
    Cell: ({ value }) =>
      value ? (
        <div className={styles.searchTableTagsWrapper}>
          {value?.action && <Badge bg="action">Action request</Badge>}
          {value?.decision && <Badge bg="decision">Decision request</Badge>}
          {value?.needToKnow && <Badge bg="needToKnow">Need to know</Badge>}
        </div>
      ) : (
        ''
      ),
  },
];
