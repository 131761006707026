import DateFormatterComponent, { DateFormatterPropsBase } from '../components/DateFormatter';
import { Format, createDateFormat } from '../utils/createDateFormat';

import React from 'react';
import { useGetUserConfig } from 'redux/userConfig/userConfigSlice';

export interface DateFormatterContainerProps extends DateFormatterPropsBase {
  formatType?: Format;
}

const DateFormatter = ({ formatType, date }: DateFormatterContainerProps) => {
  const { dateFormat, hourFormat } = useGetUserConfig();

  return (
    <DateFormatterComponent
      date={date}
      format={createDateFormat({ formatType, dateFormat, hourFormat })}
    />
  );
};

export default React.memo(DateFormatter);
