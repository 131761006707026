import { CurrentUser } from '../../types/Session';
import { createSlice } from '@reduxjs/toolkit';
import { fetchNewToken } from './sessionActions';
import { useAppSelector } from '../hooks';

export interface SessionState {
  token: { accessToken: string; refreshToken: string } | null;
  locationBeforeLogin: string | null;
  isLoadingRefresh: boolean | null;
  user: CurrentUser | null;
}

const initialState: SessionState = {
  token: null,
  locationBeforeLogin: null,
  isLoadingRefresh: null,
  user: null,
};

export const sessionSlice = createSlice({
  name: 'session',
  initialState,
  reducers: {
    setToken: (state, action) => {
      state.token = action.payload;
    },
    setLocationBeforeLogin: (state, action) => {
      state.locationBeforeLogin = action.payload;
    },
    setUserData: (state, action) => {
      const { firstName, lastName, userId: id, email, units } = action.payload;
      state.user = { firstName, lastName, id, email };
    },
    logout: (state) => {
      state.token = null;
      state.user = null;
    },
  },
  extraReducers: (builder) => {
    builder.addCase(fetchNewToken.fulfilled, (state, action) => {
      if (state.token) {
        state.token.accessToken = action.payload.access;
      }
      state.isLoadingRefresh = false;
    });
    builder.addCase(fetchNewToken.pending, (state) => {
      state.isLoadingRefresh = true;
    });
    builder.addCase(fetchNewToken.rejected, (state) => {
      state.isLoadingRefresh = false;
    });
  },
});

export const useGetToken = () => useAppSelector((state) => state.session.token);
export const useGetLocationBeforeLogin = () =>
  useAppSelector((state) => state.session.locationBeforeLogin);
export const useGetUser = () => useAppSelector((state) => state.session.user);

export const { setToken, setLocationBeforeLogin, setUserData, logout } = sessionSlice.actions;

export const sessionReducer = sessionSlice.reducer;
