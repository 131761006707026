import CustomSelect from 'components/CustomSelect';
import { Form } from 'react-bootstrap';
import React from 'react';
import type { Props as ReactSelectProps } from 'react-select';
import { components } from 'react-select';
import styles from './MultiSelect.module.scss';

export interface MultiSelectOption<T = number> {
  label: string;
  value?: T;
  options?: MultiSelectOption<T>[];
}

interface MultiSelectProps<T> extends ReactSelectProps {
  options: MultiSelectOption<T>[];
}

const MultiSelect = <T,>({ options, ...props }: MultiSelectProps<T>) => {
  return (
    <CustomSelect
      className={'react-select'}
      classNamePrefix={'react-select'}
      closeMenuOnSelect={false}
      components={{
        Option: (props) => (
          <div>
            {' '}
            <components.Option {...props}>
              <Form.Check
                checked={props.isSelected}
                className={styles.selectCheckbox}
                type="checkbox"
                inline
                onChange={() => null}
              />{' '}
              <label className={styles.selectLabel}>{props.label}</label>{' '}
            </components.Option>{' '}
          </div>
        ),
      }}
      hideSelectedOptions={false}
      options={options}
      isMulti
      {...props}
    />
  );
};

export default MultiSelect;
