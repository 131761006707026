import IdeaForm from './IdeasPage/IdeaForm';
import React from 'react';

interface IdeasFormPageProps {
  title: string;
  onCancel: () => void;
  onSubmit: (formData: any) => void;
  initialState?: any;
}

const IdeasFormPage = ({ title, onCancel, onSubmit, initialState }: IdeasFormPageProps) => {
  return (
    <IdeaForm initialState={initialState} title={title} onCancel={onCancel} onSubmit={onSubmit} />
  );
};

export default IdeasFormPage;
