import type { Props } from 'react-select';
import React from 'react';
import Select from 'react-select';
import clsx from 'clsx';

interface CustomSelectProps extends Props {
  invalid?: boolean;
  customStyles?: any;
  textColor?: string;
  primaryColor?: string;
  secondaryColor?: string;
}

const CustomSelect = ({
  invalid = false,
  customStyles = null,
  textColor = '#003348',
  primaryColor = '#7eede2',
  secondaryColor = '#7eede2',
  ...props
}: CustomSelectProps) => {
  return (
    <Select
      className={clsx(customStyles, invalid && 'is-invalid')}
      theme={(theme) => ({
        ...theme,
        borderRadius: 10,
        colors: {
          ...theme.colors,
          text: textColor,
          primary: primaryColor,
          primary25: secondaryColor,
          primary50: secondaryColor,
          primary75: secondaryColor,
        },
      })}
      {...props}
    />
  );
};

export default CustomSelect;
