import * as yup from 'yup';

import { IdeaFormData } from 'pages/IdeasPage/IdeaForm';

export const ideaFormSchema: yup.SchemaOf<IdeaFormData> = yup
  .object({
    title: yup
      .string()
      .required('Title is required')
      .max(100, '100 character limit reached')
      .default(''),
    type: yup.string().required().default(''),
    desc: yup
      .string()
      .required('Description is required')
      .max(200, '200 character limit reached')
      .default(''),
    output: yup.string().max(200, '200 character limit reached').default(''),
    effort: yup.string().required().default(''),
  })
  .required();
