import { Accordion, Modal, Tab, Tabs } from 'react-bootstrap';
import { Comment, Comments, Project, Unit } from 'types/Report';
import React, { useEffect, useMemo, useState } from 'react';
import { shouldActivityBeVisible, shouldCommentBeVisible } from 'utils/reportHelpers';

import CommentComponent from 'containers/Comment';
import CreateComment from 'containers/CreateComment';
import { CurrentUser } from 'types/Session';
import Edit from 'assets/icons/edit.svg';
import EditActivityPage from 'containers/EditActivityPage';
import { Filters } from 'redux/report/types';
import ProjectToggle from 'containers/ProjectToggle';
import StatusIcon from 'components/StatusIcon';
import { activityHistoryUrl } from 'pages/consts';
import clsx from 'clsx';
import { styles as projectsPanelStyles } from 'components/AreasPanel';
import { stringToStatus } from 'utils/status';
import styles from './Project.module.scss';
import { useHistory } from 'react-router-dom';

export interface ProjectBaseProps {
  project: Project;
  isSelectedProject?: boolean;
}

interface Props extends ProjectBaseProps {
  commentsUnit: Comments;
  filters: Filters;
  isLocked?: boolean;
  editable: boolean;
  units: Unit[];
  user: CurrentUser | null;
  archiveProject?: () => void;
  copyPreviousComment?: () => void;
  deleteActivity?: () => void;
  initUnit: number;
  canEdit: boolean;
}

const commentsOrder = [
  stringToStatus['gray'],
  stringToStatus['green'],
  stringToStatus['yellow'],
  stringToStatus['red'],
];

const ProjectComponent = ({
  units = [] as Unit[],
  project,
  commentsUnit,
  initUnit,
  filters,
  isLocked,
  editable,
  isSelectedProject,
  archiveProject,
  copyPreviousComment,
  deleteActivity,
  user,
  canEdit,
}: Props) => {
  const [editActivity, setEditActivity] = useState<number | null>(null);
  const history = useHistory();

  const [currentSection, setCurrentSection] = useState(initUnit);

  const flatComments = useMemo(
    () => commentsUnit?.flatMap((commentSection) => commentSection.comments),
    [commentsUnit],
  );

  const hideActivity = useMemo(() => {
    return !shouldActivityBeVisible(project, flatComments, filters, user?.id);
  }, [project, flatComments, filters]);

  const commentsLength = useMemo(
    () => commentsUnit.reduce((acc, commentUnit) => commentUnit.comments.length + acc, 0),
    [commentsUnit],
  );

  const currUserUnit = commentsUnit[0]?.id;
  const hasNextUserUnit = units.length > 1;

  const unitRelation = units.reduce((prev, cur) => {
    return {
      ...prev,
      [cur.id]: units.filter((unit) => unit.parent === cur.id).map((unit) => unit.id),
    };
  }, {});

  const commentsByUnit = useMemo(
    () =>
      commentsUnit.reduce<Record<string, Comment[]>>(
        (acc, commentUnit) => ({ ...acc, [commentUnit.id]: commentUnit.comments }),
        {},
      ),
    [commentsUnit],
  );

  const getComments = (unitId: number, checkingFirstComments = false): Comment[] => {
    if (commentsByUnit[unitId]?.length > 0 && checkingFirstComments) {
      return commentsByUnit[unitId];
    }

    return unitRelation[unitId]?.flatMap((id) => getComments(id, true)) ?? [];
  };

  const hasUserCommented = commentsByUnit[currUserUnit]?.some(
    (comment) => comment.author.id === user?.id,
  );

  const squashComments =
    getComments(currUserUnit)
      .map((comment) => comment.content)
      .join('\n__________\n') ?? '';

  const pastReportSquashComment: Comment | null =
    hasUserCommented || !isLocked || !squashComments
      ? null
      : ({
          author: user,
          content: squashComments,
          created: '',
          modified: '',
          id: 0,
          activity: 0,
          status: '1',
          previous: null,
          tags: null,
          unit: currUserUnit,
          labels: [],
        } as Comment);

  const highestStatus = useMemo(
    () =>
      flatComments.sort(
        (commentA, commentB) =>
          commentsOrder.indexOf(parseInt(commentB.status, 10)) -
          commentsOrder.indexOf(parseInt(commentA.status, 10)),
      )[0]?.status || stringToStatus['gray'],
    [commentsUnit],
  );

  useEffect(() => {
    if (currentSection === undefined) {
      setCurrentSection(units?.[0]?.id);
    }
  }, [units]);

  const commentAddedByCurrentUser = useMemo(
    () => flatComments.find((comment) => comment.author.id === user?.id),
    [user?.id, flatComments],
  );

  if (hideActivity) {
    return null;
  }

  return (
    <>
      <Accordion>
        <ProjectToggle
          afterBadge={
            <>
              <div className={styles.projectCostCode}>
                {project.costCode && project.costCode.name}
              </div>
              <div className={clsx([styles.projectHeaderButtons, 'd-flex', 'gap-3'])}>
                {editable && !isLocked && !commentAddedByCurrentUser && copyPreviousComment && (
                  <button
                    className={clsx(['clear-button', styles.projectIconButton])}
                    title="Copy my comment from the previous sprint to the current activity"
                    onClick={(event) => {
                      event.stopPropagation();
                      copyPreviousComment();
                    }}>
                    <i className="bi bi-clipboard2-plus"></i>
                  </button>
                )}
                {!isLocked && canEdit && (
                  <button
                    className={'clear-button'}
                    title="Edit"
                    onClick={(event) => {
                      event.stopPropagation();
                      setEditActivity(project.id);
                    }}>
                    <img alt="edit" className="bigIcon" src={Edit} />
                  </button>
                )}
                <button
                  className={clsx(['clear-button', styles.projectIconButton])}
                  title="History of the activity"
                  onClick={(event) => {
                    event.stopPropagation();
                    history.push(activityHistoryUrl(project.id));
                  }}>
                  <i className="bi bi-hourglass" />
                </button>
                {!isLocked && archiveProject && (
                  <button
                    className={clsx(['clear-button', styles.projectIconButton])}
                    title="Archive"
                    onClick={(event) => {
                      event.stopPropagation();
                      archiveProject();
                    }}>
                    <i className="bi bi-archive" />
                  </button>
                )}
                {!isLocked && deleteActivity && (
                  <button
                    className={clsx(['clear-button', styles.projectIconButton])}
                    data-testid="activity-delete"
                    title="Delete"
                    onClick={(event) => {
                      event.stopPropagation();
                      deleteActivity();
                    }}>
                    <i className="bi bi-trash" />
                  </button>
                )}
              </div>
            </>
          }
          badgeCount={commentsLength}
          className="d-flex align-items-center"
          data-testid="project-level"
          eventKey="2"
          initialOpen={isSelectedProject}>
          <StatusIcon className={styles.projectStatus} level={highestStatus} />
          {project.title}
        </ProjectToggle>
        <Accordion.Collapse
          aria-label="project"
          className={projectsPanelStyles.levelMargin}
          eventKey="2"
          role="treeitem">
          <div className={styles.commentsWrapper}>
            <Tabs
              activeKey={currentSection}
              className={styles.projectTabs}
              id={'tab' + project.area.id + project.title}
              onSelect={(section) => section && setCurrentSection(parseInt(section, 10))}>
              {units?.map((unit) => (
                <Tab eventKey={unit.id} key={unit.id} title={unit.roleName}>
                  <>
                    {commentsByUnit[unit.id]
                      ?.filter((comment) => shouldCommentBeVisible(comment, filters, user?.id))
                      .map((comment) => (
                        <CommentComponent comment={comment} isLocked={isLocked} key={comment.id} />
                      ))}
                    {pastReportSquashComment && unit.id === currUserUnit && (
                      <CommentComponent comment={pastReportSquashComment} isLocked simpleView />
                    )}
                  </>
                </Tab>
              ))}
            </Tabs>
            {!!units?.length &&
              !isLocked &&
              !commentAddedByCurrentUser &&
              currentSection === currUserUnit &&
              commentsByUnit[currentSection] &&
              !filters.onlyMyActivities && (
                <CreateComment
                  initText={squashComments}
                  limitText={!hasNextUserUnit}
                  projectId={project.id}
                  uniqueId={`${project.id}-new`}
                  unit={currentSection}
                />
              )}
          </div>
        </Accordion.Collapse>
      </Accordion>

      <Modal show={!!editActivity} size="lg" centered onHide={() => setEditActivity(null)}>
        <EditActivityPage id={editActivity} onClose={() => setEditActivity(null)} />
      </Modal>
    </>
  );
};

export default ProjectComponent;
