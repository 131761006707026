import * as yup from 'yup';

import { HourFormat } from 'utils/createDateFormat';

export const dateFormatOptions = ['dd/MM/yyyy', 'yyyy/MM/dd'];
export const hourFormatOptions: HourFormat[] = ['12h', '24h'];
export const mailFrequencyOptions = [
  { value: 1, name: 'Never' },
  { value: 2, name: 'Daily' },
  { value: 3, name: 'Weekly' },
];

export const userSettingsFormSchema = yup
  .object({
    hourFormat: yup
      .string()
      .required('Hour format is required')
      .test(
        'hourFormat',
        'Hour format is of wrong type',
        (value) => !!(value && hourFormatOptions.includes(value as HourFormat)),
      ),
    dateFormat: yup
      .string()
      .required('Date format is required')
      .test(
        'dateFormat',
        'Date format is of wrong type',
        (value) => !!(value && dateFormatOptions.includes(value)),
      ),
    frequencyIdeasSummaryMail: yup.string().required('You have to select frequency of idea emails'),
  })
  .required();
