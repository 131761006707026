import { ActivityComment, History, Report, Unit } from 'types/ActivityHistory';
import React, { useMemo } from 'react';

import { Badge } from 'react-bootstrap';
import { Column } from 'react-table';
import DateFormatter from 'containers/DateFormatter';
import ExpandedActivityHistory from './ExpandedActivityHistory';
import Status from 'components/Status';
import Table from 'components/Table';
import clsx from 'clsx';
import styles from './ActivityHistoryTable.module.scss';

interface ActivityHistoryRow extends ActivityComment {
  showWeek: boolean;
  report: Report;
}

interface ActivityHistoryTableProps {
  data: History[];
  units: Unit[];
}

const ActivityHistoryTable = ({ data, units }: ActivityHistoryTableProps) => {
  const columns = useMemo<Column<ActivityHistoryRow>[]>(
    () => [
      {
        Header: 'Week',
        accessor: 'report',
        className: (cell) =>
          cell.row.original.showWeek
            ? clsx(styles.tableCellWeek, styles.weekCell)
            : styles.tableCellWeekSpacer,
        Cell: (data) => {
          if (data.row.original.showWeek) {
            return <div className={styles.week}>{`${data.value.sprint}/${data.value.year}`}</div>;
          }

          return null;
        },
        disableSortBy: true,
      },
      {
        Header: 'Date',
        accessor: 'created',
        className: (cell) => cell.row.original.showWeek && styles.tableCellWeek,
        Cell: ({ value }) => <DateFormatter date={value} formatType="date" />,
      },
      {
        Header: 'Name',
        accessor: 'author',
        className: (cell) => cell.row.original.showWeek && styles.tableCellWeek,
        Cell: ({ value }) => `${value?.firstName} ${value?.lastName}`,
        disableSortBy: true,
      },
      {
        Header: 'Tag',
        accessor: 'tags',
        className: (cell) => cell.row.original.showWeek && styles.tableCellWeek,
        Cell: ({ value }) => (
          <div className={styles.tagsWrapper}>
            {value?.action && <Badge bg="action">Action request</Badge>}
            {value?.decision && <Badge bg="decision">Decision request</Badge>}
            {value?.needToKnow && <Badge bg="needToKnow">Need to know</Badge>}
          </div>
        ),
        disableSortBy: true,
      },
      {
        Header: 'Status',
        accessor: 'status',
        className: (cell) => cell.row.original.showWeek && styles.tableCellWeek,
        Cell: ({ value }) => <Status level={value.toString()} />,
        disableSortBy: true,
      },
      {
        Header: 'Unit',
        accessor: 'unit',
        className: (cell) => cell.row.original.showWeek && styles.tableCellWeek,
        Cell: ({ value }) => units?.find(({ id }) => value === id)?.name || '',
        disableSortBy: true,
      },
      {
        Header: 'Comment',
        accessor: 'content',
        className: (cell) =>
          clsx([styles.fitCell, cell.row.original.showWeek && styles.tableCellWeek]),
        disableSortBy: true,
        Cell: ({ value }) => (value.length > 50 ? `${value?.slice(0, 50)}...` : value),
      },
    ],
    [],
  );

  return (
    <Table
      columns={columns}
      data={data.flatMap((el) =>
        el.activityComments.map((activityComments, index) => ({
          showWeek: index === el.activityComments.length - 1,
          report: el.report,
          ...activityComments,
        })),
      )}
      RowSubComponent={ExpandedActivityHistory}
      tableClassName={styles.mainTable}
      expandable
    />
  );
};

export default ActivityHistoryTable;
