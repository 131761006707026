import React, { useMemo, useRef, useState } from 'react';
import { formatRangeDate, isBetweenDates, isLessThanDay } from 'utils/timeUtils';

import { Event } from 'types/Report';
import ExpandedEvent from './components/ExpandedEvent';
import { OUT_EVENT_DATE_SHORT_FORMAT } from '../../constants';
import Table from '../Table';
import createColumns from './createColumns';
import formatFns from 'date-fns/format';
import { useGetFilters } from '../../redux/report/reportSlice';
import { useGetUser } from '../../redux/session/sessionSlice';

type Props = {
  events: Event[];
  removeEvent?: (id: number) => any;
  updateEvent?: (id: number, desc: string, startDate: string, endDate: string) => any;
};

const EventsTable = ({ events, removeEvent, updateEvent }: Props) => {
  const [editing, setEditing] = useState<number | null>(null);
  const rangeDateRef = useRef<(Date | null)[]>([null, null]);
  const descRef = useRef<string>('');

  const setDesc = (text) => {
    descRef.current = text;
  };

  const setRangeDate = (value) => {
    rangeDateRef.current = value;
  };

  const filters = useGetFilters();
  const user = useGetUser();

  const data = useMemo(() => {
    let filteredEvents = events;

    if (filters.onlyMyActivities) {
      filteredEvents = filteredEvents.filter((event) => event?.author?.id === user?.id);
    }

    if (filters.onlyRecentUpdates) {
      filteredEvents = filteredEvents.filter((event) => isLessThanDay(event?.created));
    }

    if (filters?.author?.length > 0) {
      filteredEvents = filteredEvents.filter((event) => filters.author.includes(event?.author?.id));
    }

    if (filters?.dates?.startDate && filters?.dates?.endDate) {
      filteredEvents = filteredEvents.filter(
        (event) =>
          isBetweenDates(
            new Date(event.startDate),
            filters.dates.startDate,
            filters.dates.endDate,
          ) ||
          isBetweenDates(new Date(event.endDate), filters.dates.startDate, filters.dates.endDate),
      );
    }

    const searchLower = filters?.search?.toLowerCase();

    if (searchLower) {
      filteredEvents = filteredEvents.filter((event) =>
        event?.desc?.toLowerCase().includes(searchLower),
      );
    }

    return filteredEvents;
  }, [filters, events]);

  const handleCancel = () => {
    setRangeDate([null, null]);
    setDesc(null);
    setEditing(null);
  };

  const handleEdit = (id, startDate, endDate, desc) => {
    setRangeDate([new Date(startDate), new Date(endDate)]);
    setDesc(desc);
    setEditing(id);
  };

  const handleSubmit = () => {
    if (editing && rangeDateRef.current[0] && rangeDateRef.current[1] && descRef.current) {
      updateEvent?.(
        editing,
        descRef.current,
        formatFns(rangeDateRef.current[0], OUT_EVENT_DATE_SHORT_FORMAT),
        formatFns(rangeDateRef.current[1], OUT_EVENT_DATE_SHORT_FORMAT),
      );
      setRangeDate([null, null]);
      setDesc(null);
      setEditing(null);
    }
  };

  const columns = createColumns({
    removeEvent,
    editing,
    handleCancel,
    handleEdit: updateEvent ? handleEdit : undefined,
    handleSubmit,
    setDesc,
    desc: descRef.current,
    rangeDate: rangeDateRef.current,
    setRangeDate,
  });

  return (
    <Table
      columns={columns}
      data={data.map((item) => ({
        ...item,
        date: formatRangeDate(item.startDate, item.endDate) || null,
      }))}
      RowSubComponent={ExpandedEvent}
      expandable
    />
  );
};

export default EventsTable;
