import type { AxiosError, AxiosInstance } from 'axios';

import { API_URL } from './../redux/const';
import extractToken from '../utils/extractToken';
import fromUnixTime from 'date-fns/fromUnixTime';
import isAfter from 'date-fns/isAfter';
import isValid from 'date-fns/isValid';
import { logout } from '../redux/session/sessionSlice';
import store from '../redux/store';
export default (axiosInstance: AxiosInstance) => {
  axiosInstance.interceptors.request.use((config) => {
    const isMainApiCall = config.baseURL?.startsWith(API_URL as string);
    if (isMainApiCall) {
      const refreshToken = store.getState?.()?.session?.token?.refreshToken;
      const exp = extractToken(refreshToken)?.expirationDate;
      const expirationDate = exp && fromUnixTime(exp);
      const isExpired =
        expirationDate && isValid(expirationDate) && isAfter(new Date(), expirationDate);
      if (isExpired) {
        store.dispatch(logout());

        return Promise.reject({ error: 'Outdated token' });
      }
    }

    return config;
  });

  axiosInstance.interceptors.response.use(
    (response) => response,
    (error: AxiosError) => {
      if (error.response?.status === 401) {
        store.dispatch(logout());
      }

      return Promise.reject(error);
    },
  );
};
