import { UserPermission } from '../redux/organisation/types';

export const checkAllPermissions = (permissions: string[], check: UserPermission[]): boolean => {
  if (permissions.length === 0 || check.length === 0) {
    return false;
  }

  return check.every((permission) => permissions.includes(permission));
};

export const checkOneOfPermissions = (permissions: string[], check: UserPermission[]): boolean => {
  if (!permissions || !check) {
    return false;
  }

  return check.some((permission) => permissions.includes(permission));
};
