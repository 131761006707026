import React from 'react';
import get from 'lodash/get';
import styles from './ExpandedRemark.module.scss';

const sections = [{ title: 'Description', key: 'desc' }];

const ExpandedRemark = ({ ...row }) => {
  return (
    <div className={styles.expandedRemark}>
      {sections.map((section) => (
        <>
          <div className={styles.expandedRemarkTitle}>{section.title}</div>
          <div className={styles.expandedRemarkText}>{get(row, section.key, '')}</div>
        </>
      ))}
    </div>
  );
};

export default ExpandedRemark;
