import {
  changeListMode,
  setAuthorFilter,
  setSearchDateFilter,
  setSearchFilter,
  setVisibilityFilter,
  toggleOnlyActions,
  toggleOnlyMyActivities,
  toggleOnlyRecentUpdates,
  useGetCurrentReport,
  useGetEditable,
  useGetFilters,
  useGetListMode,
  useGetPrevious,
  useGetSprint,
  useGetYear,
} from '../redux/report/reportSlice';
import {
  getNextReport as getNextReportAction,
  getPreviousReport as getPreviousReportAction,
} from 'redux/report/reportActions';

import { default as MenuBarComponent } from '../components/MenuBar';
import React from 'react';
import { UserPermission } from 'redux/organisation/types';
import { getUsersOptions } from 'utils/reportHelpers';
import { useAppDispatch } from '../redux/hooks';
import { useCheckOneOfPermissions } from 'redux/organisation/organisationSlice';
import { useFetchData } from 'utils/fetchData';

const MenuBar = ({ authors, ...props }) => {
  const filters = useGetFilters();
  const year = useGetYear();
  const sprint = useGetSprint();
  const dispatch = useAppDispatch();
  const listMode = useGetListMode();
  const report = useGetCurrentReport();
  const previous = useGetPrevious();
  const userOptions = getUsersOptions(authors);

  const canCreateNewActivity = useCheckOneOfPermissions(UserPermission.ADD_ACTIVITY);
  const editable = useGetEditable();

  const getNextReport = useFetchData(getNextReportAction, { model: 'Report' });
  const getPreviousReport = useFetchData(getPreviousReportAction, { model: 'Report' });

  return (
    <MenuBarComponent
      {...props}
      canCreateNewActivity={canCreateNewActivity && editable && !report?.locked}
      changeListMode={(mode) => dispatch(changeListMode(mode))}
      filters={filters}
      hasPrevious={!!previous}
      listMode={listMode}
      setAuthorFilter={(search) => dispatch(setAuthorFilter(search))}
      setNextSprint={getNextReport}
      setPrevSprint={getPreviousReport}
      setSearchDateFilter={(dates) => dispatch(setSearchDateFilter(dates))}
      setSearchFilter={(search) => dispatch(setSearchFilter(search))}
      setVisibilityFilter={(eventKey) => dispatch(setVisibilityFilter(eventKey))}
      sprint={sprint}
      spritStatus={report?.status ?? ''}
      toggleOnlyActions={() => dispatch(toggleOnlyActions())}
      toggleOnlyMyActivities={() => dispatch(toggleOnlyMyActivities())}
      toggleOnlyRecentUpdates={() => dispatch(toggleOnlyRecentUpdates())}
      userOptions={userOptions}
      year={year}
    />
  );
};

export default MenuBar;
