import DateFormatter from 'containers/DateFormatter';
import { EditIcon } from '../../assets/icons';
import { Idea } from 'types/Ideas';
import React from 'react';
import styles from './IdeasPage.module.scss';

interface IdeaTableProps {
  archiveIdea?: (id: number) => void;
  onIdeaEdit?: (idea: Idea) => void;
  removeIdea?: (id: number) => void;
}

export default ({ archiveIdea, onIdeaEdit, removeIdea }: IdeaTableProps) => [
  {
    Header: 'Date',
    accessor: 'created',
    Cell: ({ value }) => {
      const date = new Date(value);

      return <DateFormatter date={date} formatType="date" />;
    },
  },
  {
    Header: 'Name',
    accessor: 'author',
    Cell: ({ value: { firstName, lastName } }) => `${firstName} ${lastName}`,
  },
  { Header: 'Report', accessor: 'title' },
  { Header: 'Type', accessor: 'type' },
  {
    Header: 'Edit',
    collapse: true,
    center: true,
    Cell: ({ row: { original } }) => {
      return (
        <div className={styles.ideasPageTableButtons}>
          {onIdeaEdit && (
            <button onClick={() => onIdeaEdit(original)}>
              <EditIcon className="bigIcon" title="Edit" />
            </button>
          )}
          {archiveIdea && (
            <button onClick={() => archiveIdea(original.id)}>
              <i className="bi bi-archive" title="Archive" />
            </button>
          )}
          {removeIdea && (
            <button onClick={() => removeIdea(original.id)}>
              <i className="bi bi-trash" title="Delete" />
            </button>
          )}
        </div>
      );
    },
  },
];
