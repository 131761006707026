import { API_URL } from './const';
import attachOrganisation from 'interceptors/attachOrganisation';
import attachToken from '../interceptors/attachToken';
import axios from 'axios';
import camelization from '../interceptors/camelization';
import refreshToken from '../interceptors/refreshToken';
import unauthorized from '../interceptors/unauthorized';
export const baseURL = `${API_URL}/api/v1/`;

const axiosInstance = axios.create({
  baseURL,
  headers: { 'Content-Type': 'application/json' },
});

attachOrganisation(axiosInstance);
unauthorized(axiosInstance);
attachToken(axiosInstance);
refreshToken(axiosInstance);
camelization(axiosInstance);

export default axiosInstance;
