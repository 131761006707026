import { Filters, FiltersState } from 'redux/activityHistory/types';
import React, { useState } from 'react';
import {
  useActivityHistoryApi,
  useActivityHistoryUnitsApi,
  useGetActivityApi,
} from 'utils/hooks/activityHistory';
import {
  useGetActivity,
  useGetActivityHistoryUnits,
  useGetHistory,
  useIsActivityHistoryUnitsLoading,
  useIsActivityLoading,
  useIsHistoryLoading,
} from 'redux/activityHistory/activityHistorySlice';

import { default as ActivityHistoryPageComponent } from '../pages/ActivityHistoryPage';
import { subMonths } from 'date-fns';

export interface StandardObject {
  label: string;
  id: string;
}

const statuses = [
  { label: 'In line with schedule', id: 'green' },
  {
    label: 'Potential impact on schedule and costs / issues experienced',
    id: 'yellow',
  },
  {
    label: 'Confirmed / highly probable impact on schedule and costs',
    id: 'red',
  },
  {
    label: 'No status set',
    id: 'gray',
  },
];

const actions = [
  { label: 'Action request', id: 'action' },
  {
    label: 'Need to know',
    id: 'needToKnow',
  },
  {
    label: 'Decision request',
    id: 'decision',
  },
];

const ActivityHistoryPage = () => {
  const activity = useGetActivity();
  const isActivityLoading = useIsActivityLoading();
  const [filters, setFilters] = useState<FiltersState>({
    search: '',
    date: {
      startDate: subMonths(new Date(), 1)?.toISOString(),
      endDate: new Date()?.toISOString(),
    },
    authors: [],
    status: [],
    tags: [],
    units: [],
  });

  const units = useGetActivityHistoryUnits();
  const isActivityHistoryUnitsLoading = useIsActivityHistoryUnitsLoading();

  const history = useGetHistory();
  const isHistoryLoading = useIsHistoryLoading();

  const getActivity = useGetActivityApi();
  const getActivityHistory = useActivityHistoryApi();
  const getActivityUnits = useActivityHistoryUnitsApi();

  const handleSetFilters = (newFilters: Filters): void => {
    setFilters({
      ...newFilters,
      date: {
        startDate: newFilters.date.startDate?.toISOString() ?? null,
        endDate: newFilters.date.endDate?.toISOString() ?? null,
      },
    });
  };

  return (
    <ActivityHistoryPageComponent
      actions={actions}
      activity={activity}
      filters={filters}
      getActivity={getActivity}
      getActivityHistory={getActivityHistory}
      getActivityUnits={getActivityUnits}
      history={history}
      isActivityLoading={isActivityLoading || isActivityHistoryUnitsLoading}
      isDataLoading={isHistoryLoading}
      setFilters={handleSetFilters}
      statuses={statuses}
      units={units}
    />
  );
};

export default ActivityHistoryPage;
