import { Accordion, Card, Spinner } from 'react-bootstrap';
import { Area, Report } from 'types/Report';
import React, { useMemo } from 'react';

import { AreaVisibilityData } from 'utils/reportHelpers';
import ProjectToggle from 'containers/ProjectToggle';
import SubAreaPanel from 'containers/SubAreaPanel';
import clsx from 'clsx';
import { styles } from './';
import { useGetSelectedProject } from 'utils/useGetSelectedProject';

const checkAreaPriority = (item) =>
  item.toLowerCase().includes('workshop') || item.toLowerCase().includes('conference') ? 1 : 0;

export interface ProjectsPanelProps {
  areas: Area[];
  report: Report;
  isLoading: boolean;
  showMenu: boolean;
  visibilityData: AreaVisibilityData;
  copyPreviousComments: () => void;
}

const AreasPanel = ({
  areas,
  report,
  showMenu,
  isLoading,
  visibilityData,
  copyPreviousComments,
}: ProjectsPanelProps) => {
  const selectedArea = useGetSelectedProject(report?.activities || [])?.area?.id;

  const sortedAreas = useMemo(() => {
    return [...areas].sort((a, b) => {
      const aPriority = checkAreaPriority(a.name);
      const bPriority = checkAreaPriority(b.name);

      return aPriority === bPriority ? a.name.localeCompare(b.name) : bPriority - aPriority;
    });
  }, [areas]);

  const hasPrevious = report.previous && !report.previous.copiedAt;

  const renderAreas = () =>
    sortedAreas?.length ? (
      sortedAreas
        .filter((area) => visibilityData.visibleAreas.includes(area.id))
        .map((area) => (
          <Accordion key={area.id}>
            <ProjectToggle
              badgeCount={
                area.subareas?.filter((area) => visibilityData.visibleSubareas.includes(area.id))
                  .length
              }
              data-testid="area-level"
              eventKey="0"
              initialOpen={selectedArea === area.id}>
              {area.name}
            </ProjectToggle>
            <Accordion.Collapse
              aria-label="area"
              className={styles.levelMargin}
              eventKey="0"
              role="treeitem">
              <div>
                {area.subareas
                  ?.filter((subArea) => visibilityData.visibleSubareas.includes(subArea.id))
                  .map((subArea) => (
                    <SubAreaPanel area={area} key={subArea.id} subArea={subArea} />
                  ))}
              </div>
            </Accordion.Collapse>
          </Accordion>
        ))
    ) : (
      <Card>
        <Card.Body>No defined areas.</Card.Body>
      </Card>
    );

  return (
    <main className={clsx(['shadow-sm', styles.container])}>
      {isLoading ? (
        <div className={styles.loadingWrapper}>
          <Spinner animation="border" />
        </div>
      ) : (
        <>
          {showMenu && hasPrevious && (
            <div className={styles.reportMenu}>
              <button
                className={clsx(['clear-button', styles.reportMenuIconButton])}
                title="Copy all my comments from the previous sprint to all activities"
                onClick={(event) => {
                  event.stopPropagation();
                  copyPreviousComments();
                }}>
                <i className="bi bi-clipboard2-plus"></i>
              </button>
            </div>
          )}
          {renderAreas()}
        </>
      )}
    </main>
  );
};

export default AreasPanel;
