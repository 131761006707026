import { HourFormat, IdeaEmailFrequency } from 'utils/createDateFormat';
import React, { ElementType } from 'react';
import {
  dateFormatOptions,
  hourFormatOptions,
  mailFrequencyOptions,
  userSettingsFormSchema,
} from 'schemas/UserSettingsForm';

import CustomForm from 'components/CustomForm';

const sections = [
  {
    label: 'Date format',
    name: 'dateFormat',
    type: 'select' as ElementType<any>,
    required: true,
    getOptionValue: (option) => option.value,
    getOptionLabel: (option) => option.name,
    options: [
      {
        value: dateFormatOptions[0],
        name: 'day/month/year',
      },
      {
        value: dateFormatOptions[1],
        name: 'year/month/day',
      },
    ],
    column: 1,
  },
  {
    label: 'Hour format',
    name: 'hourFormat',
    type: 'select' as ElementType<any>,
    required: true,
    getOptionValue: (option) => option.value,
    getOptionLabel: (option) => option.name,
    options: [
      { value: hourFormatOptions[0], name: '12h AM/PM' },
      { value: hourFormatOptions[1], name: '24h' },
    ],
    column: 2,
  },
  {
    label: 'Idea email frequency',
    name: 'frequencyIdeasSummaryMail',
    type: 'select' as ElementType<any>,
    required: true,
    getOptionValue: (option) => option.value,
    getOptionLabel: (option) => option.name,
    options: mailFrequencyOptions,
    column: 1,
  },
];

interface UserSettingsProps {
  onSubmit: (data: any) => Promise<void>;
  onCancel: () => void;
  userName: string;
  hourFormat: HourFormat;
  dateFormat: string | null;
  frequencyIdeasSummaryMail: IdeaEmailFrequency;
}

const UserSettings = ({
  onSubmit,
  onCancel,
  userName,
  hourFormat,
  dateFormat,
  frequencyIdeasSummaryMail,
}: UserSettingsProps) => {
  return (
    <CustomForm
      columns={2}
      initialState={{ hourFormat, dateFormat, frequencyIdeasSummaryMail }}
      schema={userSettingsFormSchema}
      sections={sections}
      title={`${userName} Settings`}
      onCancel={onCancel}
      onSubmit={onSubmit}
    />
  );
};

export default UserSettings;
