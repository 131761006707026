export default () => {
  const typeSection = {
    title: 'Type',
    name: 'type',
    options: [
      { label: 'Activities', name: 'activities' },
      { label: 'Decision request', name: 'decisions' },
      { label: 'Action request', name: 'actions' },
      { label: 'Upcoming events', name: 'upcoming_events' },
      { label: 'Need to know', name: 'need_to_knows' },
      { label: 'Comments', name: 'comments' },
    ],
  };

  const labelSection = {
    title: 'Tag',
    name: 'tag',
    options: [
      { label: 'Decision request', name: 'decision' },
      { label: 'Action request', name: 'action' },
      { label: 'Need to know', name: 'need_to_know' },
    ],
  };

  const statusSection = {
    title: 'Status',
    name: 'status',
    options: [
      { label: 'In line with schedule', name: '2' },
      { label: 'Potential impact on schedule and costs / issues experienced', name: '3' },
      { label: 'Confirmed/highly probable impact on schedule and costs', name: '4' },
      { label: 'No status set', name: '1' },
    ],
  };

  return [typeSection, labelSection, statusSection];
};
