import AdminPanel from 'containers/AdminPanel';
import React from 'react';

const AdminPage = () => {
  return (
    <div className="standard-container-wrapper">
      <AdminPanel />
    </div>
  );
};

export default AdminPage;
