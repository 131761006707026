import React from 'react';
import { UserConfig } from 'types/userConfig';
import UserSettingsComponent from 'pages/UserSettings';
import { updateConfig as updateConfigAction } from 'redux/userConfig/userConfigActions';
import { useFetchData } from 'utils/fetchData';
import { useGetUser } from 'redux/session/sessionSlice';
import { useGetUserConfig } from 'redux/userConfig/userConfigSlice';

const UserSettings = ({ onClose }) => {
  const user = useGetUser();
  const { dateFormat, hourFormat, frequencyIdeasSummaryMail } = useGetUserConfig();

  const updateConfig = useFetchData<UserConfig>(updateConfigAction, {
    model: 'Config',
    errorMessage: 'Failed to update config',
    successMessage: 'Config has been updated successfully',
    onFinish: onClose,
  });

  return (
    <UserSettingsComponent
      dateFormat={dateFormat}
      frequencyIdeasSummaryMail={frequencyIdeasSummaryMail}
      hourFormat={hourFormat}
      userName={`${user?.firstName ?? ''} ${user?.lastName ?? ''}`}
      onCancel={onClose}
      onSubmit={updateConfig}
    />
  );
};

export default UserSettings;
