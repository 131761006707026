import { ProjectBaseProps, default as ProjectComponent } from 'components/Project';
import { Project as ProjectType, Report } from 'types/Report';
import {
  useActivityArchiveApi,
  useCommentCopyPreviousApi,
  useDeleteActivityApi,
} from 'utils/hooks/report';
import { useGetComments, useGetEditable, useGetFilters } from '../redux/report/reportSlice';

import React from 'react';
import { UserPermission } from 'redux/organisation/types';
import { useCheckOneOfPermissions } from 'redux/organisation/organisationSlice';
import { useGetSelectedUnitId } from 'utils/useGetSelectedUnitId';
import { useGetUser } from 'redux/session/sessionSlice';

interface ProjectProps extends ProjectBaseProps {
  report: Report;
  project: ProjectType;
}

const Project = ({ report, project, ...props }: ProjectProps) => {
  const filters = useGetFilters();
  const commentsUnit = useGetComments({
    year: report.year,
    sprint: report.sprint,
    projectId: project.id,
  });
  const user = useGetUser();
  const selectedUnit = useGetSelectedUnitId(report.units);
  const editable = useGetEditable();

  const archiveActivity = useActivityArchiveApi();

  const deleteActivity = useDeleteActivityApi();

  const copyPreviousComment = useCommentCopyPreviousApi();

  const canCopyComment = useCheckOneOfPermissions(UserPermission.ADD_COMMENT);
  const canDeleteActivity = useCheckOneOfPermissions(UserPermission.DELETE_ACTIVITY);
  const canArchiveActivity = useCheckOneOfPermissions(UserPermission.ARCHIVE_ACTIVITY);
  const canEditActivity = useCheckOneOfPermissions(UserPermission.CHANGE_ACTIVITY);

  return (
    <ProjectComponent
      {...props}
      archiveProject={canArchiveActivity ? () => archiveActivity({ id: project.id }) : undefined}
      canEdit={canEditActivity}
      commentsUnit={commentsUnit}
      copyPreviousComment={
        canCopyComment ? () => copyPreviousComment({ id: project.id }) : undefined
      }
      deleteActivity={
        canDeleteActivity
          ? () => deleteActivity({ id: project.id, year: report.year, sprint: report.sprint })
          : undefined
      }
      editable={editable}
      filters={filters}
      initUnit={selectedUnit}
      isLocked={report.locked || !editable}
      project={project}
      units={report.units}
      user={user}
    />
  );
};

export default Project;
