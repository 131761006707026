import { activityArchived, activityUnarchived } from 'redux/report/reportActions';
import { createSlice, isAnyOf } from '@reduxjs/toolkit';
import { getArchivedProjects, unarchiveProject } from './archivedProjectsActions';

import { Project } from '../../types/Report';
import { useAppSelector } from '../hooks';

export interface ReportState {
  projects: Project[];
  isLoading: boolean;
}

const initialState: ReportState = {
  projects: [],
  isLoading: false,
};

export const archivedProjectsSlice = createSlice({
  name: 'report',
  initialState,
  reducers: {
    addArchivedProject(state, action) {
      state.projects.push(action.payload);
    },
  },
  extraReducers: (builder) => {
    builder.addCase(getArchivedProjects.fulfilled, (state, action) => {
      state.projects = action.payload;
      state.isLoading = false;
    });
    builder.addCase(getArchivedProjects.pending, (state) => {
      state.isLoading = true;
    });
    builder.addCase(getArchivedProjects.rejected, (state) => {
      state.isLoading = false;
    });
    builder.addCase(unarchiveProject.fulfilled, (state, action) => {
      const id = action.meta.arg;
      state.projects = state.projects.filter((project) => project.id !== id);
    });

    builder.addMatcher(isAnyOf(activityUnarchived), (state, action) => {
      const id = action.payload.id;
      state.projects = state.projects.filter((project) => project.id !== id);
    });

    builder.addMatcher(isAnyOf(activityArchived), (state, action) => {
      state.projects.push(action.payload);
    });
  },
});

export const useGetArchivedProjects = () =>
  useAppSelector((state) => state.archivedProjects.projects);
export const { addArchivedProject } = archivedProjectsSlice.actions;

export const archivedProjectsReducer = archivedProjectsSlice.reducer;
