import * as yup from 'yup';

import { LockedPeriodFormData } from 'components/AdminPanel/NewLockedPeriodCreation';

export const lockedPeriodFormSchema: yup.SchemaOf<LockedPeriodFormData> = yup
  .object({
    desc: yup.string().required().max(200),
    dateRange: yup.object({
      start: yup.mixed().required(),
      end: yup.mixed().required(),
    }),
    unit: yup.number().required(),
  })
  .required();
