import { Report } from '../../types/Report';
import sortBy from 'lodash/sortBy';

export const sortProjects = (_report: Report | null): Report | null => {
  const report = _report;

  if (!report) {
    return null;
  }

  return {
    ...report,
    activities: sortBy(report.activities, ['area.name', 'subarea.name', 'title']),
  };
};

export const reduceReport = (action: {
  payload: Report | null;
  meta: { arg: { sprint: number; year: number } };
}): {
  report: Report | null;
  sprint: number | null;
  year: number | null;
} => {
  const report = sortProjects(action.payload);

  const sprint = report?.sprint || action.meta.arg.sprint || null;
  const year = report?.year || action.meta.arg.year || null;

  return { report, sprint, year };
};

export const projectStatusToNumber = new Proxy(
  {
    green: '1',
    yellow: '2',
    red: '3',
    grey: '4',
  },
  { get: (handler, type) => handler[type] || 4 },
);

export const remarkPathToEndpoint = new Proxy(
  {
    actions: 'actions',
    decisions: 'decisions',
    needToKnows: 'needtoknows',
  },
  { get: (handler, type) => handler[type] || null },
);

export const remarkTypeToPath = new Proxy(
  {
    action: 'actions',
    decision: 'decisions',
    need_to_know: 'needToKnows',
  },
  {
    get: (handler, type) => handler[type] || null,
  },
);

export const sectionToNumberMap = {
  comments: '1',
  commentsHsec: '2',
  commentsHdiv: '3',
};

export const numberToSectionMap = {
  1: 'comments',
  2: 'commentsHsec',
  3: 'commentsHdiv',
};
