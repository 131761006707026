import React, { useEffect, useMemo, useState } from 'react';
import { filterIdeas, getTypesOption, getUserOptions } from 'utils/ideaHelpers';

import { Idea } from 'types/Ideas';
import IdeasSearch from 'containers/IdeasSearch';
import IdeasTable from 'components/IdeasTable';
import { MultiSelectOption } from 'components/MultiSelect/MultiSelect';
import createColumns from './createColumns';
import styles from './ArchivedIdeasPage.module.scss';

interface Props {
  getArchivedIdeas: () => any;
  unarchiveIdea: (id: number) => any;
  archivedIdeas: Array<Idea>;
  canDelete: boolean;
  canUnarchive: boolean;
  canViewIdea: boolean;
  removeIdea: (id: number) => any;
}

const ArchivedIdeasPage = ({
  getArchivedIdeas,
  unarchiveIdea,
  archivedIdeas,
  removeIdea,
  canDelete,
  canUnarchive,
  canViewIdea,
}: Props) => {
  const [dates, setDates] = useState<{ startDate: Date | null; endDate: Date | null }>({
    startDate: null,
    endDate: null,
  });
  const [selectedUsers, setSelectedUsers] = useState<MultiSelectOption[]>([]);
  const [selectedTypes, setSelectedTypes] = useState<MultiSelectOption[]>([]);
  const [search, setSearch] = useState<string>('');

  useEffect(() => {
    if (canViewIdea) {
      const promise = getArchivedIdeas();

      return () => promise.abort();
    }
  }, []);

  const userOptions = useMemo(() => getUserOptions(archivedIdeas), [archivedIdeas]);
  const columns = useMemo(
    () =>
      createColumns({
        unarchiveIdea: canUnarchive ? unarchiveIdea : undefined,
        removeIdea: canDelete ? removeIdea : undefined,
      }),
    [],
  );
  const data = useMemo(
    () => filterIdeas(archivedIdeas, { search, dates, selectedUsers, selectedTypes }),
    [archivedIdeas, search, selectedUsers, dates, selectedTypes],
  );

  const typeOptions = useMemo(() => getTypesOption(), []);

  return (
    <div className={styles.archivedIdeasPage}>
      <div className={styles.archivedIdeasPageMenu}>
        {canViewIdea && (
          <IdeasSearch
            dates={dates}
            selectedTypes={selectedTypes}
            selectedUsers={selectedUsers}
            setDates={setDates}
            setSearchText={setSearch}
            setSelectedTypes={setSelectedTypes}
            setSelectedUsers={setSelectedUsers}
            typeOptions={typeOptions}
            userOptions={userOptions}
          />
        )}
      </div>
      <IdeasTable columns={columns} ideas={canViewIdea ? data : []} />
    </div>
  );
};

export default ArchivedIdeasPage;
