import React from 'react';
import get from 'lodash/get';
import styles from './ExpandedActivityHistory.module.scss';

const sections = [{ title: 'Comment', key: 'content' }];

const ExpandedActivityHistory = ({ ...row }) => {
  return (
    <div className={styles.expandedActivityHistory}>
      {sections.map((section) => (
        <>
          <div className={styles.expandedActivityHistoryTitle}>{section.title}</div>
          <div className={styles.expandedActivityHistoryText}>{get(row, section.key, '')}</div>
        </>
      ))}
    </div>
  );
};

export default ExpandedActivityHistory;
