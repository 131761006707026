import React from 'react';
import esaLogo from '../../assets/esaLogo.png';
import styles from './RedirectToPage.module.scss';

interface RedirectToPageProps {
  pageLabel: string;
}

const RedirectToPage = ({ pageLabel }: RedirectToPageProps) => {
  return (
    <div className={styles.redirectToPage}>
      <img alt="logo" height="25" src={esaLogo} />
      {pageLabel && <h3>Welcome! You are being redirected to {pageLabel} page</h3>}
    </div>
  );
};

export default RedirectToPage;
