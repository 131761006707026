import * as t from 'io-ts/lib/index';

const SubArea = t.type({
  id: t.number,
  name: t.string,
});

const Area = t.intersection([
  t.type({
    id: t.number,
    name: t.string,
  }),
  t.partial({
    subareas: t.array(SubArea),
  }),
]);

const Areas = t.array(Area);

const Actis = t.type({
  actisId: t.string,
  id: t.number,
  procurementTitle: t.string,
  status: t.string,
});

const ActisMilestone = t.type({
  actis: t.number,
  baseline: t.union([t.string, t.null]),
  bestEstimate: t.union([t.string, t.null]),
  completionDate: t.union([t.string, t.null]),
  contractualDate: t.union([t.string, t.null]),
  id: t.number,
  paymentDate: t.union([t.string, t.null]),
  plannedAmount: t.number,
  tOAchieved: t.union([t.string, t.null]),
  title: t.union([t.string, t.null]),
  updatedOn: t.union([t.string, t.null]),
});

const FullActis = t.type({
  actisId: t.string,
  brActual: t.union([t.string, t.null]),
  brBaseline: t.union([t.string, t.null]),
  brPlanned: t.union([t.string, t.null]),
  budgetResponsibleUnit: t.union([t.string, t.null]),
  closedActual: t.union([t.string, t.null]),
  commimentActual: t.union([t.string, t.null]),
  commitmentBaseline: t.union([t.string, t.null]),
  commitmentCeiling: t.union([t.string, t.null]),
  commitmentPlanned: t.union([t.string, t.null]),
  fund: t.union([t.string, t.null]),
  id: t.number,
  ittActual: t.union([t.string, t.null]),
  kickOffActual: t.union([t.string, t.null]),
  kickOffPlanned: t.union([t.string, t.null]),
  milestoneactisSet: t.array(ActisMilestone),
  obligation: t.union([t.string, t.null]),
  procurementTitle: t.string,
  programme: t.union([t.string, t.null]),
  status: t.string,
  supplierName: t.union([t.string, t.null]),
  tO: t.union([t.string, t.null]),
  tobActual: t.union([t.string, t.null]),
  wbs: t.union([t.string, t.null]),
});

const FullActisList = t.array(FullActis);

const CostCode = t.type({
  id: t.number,
  name: t.string,
});

const CostCodeList = t.array(CostCode);

const Unit = t.intersection([
  t.type({
    id: t.number,
    name: t.string,
    roleName: t.string,
    level: t.number,
    reportingLevel: t.boolean,
  }),
  t.partial({
    parent: t.union([t.number, t.null]),
  }),
]);

const ReportingUnit = t.intersection([
  Unit,
  t.type({
    permissions: t.array(t.string),
  }),
]);

const FullOrganisation = t.type({
  id: t.number,
  name: t.string,
  reportingUnits: t.array(ReportingUnit),
});

const Organisation = t.intersection([
  t.type({
    id: t.number,
    name: t.string,
  }),
  t.partial({
    people: t.array(t.number),
  }),
]);

const LockedPeriod = t.type({
  id: t.number,
  unit: Unit,
  desc: t.string,
  startDate: t.string,
  endDate: t.string,
});

const LockedPeriods = t.array(LockedPeriod);

const Tags = t.type({
  action: t.boolean,
  decision: t.boolean,
  needToKnow: t.boolean,
});

const NewProject = t.type({
  actis: t.union([Actis, t.null]),
  archived: t.boolean,
  area: Area,
  costCode: t.union([CostCode, t.null]),
  id: t.number,
  subarea: SubArea,
  title: t.string,
});

const User = t.type({
  id: t.number,
  firstName: t.string,
  lastName: t.string,
});

const Label = t.type({
  id: t.union([t.number, t.null]),
  name: t.string,
});
const Labels = t.array(Label);

const PreviousComment = t.type({
  id: t.number,
  status: t.string,
  content: t.string,
  labels: Labels,
  tags: t.union([t.null, Tags]),
});

const Comment = t.type({
  author: User,
  content: t.string,
  created: t.string,
  modified: t.string,
  id: t.number,
  activity: t.number,
  status: t.string,
  previous: t.union([t.null, PreviousComment]),
  tags: t.union([t.null, Tags]),
  unit: t.number,
  labels: Labels,
});

const CommentUnit = t.type({
  comments: t.array(Comment),
  id: t.number,
  name: t.string,
});

const Project = t.type({
  actis: t.union([Actis, t.null]),
  area: Area,
  created: t.string,
  modified: t.string,
  author: User,
  costCode: t.union([CostCode, t.null]),
  id: t.number,
  parent: t.union([t.unknown, t.null, t.number]),
  subarea: SubArea,
  title: t.string,
});

const Comments = t.array(CommentUnit);
const ActivityComments = t.array(Comment);

const Remark = t.type({
  desc: t.string,
  id: t.number,
  type: t.string,
  created: t.string,
  author: User,
});

const RemarkWithProject = t.type({
  desc: t.string,
  id: t.number,
  type: t.string,
  created: t.string,
  author: User,
  comment: Comment,
});

const Event = t.type({
  desc: t.string,
  id: t.number,
  created: t.string,
  author: User,
  startDate: t.string,
  endDate: t.string,
});

const ReportSwitch = t.type({
  id: t.number,
  sprint: t.number,
  year: t.number,
  copiedAt: t.union([t.string, t.null]),
});

const Report = t.type({
  actions: t.array(Remark),
  decisions: t.array(Remark),
  endDate: t.string,
  events: t.array(Event),
  id: t.number,
  needToKnows: t.array(Remark),
  activities: t.array(Project),
  status: t.union([t.literal('current'), t.literal('past'), t.literal('future')]),
  serverVersion: t.string,
  sprint: t.number,
  startDate: t.string,
  units: t.array(Unit),
  year: t.number,
  next: t.union([ReportSwitch, t.null]),
  previous: t.union([ReportSwitch, t.null]),
  locked: t.boolean,
  areas: t.array(Area),
});

const ReportList = t.array(Report);

const Organisations = t.array(FullOrganisation);

const Units = t.array(Unit);

const ElementDelete = t.type({
  id: t.number,
});

const ReportStatistics = t.type({
  actions: t.number,
  decisions: t.number,
  events: t.number,
  needToKnows: t.number,
  activities: t.number,
  activitiesUpdated: t.number,
  usersOnline: t.number,
});

const SearchReport = t.type({
  comments: t.array(
    t.type({
      author: User,
      content: t.string,
      copiedAt: t.union([t.string, t.null]),
      created: t.string,
      id: t.number,
      modified: t.string,
      activity: t.number,
      activityArea: Area,
      activitySubarea: SubArea,
      status: t.string,
      labels: t.array(t.type({ id: t.number, name: t.string })),
      tags: t.union([t.null, Tags]),
      unit: t.type({ id: t.number, name: t.string, roleName: t.string, level: t.number }),
    }),
  ),
  activities: t.array(Project),
  actions: t.array(Remark),
  decisions: t.array(Remark),
  needToKnows: t.array(Remark),
  upcomingEvents: t.array(Event),
});

export interface Role {
  name: string;
  id: number;
}

type Report = t.TypeOf<typeof Report>;
type NewProject = t.TypeOf<typeof NewProject>;
type Project = t.TypeOf<typeof Project>;
type Remark = t.TypeOf<typeof Remark>;
type User = t.TypeOf<typeof User>;
type Event = t.TypeOf<typeof Event>;
type FullOrganisation = t.TypeOf<typeof FullOrganisation>;
type Organisation = t.TypeOf<typeof Organisation>;
type Organisations = t.TypeOf<typeof Organisations>;
type LockedPeriod = t.TypeOf<typeof LockedPeriod>;
type Comment = t.TypeOf<typeof Comment>;
type ActivityComments = t.TypeOf<typeof ActivityComments>;
type Comments = t.TypeOf<typeof Comments>;
type Tags = t.TypeOf<typeof Tags>;
type ElementDelete = t.TypeOf<typeof ElementDelete>;
type Area = t.TypeOf<typeof Area>;
type Areas = t.TypeOf<typeof Areas>;
type SubArea = t.TypeOf<typeof SubArea>;
type Unit = t.TypeOf<typeof Unit>;
type ReportList = t.TypeOf<typeof ReportList>;
type ReportSwitch = t.TypeOf<typeof ReportSwitch>;
type Units = t.TypeOf<typeof Units>;
type Actis = t.TypeOf<typeof Actis>;
type CostCode = t.TypeOf<typeof CostCode>;
type CostCodeList = t.TypeOf<typeof CostCodeList>;
type ActisMilestone = t.TypeOf<typeof ActisMilestone>;
type FullActis = t.TypeOf<typeof FullActis>;
type FullActisList = t.TypeOf<typeof FullActisList>;
type ReportStatistics = t.TypeOf<typeof ReportStatistics>;
type SearchReport = t.TypeOf<typeof SearchReport>;
type RemarkWithProject = t.TypeOf<typeof RemarkWithProject>;
type Label = t.TypeOf<typeof Label>;
type Labels = t.TypeOf<typeof Labels>;
type ReportingUnit = t.TypeOf<typeof ReportingUnit>;

export {
  NewProject,
  Project,
  ActivityComments,
  Comments,
  Report,
  Remark,
  User,
  Areas,
  FullOrganisation,
  Organisation,
  Organisations,
  LockedPeriod,
  LockedPeriods,
  Area,
  Label,
  Labels,
  SubArea,
  Unit,
  Comment,
  ReportList,
  Event,
  Tags,
  ElementDelete,
  Units,
  ReportSwitch,
  Actis,
  CostCode,
  ReportingUnit,
  CostCodeList,
  ActisMilestone,
  FullActis,
  FullActisList,
  ReportStatistics,
  SearchReport,
  RemarkWithProject,
};
