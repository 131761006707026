import ButtonsCell from 'components/Table/ButtonsCell';
import CustomSelect from 'components/CustomSelect';
import DateFormatter from 'containers/DateFormatter';
import { Form } from 'react-bootstrap';
import { LockedPeriod } from 'types/Report';
import React from 'react';
import { Row } from 'react-table';
import { StatefulCustomRangeDatePicker } from '../CustomDatePicker';
import { handleKeyDown } from 'utils/keyUtils';
import { isBefore } from 'date-fns';
import styles from './LockedPeriodsTable.module.scss';

export default ({
  removeLockedPeriod,
  editing,
  handleCancel,
  handleEdit,
  handleSubmit,
  setDesc,
  desc,
  rangeDate,
  setRangeDate,
  options,
  setOption,
  selectedOption,
}) => [
  {
    Header: 'Unit',
    accessor: 'unit',
    className: styles.eventsTableName,
    collapse: true,
    Cell: ({
      value,
      row: {
        original: { id },
      },
    }) => {
      if (editing === id) {
        const val = options?.find((option) => option.value == selectedOption);

        return (
          <CustomSelect
            customStyles={styles.newLockedPeriodCreationUnitSelector}
            options={options}
            primaryColor="#a6fbf2"
            value={val}
            onChange={(event: any) => {
              setOption(event.value);
            }}
          />
        );
      }

      return value.name;
    },
  },
  {
    Header: 'Period',
    accessor: 'date',
    Cell: ({
      row: {
        original: { id, originalStartDate, originalEndDate },
      },
    }) => {
      if (editing === id) {
        return (
          <StatefulCustomRangeDatePicker
            endDate={new Date(rangeDate[1])}
            minDate={
              isBefore(new Date(), new Date(originalStartDate))
                ? new Date()
                : new Date(originalStartDate)
            }
            placeholderText="Period date"
            portalId="dateSearch"
            startDate={new Date(rangeDate[0])}
            onChange={(value) => {
              setRangeDate(value);
            }}
          />
        );
      }

      return (
        <>
          <DateFormatter date={originalStartDate} formatType="date" />
          {' - '}
          <DateFormatter date={originalEndDate} formatType="date" />
        </>
      );
    },
    sortType: (rowA: Row<LockedPeriod>, rowB: Row<LockedPeriod>): number => {
      const startDateA = new Date(rowA.original.startDate).getTime();
      const startDateB = new Date(rowB.original.startDate).getTime();
      if (startDateA > startDateB) {
        return 1;
      }
      if (startDateA < startDateB) {
        return -1;
      }

      return 0;
    },
  },
  {
    Header: 'Description',
    accessor: 'desc',
    Cell: ({
      row: {
        original: { id, desc: originalDesc },
      },
    }) => {
      if (editing === id) {
        return (
          <Form.Control
            data-testid="eventDescriptionInput"
            isInvalid={!desc}
            value={desc}
            autoFocus
            onChange={(event) => setDesc(event.target.value)}
            onKeyDown={(event) =>
              handleKeyDown(event, {
                onEscape: () => handleCancel(),
                onEnter: () => handleSubmit(),
              })
            }
          />
        );
      }

      return originalDesc;
    },
  },
  {
    Header: 'Actions',
    collapse: true,
    center: true,
    Cell: ({
      row: {
        original: { id, startDate, endDate, desc, unit },
      },
    }) => {
      return (
        <ButtonsCell
          editMode={editing === id}
          id="event"
          onCancel={handleCancel}
          onEdit={handleEdit ? () => handleEdit(id, startDate, endDate, desc, unit.id) : undefined}
          onRemove={removeLockedPeriod ? () => removeLockedPeriod(id) : undefined}
          onSubmit={handleSubmit}
        />
      );
    },
  },
];
