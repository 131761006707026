export const DEFAULT_STATUS = 'grey';

export const statusToString = new Proxy(
  {
    1: 'grey',
    2: 'green',
    3: 'yellow',
    4: 'red',
  },
  { get: (handler, type) => handler[type] || DEFAULT_STATUS },
);

export const stringToStatus = new Proxy(
  {
    grey: 1,
    green: 2,
    yellow: 3,
    red: 4,
  },
  { get: (handler, type) => handler[type] || stringToStatus[DEFAULT_STATUS] },
);

export const findHighestStatus = <T extends { status: string }>(flatComments: T[]): string =>
  flatComments.sort(
    (commentA, commentB) => parseInt(commentB.status, 10) - parseInt(commentA.status, 10),
  )[0]?.status || stringToStatus[DEFAULT_STATUS];
