import { useGetActis, useGetCostCodes, useGetSprint, useGetYear } from 'redux/report/reportSlice';
import { useGetActisApi, useGetCostCodeApi } from 'utils/hooks/report';

import ActivityFormPage from 'pages/ActivityFormPage';
import { NewProject } from 'redux/report/types';
import React from 'react';
import { createProject as createProjectAction } from 'redux/report/reportActions';
import { useFetchDataWithResult } from 'utils/fetchData';
import { useGetOrganisationAreaApi } from 'utils/hooks/organisation';
import { useGetOrganisationAreas } from 'redux/organisation/organisationSlice';

const CreateNewActivityPage = ({ onClose }) => {
  const year = useGetYear()!;
  const sprint = useGetSprint()!;
  const areas = useGetOrganisationAreas();
  const actis = useGetActis();
  const costCodes = useGetCostCodes();
  const getCostCode = useGetCostCodeApi();
  const getActis = useGetActisApi();
  const getAreas = useGetOrganisationAreaApi();

  const createProject = useFetchDataWithResult<NewProject>(createProjectAction, {
    model: 'Activity',
    errorMessage: 'Failed to add activity',
    successMessage: 'Activity has been added successfully',
    onSuccess: onClose,
  });

  return (
    <ActivityFormPage
      actis={actis}
      areas={areas}
      costCodes={costCodes}
      getActis={getActis}
      getAreas={getAreas}
      getCostCodes={getCostCode}
      title="Create New Activity"
      onCancel={() => onClose()}
      onSubmit={(data) => createProject({ ...data, year, sprint })}
    />
  );
};

export default CreateNewActivityPage;
