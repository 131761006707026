import CustomForm from 'components/CustomForm';
import React from 'react';
import { ideaFormSchema } from 'schemas/IdeaForm';

export interface IdeaFormData {
  title: string;
  type: string;
  desc: string;
  output: string;
  effort: string;
}

interface IdeaFormProps {
  title: string;
  onSubmit: (formData: IdeaFormData) => any;
  onCancel: () => any;
  disabled?: boolean;
  initialState?: IdeaFormData;
}

const sections = [
  { label: 'Title', name: 'title', required: true, maxLength: 100 },
  {
    label: 'Type',
    name: 'type',
    type: 'select',
    options: [
      'Internal investigation / analysis',
      'Internal development / lab work',
      'Investment (buying something to support our activities)',
      'Studies with industry',
      'Development with industry',
      'Other',
    ],
  },
  {
    label: 'Idea description',
    name: 'desc',
    required: true,
    type: 'textarea',
    rows: 10,
    maxLength: 200,
  },
  { label: 'Main output / result', name: 'output', type: 'textarea', rows: 5, maxLength: 200 },
  { label: 'Estimation of effort needed', name: 'effort' },
];

const defaultInitialState = {
  title: '',
  type: 'Internal investigation / analysis',
  desc: '',
  output: '',
  effort: '',
};

const IdeaForm = ({
  title,
  onSubmit,
  onCancel,
  disabled = false,
  initialState = defaultInitialState,
}: IdeaFormProps) => (
  <CustomForm
    disabled={disabled}
    initialState={initialState}
    schema={ideaFormSchema}
    sections={sections}
    title={title}
    onCancel={onCancel}
    onSubmit={onSubmit}
  />
);
export default IdeaForm;
