import { CommentBaseProps, Comment as CommentComponent } from 'components/Comment';

import { CommentRemove } from 'redux/report/types';
import React from 'react';
import { UserPermission } from 'redux/organisation/types';
import { deleteProjectComment as deleteProjectCommentAction } from '../redux/report/reportActions';
import { useCheckOneOfPermissions } from 'redux/organisation/organisationSlice';
import { useFetchData } from 'utils/fetchData';
import { useGetUser } from 'redux/session/sessionSlice';

const Comment = (props: CommentBaseProps) => {
  const user = useGetUser();
  const canDeleteComment = useCheckOneOfPermissions(UserPermission.DELETE_COMMENT);
  const canEditComment = useCheckOneOfPermissions(UserPermission.CHANGE_COMMENT);

  const deleteProjectComment = useFetchData<CommentRemove>(deleteProjectCommentAction, {
    model: 'Comment',
    errorMessage: 'Failed to delete comment',
    successMessage: 'Comment has been deleted successfully',
  });

  return (
    <CommentComponent
      canEdit={canEditComment}
      user={user}
      onDelete={
        canDeleteComment
          ? () =>
              deleteProjectComment({
                commentId: props.comment.id,
                projectId: props.comment.activity,
                unit: props.comment.unit,
              })
          : undefined
      }
      {...props}
    />
  );
};

export default Comment;
