import {
  CommentCopyPrevious,
  DeleteActivity,
  ProjectArchive,
  SearchReportParams,
} from 'redux/report/types';
import { CostCodeList, FullActisList } from 'types/Report';
import {
  archiveProject,
  copyAllPreviousComments,
  copyPreviousComment,
  getActis,
  getCostCodes,
  removeProject,
  searchReport,
} from 'redux/report/reportActions';

import { useFetchData } from 'utils/fetchData';

export const useCommentCopyPreviousApi = (): ((data: CommentCopyPrevious) => any) =>
  useFetchData<CommentCopyPrevious>(copyPreviousComment, {
    model: 'Comment',
    errorMessage: 'Failed to copy comment',
    successMessage: 'Comment has been copied successfully',
  });

export const useAllCommentCopyPreviousApi = (): (() => any) =>
  useFetchData<CommentCopyPrevious>(copyAllPreviousComments, {
    model: 'Comment',
    errorMessage: 'Failed to copy comments',
    successMessage: 'Comments has been copied successfully',
  });

export const useActivityArchiveApi = (): ((data: ProjectArchive) => any) =>
  useFetchData<ProjectArchive>(archiveProject, {
    model: 'Activity',
    errorMessage: 'Failed to archive activity',
    successMessage: 'Activity has been archived successfully',
  });

export const useDeleteActivityApi = (): (({ id, sprint, year }: DeleteActivity) => any) =>
  useFetchData<DeleteActivity>(removeProject, {
    model: 'Activity',
    errorMessage: 'Failed to delete activity',
    successMessage: 'Activity has been deleted successfully',
  });

export const useSearchReportActionApi = (): ((data: SearchReportParams) => any) =>
  useFetchData<SearchReportParams>(searchReport, { model: 'Search' });

export const useGetCostCodeApi = (): (() => any) =>
  useFetchData<CostCodeList>(getCostCodes, {
    model: 'CostCode',
    errorMessage: 'Failed to fetch CostCode list',
  });

export const useGetActisApi = (): (() => any) =>
  useFetchData<FullActisList>(getActis, {
    model: 'Actis',
    errorMessage: 'Failed to fetch Actis',
  });
