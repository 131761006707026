import React from 'react';
import SidebarSelect from 'components/Sidebar/SidebarSelect';
import { Unit } from 'types/Report';

interface SectionSelectProps {
  currentSection: number | undefined;
  sections: Unit[];
  onChange: (data: string) => void;
}

const SectionSelect = ({ currentSection, sections, onChange }: SectionSelectProps) => {
  return (
    <SidebarSelect
      id="section"
      label="Reporting unit"
      value={currentSection}
      onChange={(event) => onChange(event.target.value)}>
      {sections.map((section) => (
        <option key={section.id} value={section.id}>
          {section.name}
        </option>
      ))}
    </SidebarSelect>
  );
};

export default SectionSelect;
