import * as t from 'io-ts/lib/index';

const UserConfig = t.type({
  dateFormat: t.string,
  hourFormat: t.string,
  frequencyIdeasSummaryMail: t.number,
});

type UserConfig = t.TypeOf<typeof UserConfig>;

export { UserConfig };
