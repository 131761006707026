import * as t from 'io-ts/lib/index';

import { Project } from 'types/Report';

export const ArchivedProjects = t.type({
  count: t.number,
  next: t.unknown,
  previous: t.unknown,
  results: t.array(Project),
});
