import 'react-datepicker/dist/react-datepicker.css';

import FieldGroup, { FieldGroupProps } from 'components/FieldGroup';
import React, { forwardRef, useState } from 'react';

import DatePicker from 'react-datepicker';
import type { ReactDatePickerProps } from 'react-datepicker';
import clsx from 'clsx';
import { createDateFormat } from 'utils/createDateFormat';
import styles from './CustomDatePicker.module.scss';
import { useGetUserConfig } from 'redux/userConfig/userConfigSlice';

// eslint-disable-next-line react/display-name
const CustomInput = forwardRef((props: any, ref) => <FieldGroup ref={ref} {...props} />);

interface CustomReactDatePickerProps<WithRange>
  extends Omit<ReactDatePickerProps, 'selectsRange' | 'onChange'> {
  selectsRange?: WithRange;
  onChange(
    date: WithRange extends false | undefined ? Date | null : [Date | null, Date | null],
    event: React.SyntheticEvent<any> | undefined,
  ): void;
  InputProps?: FieldGroupProps;
  wrapperClassName?: string;
  placeholderText?: string;
}

export const StatefulCustomRangeDatePicker = ({
  startDate,
  endDate,
  onChange,
  ...restProps
}: CustomReactDatePickerProps<true>) => {
  const [start, setStart] = useState<Date | null | undefined>(startDate);
  const [end, setEnd] = useState<Date | null | undefined>(endDate);
  const { dateFormat, hourFormat } = useGetUserConfig();

  const handleChange = ([start, end], event) => {
    setStart(start);
    setEnd(end);
    onChange([start, end], event);
  };

  return (
    <DatePicker
      calendarStartDay={1}
      className={restProps.wrapperClassName}
      customInput={
        <CustomInput
          {...restProps?.InputProps}
          wrapperClassName={clsx([restProps?.InputProps?.wrapperClassName, styles.input])}
        />
      }
      dateFormat={createDateFormat({ formatType: 'date', dateFormat, hourFormat })}
      endDate={end}
      startDate={start}
      isClearable
      selectsRange
      {...restProps}
      onChange={handleChange}
    />
  );
};
