import React from 'react';
import { RemarksPanel } from '../containers';
import ReportPage from 'pages/ReportPage';
import { UserPermission } from 'redux/organisation/types';
import { useCheckOneOfPermissions } from 'redux/organisation/organisationSlice';
import { useGetUsersFromReportCommentAndRemarks } from 'redux/report/reportSlice';

const NeedToKnowsPage = () => {
  const authors = useGetUsersFromReportCommentAndRemarks('needToKnow', 'needToKnows');

  const canEdit = useCheckOneOfPermissions(UserPermission.CHANGE_NEEDTOKNOW);
  const canDelete = useCheckOneOfPermissions(UserPermission.DELETE_NEEDTOKNOW);
  const canAdd = useCheckOneOfPermissions(UserPermission.ADD_NEEDTOKNOW);

  return (
    <ReportPage authors={authors} hideOnlyActionsFilter showAuthorFilter>
      <RemarksPanel
        canAdd={canAdd}
        canDelete={canDelete}
        canEdit={canEdit}
        path="needToKnows"
        tagType="needToKnow"
        title="Need to know"
      />
    </ReportPage>
  );
};

export default NeedToKnowsPage;
