import { fold, left } from 'fp-ts/lib/Either';

import { DEBUG } from '../redux/const';
import { pipe } from 'fp-ts/lib/function';

// Source: https://github.com/gcanti/io-ts/blob/master/Decoder.md
// Validates response from server and returns either an error or the decoded value
export const validateResponse = <T>(codec, json: T) =>
  pipe(
    codec.decode(json),
    fold(
      (error: any) => {
        const errorData = {
          message: 'Wrong response type',
          json,
          error: left(error),
        };
        if (DEBUG) {
          console.error(errorData);
        }
        throw errorData;
      },
      (data: T) => data,
    ),
  );
