import { camelizeKeys, decamelizeKeys } from 'humps';

import type { AxiosInstance } from 'axios';

export default (axiosInstance: AxiosInstance) => {
  axiosInstance.interceptors.request.use((config) => {
    if (config.params) {
      config.params = decamelizeKeys(config.params);
    }
    if (config.data) {
      config.data = decamelizeKeys(config.data);
    }

    return config;
  });

  axiosInstance.interceptors.response.use(
    (response) => {
      if (response.data && response.headers['content-type'].includes('application/json')) {
        response.data = camelizeKeys(response.data);
      }

      return response;
    },
    (error) => {
      if (
        error.response.data &&
        error.response.headers['content-type'].includes('application/json')
      ) {
        error.response.data = camelizeKeys(error.response.data);
      }
      throw error;
    },
  );
};
