/* eslint-disable max-lines */

import React, { FC } from 'react';

import CharacterCounter from 'components/CharacterCounter';
import type { Control } from 'react-hook-form';
import { Controller } from 'react-hook-form';
import { Form } from 'react-bootstrap';
import { ReactComponent as SendImg } from 'assets/icons/send.svg';
import TextareaAutosize from 'react-textarea-autosize';
import clsx from 'clsx';
import styles from './WideInput.module.scss';

interface WideInput {
  onSubmit: () => void;
  buttonText?: string;
  name: string;
  control: Control<any, any>;
  placeholder?: string;
  disabled?: boolean;
  className?: string;
  error?: string;
  maxLength?: number;
}

const WideInput: FC<WideInput> = ({
  onSubmit,
  buttonText = 'Add',
  control,
  name,
  placeholder = 'Add comment',
  disabled = false,
  className = null,
  maxLength,
  error,
}) => {
  const handleSubmit = () => onSubmit();

  return (
    <Form.Group className={clsx(['mb-3', className])} controlId="formControlsTextarea">
      <div className={styles.inputWrapper}>
        <Controller
          control={control}
          name={name}
          render={({ field, fieldState: { invalid } }) => (
            <CharacterCounter
              className={styles.counterWrapper}
              maxLength={maxLength}
              value={field?.value ?? ''}>
              <Form.Control
                as={TextareaAutosize}
                className={styles.wideInput}
                disabled={disabled}
                isInvalid={invalid}
                maxLength={maxLength}
                placeholder={placeholder}
                {...field}
              />
            </CharacterCounter>
          )}
        />
        <div className={clsx([styles.buttonWrapper, maxLength && styles.buttonWrapperGap])}>
          <button
            className={clsx([styles.button, 'btn'])}
            disabled={disabled}
            onClick={handleSubmit}>
            {buttonText}
            <SendImg className={styles.sendImg} />
          </button>
        </div>
      </div>
      <div className={styles.inputError}>{error}</div>
    </Form.Group>
  );
};

export default WideInput;
