import { Card } from 'react-bootstrap';
import ExpandedIdea from './ExpandedIdea';
import { Idea } from 'types/Ideas';
import React from 'react';
import Table from '../Table';
import styles from './IdeasTable.module.scss';

interface Props {
  columns: Array<any>;
  ideas: Array<Idea>;
}

const IdeasTable = ({ columns, ideas }: Props) => {
  return (
    <Card className={styles.ideasTableCard}>
      <Card.Body className={styles.ideasTableCardBody}>
        <div className={styles.ideasTableCardTableScrollable}>
          <Table columns={columns} data={ideas} RowSubComponent={ExpandedIdea} expandable />
        </div>
      </Card.Body>
    </Card>
  );
};

export default IdeasTable;
