import { Column, useExpanded, useSortBy, useTable } from 'react-table';
import React, { useMemo } from 'react';

import clsx from 'clsx';
import styles from './Table.module.scss';

// Data has to be an Object and value keys have to be provided in columns
interface Props {
  columns: readonly Column<any>[];
  data: any[];
  expandable?: boolean;
  RowSubComponent?: React.FunctionComponent | null;
  tableClassName?: string;
  initialSortBy?: string;
}

const expandColumn = {
  Header: () => null,
  id: 'expander',
  collapse: true,
  disableSortBy: true,
  Cell: ({ row }) => (
    <span {...row.getToggleRowExpandedProps()}>
      <i
        className={clsx(
          styles.tableExpander,
          'bi bi-chevron-right',
          row.isExpanded && styles.tableExpanderActive,
        )}
      />
    </span>
  ),
};

const Table = ({
  columns: _columns,
  data,
  expandable = false,
  RowSubComponent = null,
  tableClassName = undefined,
  initialSortBy,
}: Props) => {
  const columns = useMemo(
    () => (expandable ? [expandColumn, ..._columns] : _columns),
    [expandable, _columns],
  );

  const initialState: any = useMemo(
    () =>
      initialSortBy
        ? {
            sortBy: [{ id: initialSortBy, desc: true }],
          }
        : {},
    [initialSortBy],
  );

  const { getTableProps, getTableBodyProps, rows, headers, prepareRow, visibleColumns } = useTable(
    {
      columns,
      data,
      initialState,
    },
    useSortBy,
    useExpanded,
  );

  return (
    <table {...getTableProps()} className={clsx(styles.table, tableClassName)}>
      <thead>
        <tr>
          {headers.map((column: any) => {
            const { key, ...headerProps } = column.getHeaderProps({
              className: clsx(
                column.collapse && styles.tableCollapse,
                column.center && styles.tableHeaderCentered,
              ),
              ...column.getSortByToggleProps(),
            });

            return (
              <th key={key} {...headerProps}>
                {column.render('Header')}
                {!column.disableSortBy && column.canSort && (
                  <span>
                    {column.isSorted ? (
                      <>
                        {column.isSortedDesc ? (
                          <i className={clsx(styles.sortIcon, 'bi bi-sort-down')} />
                        ) : (
                          <i className={clsx(styles.sortIcon, 'bi bi-sort-up')} />
                        )}
                      </>
                    ) : (
                      <i className={clsx(styles.sortIcon, 'bi bi-funnel')} />
                    )}
                  </span>
                )}
              </th>
            );
          })}
        </tr>
      </thead>

      <tbody {...getTableBodyProps()}>
        {rows.map((row: any) => {
          prepareRow(row);
          const { key, ...rowProps } = row.getRowProps();

          return (
            <React.Fragment key={key}>
              <tr {...rowProps}>
                {row.cells.map((cell: any) => {
                  const { key, ...cellProps } = cell.getCellProps({
                    className: clsx(
                      typeof cell.column.className === 'function'
                        ? cell.column.className({ cell, row })
                        : cell.column.className,
                      row.isExpanded && styles.tableCellExpanded,
                      cell.column.collapse && styles.tableCollapse,
                    ),
                  });

                  return (
                    <td key={key} {...cellProps}>
                      {cell.render('Cell')}
                    </td>
                  );
                })}
              </tr>
              {row.isExpanded && RowSubComponent && (
                <tr className={styles.tableRowExpanded}>
                  <td colSpan={visibleColumns.length}>
                    <RowSubComponent {...row.original} />
                  </td>
                </tr>
              )}
            </React.Fragment>
          );
        })}
      </tbody>
    </table>
  );
};

export default Table;
