import { EditCommentBaseProps, default as EditCommentComponent } from '../components/EditComment';

import { Comment } from '../types/Report';
import { CommentCreate } from 'redux/report/types';
import React from 'react';
import { addProjectComment as addProjectCommentAction } from '../redux/report/reportActions';
import { useFetchData } from 'utils/fetchData';

interface CreateCommentContainerProps extends EditCommentBaseProps {
  projectId: number;
  unit: number;
  initText?: string;
  limitText: boolean;
}

const CreateComment = ({
  unit,
  projectId,
  initText,
  limitText,
  ...props
}: CreateCommentContainerProps) => {
  const addProjectComment = useFetchData<CommentCreate>(addProjectCommentAction, {
    model: 'Comment',
    errorMessage: 'Failed to add comment',
    successMessage: 'Comment has been added successfully',
  });

  const comment: Comment = { content: initText } as unknown as Comment;

  return (
    <EditCommentComponent
      {...props}
      comment={comment}
      limitText={limitText}
      onSubmit={({ content, tags, status, labels }) =>
        addProjectComment({ content, tags, unit, status, projectId, labels })
      }
    />
  );
};

export default CreateComment;
