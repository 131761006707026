import React from 'react';
import clsx from 'clsx';
import { stringToStatus } from '../../utils/status';
import styles from './Status.module.scss';

const statusToClass = new Proxy(
  {
    [stringToStatus['green']]: styles.green,
    [stringToStatus['yellow']]: styles.yellow,
    [stringToStatus['red']]: styles.red,
    [stringToStatus['grey']]: styles.gray,
  },
  { get: (handler, type) => handler[type] || styles.gray },
);

interface StatusProps {
  level: string;
  className?: string;
}

const Status = ({ level, className }: StatusProps) => (
  <div className={clsx([styles['status-indicator'], statusToClass[level], className])}></div>
);
export default Status;
