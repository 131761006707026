import React from 'react';
import Status from 'components/Status';
import { stringToStatus } from 'utils/status';

const InstructionPage = () => {
  return (
    <div className="standard-container-wrapper">
      <div className="standard-container-standalone">
        <h2>General</h2>
        <p>Please provide relevant data in particular for the support to projects.</p>
        <p>Please avoid repetition from one weekly to another when possible.</p>
        <p>Please avoid specific acronyms or explain.</p>
        <h2>Adding a new project</h2>
        <p>
          If you cannot find your project in the list, please click on &rdquo;Add project&rdquo;. If
          area or subarea for your project doesn&apos;t exist, please contact configuration unit of
          your system.
        </p>
        <h2>Projects / activities splitted across sections/persons</h2>
        <p>
          For projects splitted across sections or persons, please make sure that a separate
          activity is created in the tool for each section and person, eventually with a different
          cost code.
        </p>
        <h2>Project names</h2>
        <p>
          Please use project name with no additional description, for example: &rdquo;Juice&rdquo;,
          &rdquo;Plato S/C PDR&rdquo;, &rdquo;Clean Space Initiative&rdquo;
        </p>
        <h2>Status</h2>
        <p>
          Please set status for your projects as follows:
          <div className="d-flex align-items-center">
            <Status level={stringToStatus['green']} /> in line with schedule
          </div>
          <div className="d-flex align-items-center">
            <Status level={stringToStatus['yellow']} /> potential impact on schedule and costs /
            issues experienced
          </div>
          <div className="d-flex align-items-center">
            <Status level={stringToStatus['red']} /> confirmed/highly probable impact on schedule
            and costs
          </div>
          <div className="d-flex align-items-center">
            <Status level={stringToStatus['grey']} /> no status set
          </div>
        </p>
      </div>
    </div>
  );
};

export default InstructionPage;
