import * as Yup from 'yup';

import { Controller, useForm } from 'react-hook-form';

import CustomRangePicker from 'components/CustomDatePicker';
import CustomSelect from 'components/CustomSelect';
import React from 'react';
import WideInput from 'components/WideInput';
import clsx from 'clsx';
import { lockedPeriodFormSchema } from 'schemas/LockedPeriodForm';
import styles from './NewLockedPeriodCreation.module.scss';
import { yupResolver } from '@hookform/resolvers/yup';

export interface LockedPeriodFormData {
  desc: string;
  dateRange: {
    start: Date;
    end: Date;
  };
  unit: number;
}

interface Props {
  create: (desc: string, startDate: Date, endDate: Date, unit: number) => any;
  editable: boolean;
  options: { label: string; value: any }[];
}

const NewLockedPeriodCreation = ({ editable, create, options }: Props) => {
  const { handleSubmit, control, reset } = useForm({
    resolver: yupResolver<Yup.SchemaOf<LockedPeriodFormData>>(lockedPeriodFormSchema),
  });

  return (
    <div className={styles.newLockedPeriodCreation}>
      <Controller
        control={control}
        name="dateRange"
        render={({ field: { value, onChange }, fieldState: { invalid } }) => (
          <CustomRangePicker
            className={clsx(styles.newLockedPeriodCreationDateSelector, invalid && 'is-invalid')}
            disabled={!editable}
            endDate={value?.end}
            minDate={new Date()}
            placeholderText="Period"
            portalId="dateSearch"
            startDate={value?.start}
            wrapperClassName={styles.newLockedPeriodCreationDateSelectorWrapper}
            onChange={([start, end]) => onChange({ start, end })}
          />
        )}
      />

      <Controller
        control={control}
        name="unit"
        render={({ field: { value, onChange }, fieldState: { invalid } }) => {
          const val = options?.find((option) => option.value === value) ?? null;

          return (
            <CustomSelect
              customStyles={styles.newLockedPeriodCreationUnitSelector}
              invalid={invalid}
              options={options}
              placeholder="Unit"
              primaryColor="#a6fbf2"
              value={val}
              onChange={(event: any) => onChange(event.value)}
            />
          );
        }}
      />

      <WideInput
        className={styles.newLockedPeriodCreationInput}
        control={control}
        disabled={!editable}
        name={'desc'}
        placeholder={'Description'}
        onSubmit={handleSubmit(({ desc, dateRange, unit }) => {
          create(desc, dateRange.start, dateRange.end, unit);
          reset({ desc: '', dateRange: null, unit: null });
        })}
      />
    </div>
  );
};

export default NewLockedPeriodCreation;
