import { ReactComponent as Alert } from 'assets/icons/status/alert.svg';
import { ReactComponent as Caoution } from 'assets/icons/status/caoution.svg';
import { ReactComponent as NoStatus } from 'assets/icons/status/no_status.svg';
import React from 'react';
import { ReactComponent as Well } from 'assets/icons/status/well.svg';
import { stringToStatus } from '../../utils/status';

const statusToImg = new Proxy(
  {
    [stringToStatus['green']]: Well,
    [stringToStatus['yellow']]: Caoution,
    [stringToStatus['red']]: Alert,
    [stringToStatus['grey']]: NoStatus,
  },
  { get: (handler, type) => handler[type] || NoStatus },
);

interface StatusProps {
  level: string;
  className?: string;
}

const Status = ({ level, className }: StatusProps) => {
  const StatusComp = statusToImg[level];

  return <StatusComp className={className} />;
};
export default Status;
