import {
  getActivity,
  getActivityHistory,
  getActivityUnits,
} from 'redux/activityHistory/activityHistoryActions';

import { ActivityHistoryFetch } from 'types/ActivityHistory';
import { useFetchData } from 'utils/fetchData';

export const useGetActivityApi = (): ((id: number) => any) =>
  useFetchData<number>(getActivity, { model: 'Activity' });

export const useActivityHistoryApi = (): ((data: ActivityHistoryFetch) => any) =>
  useFetchData<ActivityHistoryFetch>(getActivityHistory, { model: 'Activity' });

export const useActivityHistoryUnitsApi = (): ((id: number) => any) =>
  useFetchData<number>(getActivityUnits, { model: 'Activity' });
