import { ARCHIVED_PROJECT_QUERY, UNARCHIVE_PROJECT_QUERY } from './const';

import { ArchivedProjects } from 'types/ArchivedProjects';
import { ReportAxiosRequestConfig } from 'types/Axios';
import { addUnarchivedProject } from 'redux/report/reportSlice';
import apiCall from '../apiCall';
import { createAsyncThunk } from '@reduxjs/toolkit';
import { validateResponse } from '../../utils/validateResponse';

export const getArchivedProjects = createAsyncThunk(
  'archivedProjects/fetchArchivedProjects',
  async (_, { signal, getState }) => {
    const { year, sprint } = getState().report;
    const response = await apiCall.get(ARCHIVED_PROJECT_QUERY(year, sprint), {
      signal,
      withOrganisation: true,
      withSection: true,
    } as ReportAxiosRequestConfig);

    return validateResponse(ArchivedProjects, response.data).results;
  },
);

export const unarchiveProject = createAsyncThunk(
  'archivedProjects/unarchiveProject',
  async (id: number, { getState, dispatch, rejectWithValue }) => {
    try {
      const { year, sprint } = getState().report;
      const response = await apiCall.put(UNARCHIVE_PROJECT_QUERY(id, year, sprint), null, {
        withOrganisation: true,
        withSection: true,
      } as ReportAxiosRequestConfig);
      const project = getState().archivedProjects.projects.find((project) => project.id === id);
      if (project) {
        dispatch(addUnarchivedProject(project));
      }

      return response.data;
    } catch (error: any) {
      return rejectWithValue(error?.response);
    }
  },
);
