import { Card, Spinner } from 'react-bootstrap';
import React, { useEffect, useMemo } from 'react';

import ReportPage from 'pages/ReportPage';
import { ReportStatistics } from 'types/Report';
import Table from 'components/Table';
import createColumns from './createColumns';
import { startCase } from 'lodash';
import styles from './StatisticsPage.module.scss';

interface Props {
  getReportStatistics: () => any;
  statistics: ReportStatistics | null;
  year: number | null;
  sprint: number | null;
  isLoading: boolean;
}

const StatisticsPage = ({ getReportStatistics, statistics, year, sprint, isLoading }: Props) => {
  useEffect(() => getReportStatistics(), [year, sprint]);

  const data = useMemo(
    () =>
      statistics
        ? Object.keys(statistics)
            .sort()
            .map((key) => ({ name: startCase(key), value: statistics[key] }))
        : [],
    [statistics],
  );

  const columns = createColumns();

  return (
    <ReportPage showBasicFilters={false}>
      <Card className={styles.statisticsPageCard}>
        <Card.Body>
          {isLoading ? (
            <div className={styles.statisticsPageLoading}>
              <Spinner animation="border" />
            </div>
          ) : (
            <Table columns={columns} data={data} />
          )}
        </Card.Body>
      </Card>
    </ReportPage>
  );
};

export default StatisticsPage;
