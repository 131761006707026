import {
  getArchivedIdeas as getArchivedIdeasAction,
  removeIdea as removeIdeaAction,
  unarchiveIdea as unarchiveIdeaAction,
} from 'redux/idea/ideaActions';

import { default as ArchivedIdeasPageComponent } from 'pages/ArchivedIdeasPage';
import React from 'react';
import { UserPermission } from 'redux/organisation/types';
import { useCheckOneOfPermissions } from 'redux/organisation/organisationSlice';
import { useFetchData } from 'utils/fetchData';
import { useGetArchivedIdeas } from 'redux/idea/ideaSlice';

const ArchivedIdeasPage = () => {
  const archivedIdeas = useGetArchivedIdeas();
  const canViewIdea = useCheckOneOfPermissions(UserPermission.VIEW_ARCHIVED_IDEA);
  const canDeleteIdea = useCheckOneOfPermissions(UserPermission.DELETE_IDEA);
  const canUnarchiveIdea = useCheckOneOfPermissions(UserPermission.UNARCHIVE_IDEA);

  const getArchivedIdeas = useFetchData(getArchivedIdeasAction, { model: 'Ideas' });
  const unarchiveIdea = useFetchData<number>(unarchiveIdeaAction, {
    model: 'Idea',
    errorMessage: 'Failed to unarchive idea',
    successMessage: 'Idea has been unarchived successfully',
  });

  const removeIdea = useFetchData<number>(removeIdeaAction, {
    model: 'Idea',
    errorMessage: 'Failed to remove idea',
    successMessage: 'Idea has been removed successfully',
  });

  return (
    <ArchivedIdeasPageComponent
      archivedIdeas={archivedIdeas}
      canDelete={canDeleteIdea}
      canUnarchive={canUnarchiveIdea}
      canViewIdea={canViewIdea}
      getArchivedIdeas={getArchivedIdeas}
      removeIdea={removeIdea}
      unarchiveIdea={unarchiveIdea}
    />
  );
};

export default ArchivedIdeasPage;
