import {
  ActionIcon,
  ActivitiesIcon,
  ArchivesIcon,
  DecisionIcon,
  EditIcon,
  IdeasIcon,
  InstructionIcon,
  NeedToKnowIcon,
  PersonIcon,
  SearchIcon,
  UpcomingEventIcon,
} from '../../assets/icons';
import React, { useState } from 'react';
import { Report, Unit } from 'types/Report';

import { CopyToClipboard } from 'react-copy-to-clipboard';
import { CurrentUser } from 'types/Session';
import ExportForm from 'components/ExportForm';
import { Modal } from 'react-bootstrap';
import OrganisationSelect from 'containers/OrganisationSelect';
import SectionSelect from 'containers/SectionSelect';
import SidebarSection from './SidebarSection';
import { SidebarSections } from './types';
import { UserPermission } from 'redux/organisation/types';
import UserSettings from '../../containers/UserSettings';
import { checkOneOfPermissions } from 'utils/permissions';
import clsx from 'clsx';
import { createEncodedEnv } from './helpers';
import esaLogo from '../../assets/esaLogo.png';
import { pages } from '../../pages/consts';
import styles from './Sidebar.module.scss';

interface SidebarProps {
  report: Report | null;
  user: CurrentUser | null;
  role: string | undefined;
  handleExport: (params) => void;
  units: Unit[];
  permissions: UserPermission[];
  reportError: boolean;
  sectionError: boolean;
}

const Sidebar = ({
  report,
  user,
  role,
  handleExport,
  units,
  permissions,
  reportError,
  sectionError,
}: SidebarProps) => {
  const [showSettings, setShowSettings] = useState(false);
  const [showExport, setShowExport] = useState(false);
  const encodedEnv = createEncodedEnv(report);

  const sections: SidebarSections = [
    {
      title: 'Report',
      buttons: [
        {
          Icon: ActivitiesIcon,
          label: 'Activities',
          disabled: reportError || sectionError,
          path: pages.activitiesPage,
          permissions: [UserPermission.ADD_ACTIVITY, UserPermission.VIEW_ACTIVITY],
        },
        {
          Icon: ActionIcon,
          label: 'Action request',
          disabled: reportError || sectionError,
          path: pages.actionsPage,
          permissions: [UserPermission.ADD_ACTION, UserPermission.VIEW_ACTION],
        },
        {
          Icon: DecisionIcon,
          label: 'Decision request',
          disabled: reportError || sectionError,
          path: pages.decisionsPage,
          permissions: [UserPermission.ADD_DECISION, UserPermission.VIEW_DECISION],
        },
        {
          Icon: NeedToKnowIcon,
          label: 'Need to know',
          disabled: reportError || sectionError,
          path: pages.needToKnowsPage,
          permissions: [UserPermission.ADD_NEEDTOKNOW, UserPermission.VIEW_NEEDTOKNOW],
        },
        {
          Icon: UpcomingEventIcon,
          label: 'Upcoming events',
          disabled: reportError || sectionError,
          path: pages.eventsPage,
          permissions: [UserPermission.ADD_UPCOMING_EVENT, UserPermission.VIEW_UPCOMING_EVENT],
        },
      ],
    },
    {
      title: 'Generic',
      buttons: [
        {
          Icon: IdeasIcon,
          label: 'Ideas',
          disabled: false,
          path: pages.ideasPage,
          permissions: [UserPermission.ADD_IDEA, UserPermission.VIEW_IDEA],
        },
        {
          Icon: ArchivesIcon,
          label: 'Idea archives',
          disabled: false,
          path: pages.archivedIdeasPage,
          permissions: [UserPermission.VIEW_ARCHIVED_IDEA],
        },
      ],
    },
    {
      title: 'Other',
      buttons: [
        {
          Icon: SearchIcon,

          label: 'Search',
          disabled: false,
          path: pages.searchPage,
          permissions: [],
        },
        {
          Icon: ArchivesIcon,
          label: 'Export',
          disabled: reportError || sectionError,
          onClick: () => setShowExport(true),
          permissions: [],
        },
        {
          Icon: EditIcon,
          label: 'Admin',
          disabled: sectionError,
          path: pages.adminPage,
          permissions: [UserPermission.ADD_LOCKED_PERIOD, UserPermission.VIEW_LOCKED_PERIOD],
        },
        {
          Icon: ArchivesIcon,
          label: 'Activity archives',
          disabled: reportError || sectionError,
          path: pages.archivedActivitiesPage,
          permissions: [UserPermission.VIEW_ARCHIVED_ACTIVITY],
        },
        {
          Icon: ActionIcon,
          label: 'Statistics',
          disabled: reportError || sectionError,
          path: pages.statisticsPage,
          permissions: [UserPermission.VIEW_REPORT_STATISTIC],
        },
      ],
    },
    {
      title: 'Help',
      buttons: [
        {
          Icon: InstructionIcon,
          label: 'Instruction',
          disabled: false,
          path: pages.instructionPage,
          permissions: [],
        },
      ],
    },
    {
      title: 'Account',
      buttons: [
        {
          Icon: PersonIcon,
          label: `${user?.firstName ?? ''} ${user?.lastName ?? ''}`,
          disabled: false,
          subLabel: role,
          permissions: [],
          buttons: [
            {
              Icon: (props) => <i {...props} className={clsx(['bi bi-gear', props.className])} />,
              label: 'Settings',
              disabled: false,
              onClick: () => setShowSettings(true),
              permissions: [],
            },
          ],
        },
      ],
    },
  ];

  const filteredSections = sections
    .map((section) => {
      const newButtons = section.buttons.filter(
        (button) =>
          button.permissions.length === 0 || checkOneOfPermissions(permissions, button.permissions),
      );

      return { ...section, buttons: newButtons };
    })
    .filter((section) => section.buttons.length > 0);

  const unitRelation = units.reduce((prev, cur) => {
    return {
      ...prev,
      [cur.level]: units.filter((unit) => unit.level === cur.level).map((unit) => unit.id),
    };
  }, {});

  const exportUnits: Unit[] = Object.values<number[]>(unitRelation).map(
    (relations: number[]): Unit => {
      const mainUnit = units.find((unit) => unit.id === relations[0]) as Unit;

      if (relations.length === 1) {
        return mainUnit;
      }

      const roleName: string = relations
        .map((id: number) => units.find((unit) => unit.id === id)?.roleName || '')
        .join(' / ');

      return {
        ...mainUnit,
        roleName,
      };
    },
  );

  return (
    <>
      <div className={styles.sidebar} data-testid="Sidebar">
        <div className={styles.sidebarMain}>
          <div className={styles.sidebarHeader}>REPTOOL</div>
          <div className={styles.sidebarButtons}>
            {filteredSections.map((section) => (
              <SidebarSection key={section.title} {...section} />
            ))}
            <OrganisationSelect />
            <SectionSelect />
          </div>
        </div>

        <div className={styles.sidebarFooter}>
          <img alt="logo" className={styles.sidebarFooterLogo} height="25" src={esaLogo} />
          <div className={styles.sidebarFooterVersion}>
            Version: {process.env.REACT_APP_APPVERSION} {process.env.REACT_APP_GITHEAD}
          </div>
          <div>
            In case of problems, suggestions or questions, please contact{' '}
            <a href="mailto:email2@esa.int?subject=Division Reporting Tool feedback">
              email2@esa.int
            </a>
          </div>
          <div className={styles.sidebarFooterEnv}>
            To simplify the investigations, please paste browser info into your e-mail:{' '}
            <CopyToClipboard text={encodedEnv}>
              <p className={styles.sidebarFooterEnvCopy}>Copy browser info</p>
            </CopyToClipboard>
          </div>
        </div>
      </div>

      <Modal show={showSettings} size="lg" centered onHide={() => setShowSettings(false)}>
        <UserSettings onClose={() => setShowSettings(false)} />
      </Modal>

      <Modal show={showExport} size="lg" centered onHide={() => setShowExport(false)}>
        <ExportForm
          handleExport={handleExport}
          units={exportUnits}
          onClose={() => setShowExport(false)}
        />
      </Modal>
    </>
  );
};

export default Sidebar;
