import { Card, Spinner } from 'react-bootstrap';
import { LockedPeriod, Unit } from 'types/Report';
import React, { useMemo } from 'react';

import LockedPeriodsTable from './LockedPeriodsTable';
import NewLockedPeriodCreation from 'containers/NewLockedPeriodCreation';
import styles from './AdminPanel.module.scss';

type Props = {
  lockedPeriods: LockedPeriod[];
  removeLockedPeriod: (id: number) => any;
  updateLockedPeriod: (
    id: number,
    desc: string,
    startDate: string,
    endDate: string,
    unit: number,
  ) => any;
  sections: Unit[];
  isLoading: boolean;
  canAddLockedPeriod: boolean;
  canViewLockedPeriods: boolean;
  canDeleteLockedPeriod: boolean;
  canUpdateLockedPeriod: boolean;
};

const AdminPanel = ({
  lockedPeriods,
  removeLockedPeriod,
  updateLockedPeriod,
  sections,
  isLoading,
  canAddLockedPeriod,
  canViewLockedPeriods,
  canDeleteLockedPeriod,
  canUpdateLockedPeriod,
}: Props) => {
  const unitOptions = useMemo(
    () => sections?.map((section) => ({ label: `${section.name}`, value: section.id })),
    [sections],
  );

  return (
    <div className="standard-container-wrapper">
      <Card className={styles.adminPanel}>
        <Card.Body>
          {canAddLockedPeriod && <NewLockedPeriodCreation />}
          {canViewLockedPeriods &&
            (isLoading ? (
              <div className={styles.adminPanelLoading}>
                <Spinner animation="border" />
              </div>
            ) : (
              <LockedPeriodsTable
                lockedPeriods={lockedPeriods}
                options={unitOptions}
                removeLockedPeriod={canDeleteLockedPeriod ? removeLockedPeriod : undefined}
                updateLockedPeriod={canUpdateLockedPeriod ? updateLockedPeriod : undefined}
              />
            ))}
        </Card.Body>
      </Card>
    </div>
  );
};

export default AdminPanel;
