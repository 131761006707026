import { Idea } from 'types/Ideas';
import { MultiSelectOption } from 'components/MultiSelect/MultiSelect';

export const getUserOptions = (ideas: Idea[]) =>
  Object.values(
    ideas.reduce(
      (authors, { author }) => ({
        ...authors,
        [author.id]: { label: `${author.firstName} ${author.lastName}`, value: author.id },
      }),
      {} as Record<number, MultiSelectOption>,
    ),
  );

export const filterIdeas = (ideas: Idea[], { search, dates, selectedUsers, selectedTypes }) => {
  let filteredIdeas = ideas;

  if (selectedUsers?.length) {
    const flatSelectedUsers = selectedUsers.map(({ value }) => value);
    filteredIdeas = filteredIdeas.filter((idea) => flatSelectedUsers.includes(idea.author.id));
  }

  if (selectedTypes?.length) {
    const flatSelectedTypes = selectedTypes.map(({ value }) => value);
    filteredIdeas = filteredIdeas.filter((idea) => flatSelectedTypes.includes(idea.type));
  }

  const { startDate, endDate } = dates;

  if (startDate) {
    filteredIdeas = filteredIdeas.filter((idea) => startDate < new Date(idea.created));
  }

  if (endDate) {
    // endDate needs to be adjusted by 1 day, because date picker returns 00:00:00 time
    const adjustedEndDate = new Date(endDate);
    adjustedEndDate.setDate(adjustedEndDate.getDate() + 1);

    filteredIdeas = filteredIdeas.filter((idea) => adjustedEndDate > new Date(idea.created));
  }

  const searchLower = search?.toLowerCase();

  if (searchLower) {
    filteredIdeas = filteredIdeas.filter(
      (idea) =>
        idea.title?.toLowerCase().includes(searchLower) ||
        idea.desc?.toLowerCase().includes(searchLower) ||
        idea.output?.toLowerCase().includes(searchLower) ||
        idea.effort?.toLowerCase().includes(searchLower) ||
        idea.type?.toLowerCase().includes(searchLower),
    );
  }

  return filteredIdeas;
};

export const getTypesOption = (): MultiSelectOption<string>[] => {
  const ideaTypes = [
    'Internal investigation / analysis',
    'Internal development / lab work',
    'Investment (buying something to support our activities)',
    'Studies with industry',
    'Development with industry',
    'Other',
  ] as const;

  return ideaTypes.map((ideaType) => ({
    label: ideaType,
    value: ideaType,
  }));
};
