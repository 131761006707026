import {
  archiveIdea as archiveIdeaAction,
  getIdeas as getIdeasAction,
  removeIdea as removeIdeaAction,
} from '../redux/idea/ideaActions';

import { default as IdeasPageComponent } from '../pages/IdeasPage';
import React from 'react';
import { UserPermission } from 'redux/organisation/types';
import { useCheckOneOfPermissions } from 'redux/organisation/organisationSlice';
import { useFetchData } from 'utils/fetchData';
import { useGetIdeas } from '../redux/idea/ideaSlice';

const IdeasPage = ({ ...props }) => {
  const ideas = useGetIdeas();
  const canViewIdea = useCheckOneOfPermissions(UserPermission.VIEW_IDEA);
  const canEditIdea = useCheckOneOfPermissions(UserPermission.CHANGE_IDEA);
  const canDeleteIdea = useCheckOneOfPermissions(UserPermission.DELETE_IDEA);
  const canArchiveIdea = useCheckOneOfPermissions(UserPermission.ARCHIVE_IDEA);
  const canAddIdea = useCheckOneOfPermissions(UserPermission.ADD_IDEA);

  const archiveIdea = useFetchData<number>(archiveIdeaAction, {
    model: 'Idea',
    errorMessage: 'Failed to archive idea',
    successMessage: 'Idea has been archived successfully',
  });

  const removeIdea = useFetchData<number>(removeIdeaAction, {
    model: 'Idea',
    errorMessage: 'Failed to remove idea',
    successMessage: 'Idea has been removed successfully',
  });

  const getIdeas = useFetchData(getIdeasAction, { model: 'Idea' });

  return (
    <IdeasPageComponent
      canAdd={canAddIdea}
      canArchive={canArchiveIdea}
      canDelete={canDeleteIdea}
      canEdit={canEditIdea}
      canViewIdea={canViewIdea}
      removeIdea={removeIdea}
      {...props}
      archiveIdea={archiveIdea}
      getIdeas={getIdeas}
      ideas={ideas}
    />
  );
};

export default IdeasPage;
