import DateFormatter from 'containers/DateFormatter';
import React from 'react';

interface ArchivedIdeasTable {
  unarchiveIdea?: (id: number) => any;
  removeIdea?: (id: number) => any;
}

export default ({ unarchiveIdea, removeIdea }: ArchivedIdeasTable) => [
  {
    Header: 'Date',
    accessor: 'created',
    Cell: ({ value }) => {
      const date = new Date(value);

      return <DateFormatter date={date} formatType="date" />;
    },
  },
  {
    Header: 'Name',
    accessor: 'author',
    Cell: ({ value: { firstName, lastName } }) => `${firstName} ${lastName}`,
  },
  { Header: 'Report', accessor: 'title' },
  { Header: 'Type', accessor: 'type' },
  {
    Header: 'Edit',
    collapse: true,
    center: true,
    Cell: ({
      row: {
        original: { id },
      },
    }) => {
      return (
        <>
          {unarchiveIdea && (
            <button onClick={() => unarchiveIdea(id)}>
              <i className="bi bi-arrow-up-square" title="Unarchive" />
            </button>
          )}
          {removeIdea && (
            <button onClick={() => removeIdea(id)}>
              <i className="bi bi-trash" title="Delete" />
            </button>
          )}
        </>
      );
    },
  },
];
