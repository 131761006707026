export const pages = {
  ideasPage: '/ideas',
  createNewIdeaPage: '/ideas/create',
  editIdeaPage: '/ideas/edit/:id',
  loginPage: '/login',
  activityHistoryPage: '/activity-history/:id',
  activitiesPage: '/',
  decisionsPage: '/decisions',
  actionsPage: '/actions',
  needToKnowsPage: '/need-to-knows',
  eventsPage: '/events',
  createNewActivityPage: '/activities/create',
  editActivityPage: '/activities/edit/:id',
  instructionPage: '/instruction',
  archivedIdeasPage: '/archived-ideas',
  archivedActivitiesPage: '/archived-activities',
  searchPage: '/search',
  userSettings: '/settings',
  statisticsPage: '/statistics',
  adminPage: '/admin',
  noReportUnitPage: '/error/report-unit',
};

export const editIdeaPageUrl = (id) => `/ideas/edit/${id}`;
export const editActivityPageUrl = (id) => `/activities/edit/${id}`;
export const activityHistoryUrl = (id) => `/activity-history/${id}`;
