import { Idea, Ideas } from '../../types/Ideas';
import {
  archiveIdea,
  createIdea,
  getArchivedIdeas,
  getIdeas,
  removeIdea,
  unarchiveIdea,
  updateFieldIdea,
  updateIdea,
} from './ideaActions';

import { IDEA_SLICE_NAME } from './const';
import { createSlice } from '@reduxjs/toolkit';
import { useAppSelector } from '../hooks';

export interface IdeaState extends Ideas {
  hasError: boolean;
  isLoading: boolean;
  error: string;
  archivedIdeas: Idea[];
}

const initialState: IdeaState = {
  count: 0,
  next: '',
  previous: '',
  results: [],
  archivedIdeas: [],
  hasError: false,
  isLoading: false,
  error: '',
};

export const ideaSlice = createSlice({
  name: IDEA_SLICE_NAME,
  initialState,
  reducers: {},
  extraReducers: (builder) => {
    builder.addCase(getIdeas.fulfilled, (state, action) => {
      const data = action.payload as Ideas;
      state.next = data.next;
      state.previous = data.previous;
      state.count = data.count;
      state.results = data.results;
      state.hasError = false;
    });
    builder.addCase(getIdeas.rejected, (state) => {
      state.hasError = true;
    });
    builder.addCase(getArchivedIdeas.fulfilled, (state, action) => {
      state.archivedIdeas = (action.payload as Ideas).results;
      state.hasError = false;
    });
    builder.addCase(getArchivedIdeas.rejected, (state) => {
      state.hasError = true;
    });
    builder.addCase(removeIdea.fulfilled, (state, action) => {
      const { arg: id } = action.meta;
      state.results = state.results.filter((idea) => idea.id !== id);
      state.archivedIdeas = state.archivedIdeas.filter((idea) => idea.id !== id);
    });
    builder.addCase(archiveIdea.fulfilled, (state, action) => {
      const { arg: id } = action.meta;
      // eslint-disable-next-line @typescript-eslint/no-non-null-assertion
      const archivedIdea = state.results.find((idea) => idea.id === id)!;
      if (archivedIdea) {
        state.results.splice(state.results.indexOf(archivedIdea), 1);
        state.archivedIdeas.push(archivedIdea);
      }
    });
    builder.addCase(unarchiveIdea.fulfilled, (state, action) => {
      const { arg: id } = action.meta;
      state.archivedIdeas = state.archivedIdeas.filter((idea) => idea.id !== id);
    });
    builder.addCase(updateFieldIdea.fulfilled, (state, action) => {
      const { id, fieldToUpdate, value } = action.meta.arg;
      state.results = state.results.map((idea) =>
        idea.id === id ? { ...idea, [fieldToUpdate]: value } : idea,
      );
    });
    builder.addCase(updateIdea.fulfilled, (state, action) => {
      const { id } = action.payload;

      state.results = state.results.map((idea) => (idea.id === id ? action.payload : idea));
    });
    builder.addCase(createIdea.fulfilled, (state, action) => {
      state.results.push(action.payload);
    });
  },
});

export const {} = ideaSlice.actions;

export const useGetIdeas = () => useAppSelector((state) => state.idea.results);
export const useGetArchivedIdeas = () => useAppSelector((state) => state.idea.archivedIdeas);

export const ideaReducer = ideaSlice.reducer;
