import { Project } from 'types/Report';
import { useMemo } from 'react';
import { useQueryParams } from './useQueryParams';

export const useGetSelectedProject = (projects): Project => {
  const queryParams = useQueryParams();
  const selectedProjectId = parseInt(queryParams.get('selectedProject')!, 10);

  return useMemo<Project>(() => {
    if (selectedProjectId && projects.length > 0) {
      return projects.find((project) => project.id === selectedProjectId);
    }
  }, [projects, selectedProjectId]);
};
