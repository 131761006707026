import React, { ReactElement } from 'react';

import styles from './CharacterCounter.module.scss';

interface CharacterCounterProps {
  disabled?: boolean;
  value: string;
  maxLength?: number;
  className?: string;
}

const CharacterCounter = ({
  children,
  value,
  disabled = false,
  maxLength,
  className,
}: React.PropsWithChildren<CharacterCounterProps>): ReactElement => {
  if (!maxLength || disabled) {
    return <>{children}</>;
  }

  if (className) {
    return (
      <div className={className}>
        {children}
        <div className={styles.counter}>{`${value.length}/${maxLength}`}</div>
      </div>
    );
  }

  return (
    <>
      {children}
      <div className={styles.counter}>{`${value.length}/${maxLength}`}</div>
    </>
  );
};

export default CharacterCounter;
