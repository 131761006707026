import { TokenData, TokenDecoded } from './../types/Session';

import { camelizeKeys } from 'humps';
import jwt_decode from 'jwt-decode';
import { validateResponse } from './validateResponse';

export default (token): TokenData | undefined => {
  if (!token) {
    return undefined;
  }
  const tokenDecoded = camelizeKeys(jwt_decode(token)) as TokenDecoded;
  validateResponse(TokenDecoded, tokenDecoded);

  return {
    tokenType: tokenDecoded.tokenType,
    expirationDate: tokenDecoded.exp,
    issuedAt: tokenDecoded.iat,
    id: tokenDecoded.jti,
    userId: tokenDecoded.userId,
    firstName: tokenDecoded.firstName,
    lastName: tokenDecoded.lastName,
    email: tokenDecoded.email,
    units: tokenDecoded.units || [],
  };
};
