/* eslint-disable max-lines */
import {
  ACTIVITY_ARCHIVED,
  ACTIVITY_CREATED,
  ACTIVITY_DELETED,
  ACTIVITY_UNARCHIVED,
  ACTIVITY_UPDATED,
  COMMENT_CREATED,
  COMMENT_DELETED,
  COMMENT_UPDATED,
  EVENT_CREATED,
  EVENT_DELETED,
  EVENT_UPDATED,
  LOCKED_PERIOD_CREATED,
  LOCKED_PERIOD_DELETED,
  LOCKED_PERIOD_UPDATED,
  REMARK_CREATED,
  REMARK_DELETED,
  REMARK_UPDATED,
  USER_ONLINE_STATUS,
} from './reportActionTypes';
import {
  ActivityComments,
  Comment,
  CostCodeList,
  ElementDelete,
  Event,
  FullActisList,
  NewProject as NewProjectType,
  Organisations,
  Remark,
  Report,
  ReportStatistics,
  SearchReport,
} from '../../types/Report';
import {
  CommentCopyPrevious,
  CommentCreate,
  CommentRemove,
  CommentUpdate,
  EventCreate,
  EventUpdate,
  ExportParams,
  GenerateReportStatistics,
  NewProject,
  ProjectArchive,
  ProjectUpdate,
  RemarkCreate,
  RemarkRemove,
  RemarkUpdate,
  SearchReportParams,
} from './types';
import { createAction, createAsyncThunk } from '@reduxjs/toolkit';

import { OUT_EVENT_DATE_FORMAT } from '../../constants';
import { ReportAxiosRequestConfig } from 'types/Axios';
import { addArchivedProject } from 'redux/archivedProjects/archivedProjectsSlice';
import apiCall from 'redux/apiCall';
import apiFileCall from 'redux/apiFileCall';
import { decamelize } from 'humps';
import formatFns from 'date-fns/format';
import { remarkPathToEndpoint } from './helpers';
import { saveAs } from 'file-saver';
import { validateResponse } from '../../utils/validateResponse';

export const exportCurrentSprint = createAsyncThunk(
  'report/exportCurrentSprint',
  async (params: ExportParams, { getState }) => {
    const { report, session, organisation } = getState();
    const { year, sprint } = report;
    const { currentOrganisation, currentSection } = organisation;
    const { accessToken } = session.token!;

    const url = `reptool/organisations/${currentOrganisation?.id}/units/${currentSection?.id}/reports/${year}/${sprint}/export/`;
    let urlParams = `?`;

    Object.keys(params).forEach((key) => {
      const value = params[key];
      if (value) {
        urlParams += `${decamelize(key)}=${value}&`;
      }
    });

    const response = await apiFileCall(`${url}${urlParams}`, {
      headers: { Authorization: `Bearer ${accessToken}` },
    });

    if (response.status === 200 || response.status === 201) {
      const filename = response.filename || `report-${year}-${sprint}.docx`;
      saveAs(response.file, filename);
    }
  },
);

export const getLatestReport = createAsyncThunk(
  'report/getLatest',
  async (_, { signal, rejectWithValue }) => {
    try {
      const response = await apiCall.get<Report>('reports/latest/', {
        signal,
        withOrganisation: true,
        withSection: true,
      } as ReportAxiosRequestConfig);

      return validateResponse(Report, response.data);
    } catch (error: any) {
      return rejectWithValue(error?.response);
    }
  },
);

export const getReport = createAsyncThunk(
  'report/getBySprint',
  async ({ sprint, year }: { sprint: number; year: number }, { signal, rejectWithValue }) => {
    try {
      const response = await apiCall.get<Report>(`reports/${year}/${sprint}/`, {
        signal,
        withOrganisation: true,
        withSection: true,
      } as ReportAxiosRequestConfig);

      return validateResponse(Report, response.data);
    } catch (error: any) {
      return rejectWithValue(error?.response);
    }
  },
);

export const getPreviousReport = createAsyncThunk(
  'report/getPrevious',
  async (_, { getState, signal, rejectWithValue }) => {
    try {
      const { sprint, year } = getState().report;
      const response = await apiCall.get<Report>(`reports/${year}/${sprint}/previous/`, {
        signal,
        withOrganisation: true,
        withSection: true,
      } as ReportAxiosRequestConfig);

      return validateResponse(Report, response.data);
    } catch (error: any) {
      return rejectWithValue(error?.response);
    }
  },
);

export const getNextReport = createAsyncThunk(
  'report/getNext',
  async (_, { getState, signal, rejectWithValue }) => {
    try {
      const { sprint, year } = getState().report;
      const response = await apiCall.get<Report>(`reports/${year}/${sprint}/next/`, {
        signal,
        withOrganisation: true,
        withSection: true,
      } as ReportAxiosRequestConfig);

      return validateResponse(Report, response.data);
    } catch (error: any) {
      return rejectWithValue(error?.response);
    }
  },
);

export const createProject = createAsyncThunk(
  'report/project/create',
  async (
    { year, sprint, title, area, subarea, costCode, actis }: NewProject,
    { rejectWithValue },
  ) => {
    try {
      const response = await apiCall.post(
        `reports/${year}/${sprint}/activities/`,
        {
          title,
          area,
          ...(subarea && { subarea }),
          ...(costCode && { costCode }),
          ...(actis && { actis }),
        },
        {
          withOrganisation: true,
          withSection: true,
        } as ReportAxiosRequestConfig,
      );

      return validateResponse(NewProjectType, response.data);
    } catch (error: any) {
      return rejectWithValue(error?.response);
    }
  },
);

export const removeProject = createAsyncThunk(
  'report/project/remove',
  async (
    { id, sprint, year }: { id: number; sprint: number; year: number },
    { rejectWithValue },
  ) => {
    try {
      const response = await apiCall.delete(`reports/${year}/${sprint}/activities/${id}/`, {
        withOrganisation: true,
        withSection: true,
      } as ReportAxiosRequestConfig);

      return validateResponse(ElementDelete, response.data);
    } catch (error: any) {
      return rejectWithValue(error?.response);
    }
  },
);

export const archiveProject = createAsyncThunk(
  'report/project/archive',
  async ({ id }: ProjectArchive, { getState, dispatch, rejectWithValue }) => {
    try {
      const { year, sprint } = getState().report;
      const response = await apiCall.put(
        `reports/${year}/${sprint}/activities/${id}/archive/`,
        null,
        {
          withOrganisation: true,
          withSection: true,
        } as ReportAxiosRequestConfig,
      );

      const report = getState().report;
      const project = report.report[report.year!][report.sprint!].activities.find(
        (project) => project.id === id,
      );
      if (project) {
        dispatch(addArchivedProject(project));
      }

      return response.data;
    } catch (error: any) {
      return rejectWithValue(error?.response);
    }
  },
);

export const copyPreviousComment = createAsyncThunk(
  'report/project/copyPreviousComment',
  async ({ id }: CommentCopyPrevious, { getState, rejectWithValue }) => {
    try {
      const { year, sprint } = getState().report;
      const response = await apiCall.get<Comment>(
        `reports/${year}/${sprint}/activities/${id}/comments/copy/`,
        {
          withOrganisation: true,
          withSection: true,
        } as ReportAxiosRequestConfig,
      );

      return validateResponse(Comment, response.data);
    } catch (error: any) {
      return rejectWithValue(error?.response);
    }
  },
);

export const copyAllPreviousComments = createAsyncThunk(
  'report/project/copyAllPreviousComments',
  async (_, { getState, rejectWithValue }) => {
    try {
      const { year, sprint } = getState().report;
      const response = await apiCall.get<ActivityComments>(
        `reports/${year}/${sprint}/activities/copy-my-comments/`,
        {
          withOrganisation: true,
          withSection: true,
        } as ReportAxiosRequestConfig,
      );

      return validateResponse(ActivityComments, response.data);
    } catch (error: any) {
      return rejectWithValue(error?.response);
    }
  },
);

export const updateProjectSettings = createAsyncThunk(
  'report/project/update',
  async (_data: ProjectUpdate, { rejectWithValue }) => {
    try {
      const { id, year, sprint, title, area, subarea, costCode, actis } = _data;
      const data = {
        id,
        title,
        area,
        subarea,
        costCode,
        actis,
      };

      const response = await apiCall.patch(`reports/${year}/${sprint}/activities/${id}/`, data, {
        withOrganisation: true,
        withSection: true,
      } as ReportAxiosRequestConfig);

      return validateResponse(NewProjectType, response.data);
    } catch (error: any) {
      return rejectWithValue(error?.response);
    }
  },
);

export const addProjectComment = createAsyncThunk(
  'report/project/addComment',
  async (data: CommentCreate, { getState, rejectWithValue }) => {
    try {
      const { sprint, year } = getState().report;
      const { projectId, content, unit, tags, status, labels } = data;
      const response = await apiCall.post(
        `reports/${year}/${sprint}/activities/${projectId}/comments/`,
        { content, unit, tags, status, labels },
        {
          withOrganisation: true,
          withSection: true,
        } as ReportAxiosRequestConfig,
      );

      return response.data;
    } catch (error: any) {
      return rejectWithValue(error?.response);
    }
  },
);

export const updateProjectComment = createAsyncThunk(
  'report/project/updateComment',
  async (data: CommentUpdate, { getState, rejectWithValue }) => {
    try {
      const { sprint, year } = getState().report;
      const { projectId, content, unit, tags, status, commentId, labels } = data;
      const response = await apiCall.put(
        `reports/${year}/${sprint}/activities/${projectId}/comments/${commentId}/`,
        { content, unit, tags, status, labels },
        {
          withOrganisation: true,
          withSection: true,
        } as ReportAxiosRequestConfig,
      );

      return response.data;
    } catch (error: any) {
      return rejectWithValue(error?.response);
    }
  },
);

export const deleteProjectComment = createAsyncThunk(
  'report/project/deleteComment',
  async (data: CommentRemove, { getState, rejectWithValue }) => {
    try {
      const { sprint, year } = getState().report;
      const { projectId, commentId } = data;
      const response = await apiCall.delete(
        `reports/${year}/${sprint}/activities/${projectId}/comments/${commentId}/`,
        {
          withOrganisation: true,
          withSection: true,
        } as ReportAxiosRequestConfig,
      );

      return response.data;
    } catch (error: any) {
      return rejectWithValue(error?.response);
    }
  },
);

export const notifyProjectEditActive = createAsyncThunk(
  'report/project/notifyEditActive',
  async ({ week, year, id }: { week: number; year: number; id: number }, { getState }) => {
    // TODO: No endpoints for notifying on new backend
    // const response = await apiCall(
    //   createRoute(NOTIFY_PREDIT_ACTIVE_QUERY, getState, `${year}/${week}/${id}`),
    // );
    // return response.json();
  },
);

export const notifyProjectEditStop = createAsyncThunk(
  'report/project/notifyEditStop',
  async ({ week, year, id }: { week: number; year: number; id: number }, { getState }) => {
    // TODO: No endpoints for notifying on new backend
    // const response = await apiCall(
    //   createRoute(NOTIFY_PREDIT_STOP_QUERY, getState, `${year}/${week}/${id}`),
    // );
    // return response.json();
  },
);

export const createRemark = createAsyncThunk(
  'report/remark/create',
  async (data: RemarkCreate, { getState, rejectWithValue }) => {
    try {
      const { sprint, year } = getState().report;
      const { desc, path } = data;
      const endpoint = remarkPathToEndpoint[path];

      const response = await apiCall.post(
        `reports/${year}/${sprint}/${endpoint}/`,
        {
          desc,
        },
        {
          withOrganisation: true,
          withSection: true,
        } as ReportAxiosRequestConfig,
      );

      return validateResponse(Remark, response.data);
    } catch (error: any) {
      return rejectWithValue(error?.response);
    }
  },
);

export const removeRemark = createAsyncThunk(
  'report/remark/remove',
  async (data: RemarkRemove, { getState, rejectWithValue }) => {
    try {
      const { sprint, year } = getState().report;
      const { id, path } = data;
      const endpoint = remarkPathToEndpoint[path];

      const response = await apiCall.delete(`reports/${year}/${sprint}/${endpoint}/${id}/`, {
        withOrganisation: true,
        withSection: true,
      } as ReportAxiosRequestConfig);

      return validateResponse(ElementDelete, response.data);
    } catch (error: any) {
      return rejectWithValue(error?.response);
    }
  },
);

export const updateRemark = createAsyncThunk(
  'report/remark/update',
  async (data: RemarkUpdate, { getState, rejectWithValue }) => {
    try {
      const { sprint, year } = getState().report;
      const { desc, path, id } = data;
      const endpoint = remarkPathToEndpoint[path];

      const response = await apiCall.put(
        `reports/${year}/${sprint}/${endpoint}/${id}/`,
        {
          desc,
        },
        {
          withOrganisation: true,
          withSection: true,
        } as ReportAxiosRequestConfig,
      );

      return validateResponse(Remark, response.data);
    } catch (error: any) {
      return rejectWithValue(error?.response);
    }
  },
);

export const createEvent = createAsyncThunk(
  'report/event/create',
  async (data: EventCreate, { getState, rejectWithValue }) => {
    try {
      const { sprint, year } = getState().report;
      const { desc } = data;
      const startDate = formatFns(data.startDate, OUT_EVENT_DATE_FORMAT);
      const endDate = formatFns(data.endDate, OUT_EVENT_DATE_FORMAT);

      const response = await apiCall.post(
        `reports/${year}/${sprint}/events/`,
        {
          desc,
          startDate,
          endDate,
        },
        {
          withOrganisation: true,
          withSection: true,
        } as ReportAxiosRequestConfig,
      );

      return validateResponse(Event, response.data);
    } catch (error: any) {
      return rejectWithValue(error?.response);
    }
  },
);

export const removeEvent = createAsyncThunk(
  'report/event/remove',
  async (id: number, { getState, rejectWithValue }) => {
    try {
      const { sprint, year } = getState().report;

      const response = await apiCall.delete(`reports/${year}/${sprint}/events/${id}/`, {
        withOrganisation: true,
        withSection: true,
      } as ReportAxiosRequestConfig);

      return validateResponse(ElementDelete, response.data);
    } catch (error: any) {
      return rejectWithValue(error?.response);
    }
  },
);

export const updateEvent = createAsyncThunk(
  'report/event/update',
  async (data: EventUpdate, { getState, rejectWithValue }) => {
    try {
      const { sprint, year } = getState().report;
      const { desc, id } = data;
      const startDate = formatFns(new Date(data.startDate), OUT_EVENT_DATE_FORMAT);
      const endDate = formatFns(new Date(data.endDate), OUT_EVENT_DATE_FORMAT);

      const response = await apiCall.put(
        `reports/${year}/${sprint}/events/${id}/`,
        {
          desc,
          startDate,
          endDate,
        },
        {
          withOrganisation: true,
          withSection: true,
        } as ReportAxiosRequestConfig,
      );

      return validateResponse(Event, response.data);
    } catch (error: any) {
      return rejectWithValue(error?.response);
    }
  },
);

export const getActisProject = createAsyncThunk(
  'report/actis/get',
  async (id: string, { getState }) => {
    // TODO: implement
    // const response = await apiCall(createRoute(ACTIS_QUERY, getState, Base64.encode(id)));
    // return await response.json();
  },
);

export const getWlms = createAsyncThunk(
  'report/wlms/get',
  async (
    {
      id,
      year,
      signal,
    }: {
      id: string;
      year: number;
      signal: any; // TODO: Define type
    },
    { getState },
  ) => {
    // TODO: implement
    // const response = await apiCall(
    //   createRoute(WLMS_QUERY, getState, `${Base64.encode(id)}/${year}`),
    //   'GET',
    //   null,
    //   {
    //     signal,
    //   },
    // );
    // return await response.json();
  },
);

export const getOrganisations = createAsyncThunk('report/organisations', async (_, { signal }) => {
  const response = await apiCall.get(`reptool/organisations/`, { signal });

  return validateResponse(Organisations, response.data);
});

export const getActis = createAsyncThunk('report/actis', async () => {
  const response = await apiCall.get('/reptool/actis/');

  return validateResponse(FullActisList, response.data);
});

export const getCostCodes = createAsyncThunk('report/costCodes', async () => {
  const response = await apiCall.get('/reptool/costcode/');

  return validateResponse(CostCodeList, response.data);
});

export const searchReport = createAsyncThunk(
  'report/search',
  async (params: SearchReportParams) => {
    const response = await apiCall.get('search/', {
      params,
      withOrganisation: true,
      withSection: true,
    } as ReportAxiosRequestConfig);

    return validateResponse(SearchReport, response.data);
  },
);

export const getReportStatistics = createAsyncThunk(
  'report/statistics',
  async ({ year, sprint }: GenerateReportStatistics) => {
    const response = await apiCall.get(`reports/${year}/${sprint}/statistics/`, {
      withOrganisation: true,
      withSection: true,
    } as ReportAxiosRequestConfig);

    return validateResponse(ReportStatistics, response.data);
  },
);

// WEBSOCKET ACTIONS
export const userOnlineStatus = createAction(USER_ONLINE_STATUS, (payload) => ({ payload }));

export const activityCreated = createAction(ACTIVITY_CREATED, (payload) => ({ payload }));
export const activityDeleted = createAction(ACTIVITY_DELETED, (payload) => ({ payload }));
export const activityUpdated = createAction(ACTIVITY_UPDATED, (payload) => ({ payload }));
export const activityArchived = createAction(ACTIVITY_ARCHIVED, (payload) => ({ payload }));
export const activityUnarchived = createAction(ACTIVITY_UNARCHIVED, (payload) => ({ payload }));

export const remarkCreated = createAction(REMARK_CREATED, (payload) => ({ payload }));
export const remarkDeleted = createAction(REMARK_DELETED, (payload) => ({ payload }));
export const remarkUpdated = createAction(REMARK_UPDATED, (payload) => ({ payload }));

export const eventCreated = createAction(EVENT_CREATED, (payload) => ({ payload }));
export const eventDeleted = createAction(EVENT_DELETED, (payload) => ({ payload }));
export const eventUpdated = createAction(EVENT_UPDATED, (payload) => ({ payload }));

export const commentCreated = createAction(COMMENT_CREATED, (payload) => ({ payload }));
export const commentDeleted = createAction(COMMENT_DELETED, (payload) => ({ payload }));
export const commentUpdated = createAction(COMMENT_UPDATED, (payload) => ({ payload }));

export const lockedPeriodCreated = createAction(LOCKED_PERIOD_CREATED, (payload) => ({ payload }));
export const lockedPeriodDeleted = createAction(LOCKED_PERIOD_DELETED, (payload) => ({ payload }));
export const lockedPeriodUpdated = createAction(LOCKED_PERIOD_UPDATED, (payload) => ({ payload }));
