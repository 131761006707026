import { useGetAllPermissions, useGetCurrentSection } from 'redux/organisation/organisationSlice';
import {
  useGetCurrentReport,
  useGetCurrentRole,
  useGetHasReportError,
} from '../redux/report/reportSlice';

import { ExportParams } from 'redux/report/types';
import React from 'react';
import { default as SidebarComponent } from '../components/Sidebar';
import { exportCurrentSprint as exportCurrentSprintAction } from 'redux/report/reportActions';
import { useFetchData } from 'utils/fetchData';
import { useGetUser } from '../redux/session/sessionSlice';

const Sidebar = () => {
  const report = useGetCurrentReport();
  const user = useGetUser();
  const role = useGetCurrentRole();
  const hasReportError = useGetHasReportError();
  const currentSection = useGetCurrentSection();

  const hasSectionError = !currentSection?.id;

  const permissions = useGetAllPermissions();

  const exportCurrentSprint = useFetchData<ExportParams>(exportCurrentSprintAction, {
    model: 'Export',
  });

  return (
    <SidebarComponent
      handleExport={exportCurrentSprint}
      permissions={permissions}
      report={report}
      reportError={hasReportError}
      role={role}
      sectionError={hasSectionError}
      units={report?.units ?? []}
      user={user}
    />
  );
};

export default Sidebar;
