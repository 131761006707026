import 'react-datepicker/dist/react-datepicker.css';

import FieldGroup, { FieldGroupProps } from 'components/FieldGroup';
import React, { forwardRef, useRef, useState } from 'react';

import { Button } from 'react-bootstrap';
import DatePicker from 'react-datepicker';
import type { ReactDatePickerProps } from 'react-datepicker';
import clsx from 'clsx';
import { createDateFormat } from 'utils/createDateFormat';
import styles from './CustomDatePicker.module.scss';
import { useGetUserConfig } from 'redux/userConfig/userConfigSlice';

// eslint-disable-next-line react/display-name
const CustomInput = forwardRef((props: any, ref) => <FieldGroup ref={ref} {...props} />);

interface CustomReactDatePickerProps
  extends Omit<ReactDatePickerProps, 'selectsRange' | 'onChange'> {
  onChange(date: Date | null, event: React.SyntheticEvent<any> | undefined): void;
  InputProps?: FieldGroupProps;
  wrapperClassName?: string;
  placeholderText?: string;
  error?: string;
  format?: 'dateTime' | 'date';
  showConfirmBtn?: boolean;
}

export const StatefulCustomDatePicker = ({
  selected,
  onChange,
  ...props
}: CustomReactDatePickerProps) => {
  const [start, setStart] = useState<Date | null | undefined>(selected);
  const { dateFormat, hourFormat } = useGetUserConfig();
  const startRef = useRef<any>();

  const handleClose = () => {
    startRef.current?.setOpen(false);
  };

  const handleChange = (start, event) => {
    setStart(start);
    onChange(start, event);
  };

  return (
    <div className={clsx(props.wrapperClassName, styles.customDateWrapper)}>
      <DatePicker
        calendarStartDay={1}
        className={props.wrapperClassName}
        customInput={<CustomInput {...props.InputProps} />}
        dateFormat={createDateFormat({
          formatType: props.format ?? 'date',
          dateFormat,
          hourFormat,
        })}
        ref={startRef}
        shouldCloseOnSelect={!props.showConfirmBtn}
        {...props}
        selected={start}
        onChange={handleChange}>
        {props.showConfirmBtn && (
          <div className={styles.actions}>
            <Button onClick={handleClose}>OK</Button>
          </div>
        )}
      </DatePicker>
      <div className={styles.inputError}>{props.error}</div>
    </div>
  );
};
