import * as yup from 'yup';

export const commentFormSchema = yup
  .object({
    content: yup.string().required(),
    action: yup.boolean(),
    decision: yup.boolean(),
    needToKnow: yup.boolean(),
    status: yup.number(),
  })
  .required();
