import { EditIcon, ThrashIcon } from '../../assets/icons';

import React from 'react';
import styles from './Table.module.scss';

const ButtonsCell = ({ id, onCancel, onSubmit, onEdit, onRemove, editMode }) => {
  return (
    <div className={styles.tableEditButtons}>
      {editMode ? (
        <>
          <button data-testid={`${id}CancelButton`} onClick={onCancel}>
            <i className="bi bi-x-circle" title="Cancel" />
          </button>
          <button data-testid={`${id}SaveButton`} onClick={onSubmit}>
            <i className="bi bi-check-circle" title="Save" />
          </button>
        </>
      ) : (
        <>
          {onEdit && (
            <button data-testid={`${id}EditButton`} onClick={onEdit}>
              <EditIcon className="bigIcon" title="Edit" />
            </button>
          )}
          {onRemove && (
            <button data-testid={`${id}RemoveButton`} onClick={onRemove}>
              <ThrashIcon className="bigIcon" title="Delete" />
            </button>
          )}
        </>
      )}
    </div>
  );
};

export default ButtonsCell;
