import {
  IDEAS_ARCHIVED_QUERY,
  IDEAS_QUERY,
  IDEA_ARCHIVE_QUERY,
  IDEA_ID_QUERY,
  IDEA_QUERY,
  IDEA_SLICE_NAME,
  IDEA_UNARCHIVE_QUERY,
} from './const';
import { Idea, IdeaCreate, IdeaFieldUpdate, IdeaUpdate, Ideas } from '../../types/Ideas';

import { ReportAxiosRequestConfig } from 'types/Axios';
import apiCall from '../apiCall';
import { createAsyncThunk } from '@reduxjs/toolkit';
import { validateResponse } from '../../utils/validateResponse';

export const getIdeas = createAsyncThunk(
  `${IDEA_SLICE_NAME}/get`,
  async (_, { signal, rejectWithValue }) => {
    try {
      const response = await apiCall.get(IDEAS_QUERY, {
        signal,
        withOrganisation: true,
      } as ReportAxiosRequestConfig);
      const data: Ideas = response.data;

      return validateResponse(Ideas, data);
    } catch (error: any) {
      return rejectWithValue(error?.response);
    }
  },
);

export const getArchivedIdeas = createAsyncThunk(
  `${IDEA_SLICE_NAME}/getArchived`,
  async (_, { signal, rejectWithValue }) => {
    try {
      const response = await apiCall.get(IDEAS_ARCHIVED_QUERY, {
        signal,
        withOrganisation: true,
      } as ReportAxiosRequestConfig);
      const data: Ideas = response.data;

      return validateResponse(Ideas, data);
    } catch (error: any) {
      return rejectWithValue(error?.response);
    }
  },
);

export const createIdea = createAsyncThunk(
  `${IDEA_SLICE_NAME}/create`,
  async (data: IdeaCreate, { rejectWithValue }) => {
    try {
      const response = await apiCall.post(IDEA_QUERY, data, {
        withOrganisation: true,
      } as ReportAxiosRequestConfig);

      return response.data;
    } catch (error: any) {
      return rejectWithValue(error?.response);
    }
  },
);

export const removeIdea = createAsyncThunk(
  `${IDEA_SLICE_NAME}/remove`,
  async (id: number, { rejectWithValue }) => {
    try {
      await apiCall.delete(IDEA_ID_QUERY(id), {
        withOrganisation: true,
      } as ReportAxiosRequestConfig);
    } catch (error: any) {
      return rejectWithValue(error?.response);
    }
  },
);

export const archiveIdea = createAsyncThunk(
  `${IDEA_SLICE_NAME}/archive`,
  async (id: number, { rejectWithValue }) => {
    try {
      await apiCall.put(IDEA_ARCHIVE_QUERY(id), null, {
        withOrganisation: true,
      } as ReportAxiosRequestConfig);
    } catch (error: any) {
      return rejectWithValue(error?.response);
    }
  },
);

export const unarchiveIdea = createAsyncThunk(
  `${IDEA_SLICE_NAME}/unarchive`,
  async (id: number, { rejectWithValue }) => {
    try {
      await apiCall.put(IDEA_UNARCHIVE_QUERY(id), null, {
        withOrganisation: true,
      } as ReportAxiosRequestConfig);
    } catch (error: any) {
      return rejectWithValue(error?.response);
    }
  },
);

export const updateFieldIdea = createAsyncThunk(
  `${IDEA_SLICE_NAME}/updateField`,
  async (data: IdeaFieldUpdate, { getState, rejectWithValue }) => {
    try {
      const { results } = getState().idea;
      const editableIdea = results.find((result) => result.id === data.id);
      const requestBody = {
        ...editableIdea,
        [data.fieldToUpdate]: data.value,
      };
      await apiCall.put(IDEA_ID_QUERY(data.id), requestBody, {
        withOrganisation: true,
      } as ReportAxiosRequestConfig);
    } catch (error: any) {
      return rejectWithValue(error?.response);
    }
  },
);

export const updateIdea = createAsyncThunk(
  `${IDEA_SLICE_NAME}/update`,
  async ({ id, data }: IdeaUpdate, { rejectWithValue }) => {
    try {
      const response = await apiCall.patch(IDEA_ID_QUERY(id), data, {
        withOrganisation: true,
      } as ReportAxiosRequestConfig);

      return validateResponse(Idea, response.data);
    } catch (error: any) {
      return rejectWithValue(error?.response);
    }
  },
);
