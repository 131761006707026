import CreatableCustomSelect, {
  BasicCreatableCustomSelectProps,
  CreatableCustomSelectOption,
} from 'components/CreatableCustomSelect';
import React, { useMemo } from 'react';

import { Labels } from 'types/Report';
import { useGetCurrentOrganisationLabels } from 'redux/organisation/organisationSlice';
import { useSearchCurrentOrganisationLabelsApi } from 'utils/hooks/organisation';

const CreatableLabel = ({ value, onChange, ...props }: BasicCreatableCustomSelectProps) => {
  const getLabels = useSearchCurrentOrganisationLabelsApi();
  const labels = useGetCurrentOrganisationLabels();

  const convertLabelsToCreatableLabelOption = (labels: Labels): CreatableCustomSelectOption[] => {
    if (!labels) {
      return [];
    }

    return labels.map((label) => ({
      label: label.name,
      value: label.id?.toString() ?? '',
    }));
  };

  const initLabels = useMemo(() => {
    return convertLabelsToCreatableLabelOption(labels);
  }, [labels]);

  const convertCreatableLabelOptionToLabels = (options: CreatableCustomSelectOption[]): Labels => {
    if (!options) {
      return [];
    }

    return options.map((option) => ({
      name: option.label,
      id: option.label === option.value ? null : Number(option.value),
    }));
  };

  const values = convertLabelsToCreatableLabelOption(value as Labels);

  const promiseOptions = (query: string): Promise<CreatableCustomSelectOption[]> =>
    getLabels(query).then((data) => {
      if (data.meta.requestStatus === 'fulfilled') {
        return convertLabelsToCreatableLabelOption(data.payload);
      }

      return [];
    });

  const handleChange = (data: any, meta: any) => {
    onChange?.(convertCreatableLabelOptionToLabels(data), meta);
  };

  return (
    <CreatableCustomSelect
      initialOptions={initLabels}
      loadOptions={promiseOptions}
      {...props}
      value={values}
      onChange={handleChange}
    />
  );
};

export default CreatableLabel;
