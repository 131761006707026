import { LockedPeriodCreate, LockedPeriodUpdate } from 'redux/report/types';
import {
  createOrganisationLockedPeriod,
  deleteOrganisationLockedPeriod,
  getAllCurrentOrganisationLabels,
  getAllOrganisationLabels,
  getOrganisationAreas,
  getOrganisationLockedPeriods,
  getOrganisationUnits,
  getOrganisationUsers,
  getOrganisations,
  searchCurrentOrganisationLabels,
  updateOrganisationLockedPeriod,
} from 'redux/organisation/organisationActions';

import { LockedPeriod } from 'types/Report';
import { useFetchData } from 'utils/fetchData';

export const useGetOrganisationLockedPeriodsApi = (): (() => LockedPeriod[]) =>
  useFetchData(getOrganisationLockedPeriods, { model: 'Locked periods' });

export const useGetOrganisationAreaApi = (): (() => any) =>
  useFetchData(getOrganisationAreas, {
    model: 'Areas',
    errorMessage: 'Failed to fetch areas',
  });

export const useCreateLockedPeriodApi = (): ((period: LockedPeriodCreate) => void) =>
  useFetchData<LockedPeriodCreate>(createOrganisationLockedPeriod, {
    model: 'Locked period',
    errorMessage: 'Failed to add locked period',
    successMessage: 'Locked period has been added successfully',
  });

export const useUpdateLockedPeriodApi = (): ((period: LockedPeriodUpdate) => void) =>
  useFetchData<LockedPeriodUpdate>(updateOrganisationLockedPeriod, {
    model: 'Actis',
    errorMessage: 'Failed to update locked period',
    successMessage: 'Locked period has been updated successfully',
  });

export const useDeleteLockedPeriodApi = (): ((id: number) => void) =>
  useFetchData<number>(deleteOrganisationLockedPeriod, {
    model: 'Actis',
    errorMessage: 'Failed to remove locked period',
    successMessage: 'Locked period has been removed successfully',
  });

export const useSearchCurrentOrganisationLabelsApi = (): ((query: string) => any) =>
  useFetchData<string>(searchCurrentOrganisationLabels, { model: 'Labels' });

export const useGetAllCurrentOrganisationLabelsApi = (): (() => any) =>
  useFetchData(getAllCurrentOrganisationLabels, { model: 'Labels' });

export const useGetOrganisationUsersApi = (): (() => any) =>
  useFetchData(getOrganisationUsers, { model: 'Organisation users' });

export const useGetOrganisationUnitsApi = (): (() => any) =>
  useFetchData<number>(getOrganisationUnits, { model: 'Organisation units' });

export const useGetOrganisationsApi = (): (() => any) =>
  useFetchData(getOrganisations, { model: 'Organisations' });

export const useGetAllOrganisationLabelsApi = (): ((id: number) => any) =>
  useFetchData(getAllOrganisationLabels, { model: 'Labels' });
