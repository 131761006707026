import React from 'react';
import { RemarksPanel } from '../containers';
import ReportPage from 'pages/ReportPage';
import { UserPermission } from 'redux/organisation/types';
import { useCheckOneOfPermissions } from 'redux/organisation/organisationSlice';
import { useGetUsersFromReportCommentAndRemarks } from 'redux/report/reportSlice';

const DecisionsPage = () => {
  const authors = useGetUsersFromReportCommentAndRemarks('decision', 'decisions');

  const canEdit = useCheckOneOfPermissions(UserPermission.CHANGE_DECISION);
  const canDelete = useCheckOneOfPermissions(UserPermission.DELETE_DECISION);
  const canAdd = useCheckOneOfPermissions(UserPermission.ADD_DECISION);

  return (
    <ReportPage authors={authors} hideOnlyActionsFilter showAuthorFilter>
      <RemarksPanel
        canAdd={canAdd}
        canDelete={canDelete}
        canEdit={canEdit}
        path="decisions"
        tagType="decision"
        title="Decision request"
      />
    </ReportPage>
  );
};

export default DecisionsPage;
