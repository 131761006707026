import {
  Area,
  CostCodeList,
  FullActisList,
  Labels,
  Organisation,
  Role,
  SearchReport,
  Unit,
  User,
} from 'types/Report';

import React from 'react';
import SearchForm from './SearchForm';
import { SearchReportParams } from 'redux/report/types';
import SearchTable from './SearchTable';

interface Props {
  areas: Area[];
  users: User[];
  roles: Role[];
  actis: FullActisList;
  costCodes: CostCodeList;
  currentSection: Unit | null;
  searchReport: (params: SearchReportParams) => any;
  search: SearchReport | null;
  getUsers: () => any;
  getLabels: () => any;
  getActis: () => Promise<any>;
  getCostCodes: () => Promise<any>;
  getAreas: () => Promise<any>;
  getRoles: () => Promise<any>;
  organisation: Organisation | null;
  labels: Labels;
  isLoading: boolean;
  canViewArea: boolean;
}

const SearchPage = ({
  areas,
  users,
  searchReport,
  search,
  getUsers,
  getLabels,
  organisation,
  currentSection,
  roles,
  actis,
  costCodes,
  labels,
  isLoading,
  getCostCodes,
  getActis,
  getAreas,
  getRoles,
  canViewArea,
}: Props) => {
  return (
    <div className="standard-container-wrapper">
      <div className="standard-container-standalone">
        <SearchForm
          actis={actis}
          areas={areas}
          canViewArea={canViewArea}
          costCodes={costCodes}
          currentSection={currentSection}
          disabled={isLoading}
          getActis={getActis}
          getAreas={getAreas}
          getCostCodes={getCostCodes}
          getLabels={getLabels}
          getRoles={getRoles}
          getUsers={getUsers}
          labels={labels}
          organisation={organisation}
          roles={roles}
          searchReport={searchReport}
          users={users}
        />

        <SearchTable
          currentSection={currentSection}
          isLoading={isLoading}
          organisation={organisation}
          search={search}
          searchReport={searchReport}
        />
      </div>
    </div>
  );
};

export default SearchPage;
