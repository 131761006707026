import { Badge } from 'react-bootstrap';
import CustomToggle from 'components/CustomToggle';
import React from 'react';
import clsx from 'clsx';
import styles from './ProjectToggle.module.scss';

export interface ProjectToggleBaseProps {
  children: React.ReactNode;
  afterBadge?: React.ReactNode;
  badgeCount?: number;
  eventKey: string;
  className?: string;
  initialOpen?: boolean;
}

export interface ProjectToggleProps extends ProjectToggleBaseProps {
  listMode: 'expanded' | 'collapsed';
}

const ProjectToggle = ({
  className,
  children,
  badgeCount,
  afterBadge,
  eventKey,
  listMode,
  initialOpen,
  ...props
}: ProjectToggleProps) => {
  return (
    <CustomToggle
      className={clsx([className, styles.levelHeading])}
      eventKey={eventKey}
      initialOpen={initialOpen}
      openState={listMode === 'expanded'}
      role="button"
      Wrapper="div"
      {...props}>
      <div className={styles.content}>
        {children}
        {badgeCount !== undefined && (
          <Badge bg="gray" className={styles.badge} pill>
            {badgeCount}
          </Badge>
        )}
      </div>
      {afterBadge}
    </CustomToggle>
  );
};

export default ProjectToggle;
