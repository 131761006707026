import { EventsPanel } from '../containers';
import React from 'react';
import ReportPage from 'pages/ReportPage';
import { useGetUsersFromEvents } from 'redux/report/reportSlice';

const EventsPage = () => {
  const authors = useGetUsersFromEvents();

  return (
    <ReportPage
      authors={authors}
      searchDatePlaceholder="Event date from/to"
      hideOnlyActionsFilter
      hideOnlyMyActivities
      showAuthorFilter
      showSearchDateFilter>
      <EventsPanel />
    </ReportPage>
  );
};

export default EventsPage;
