import { Controller, useForm } from 'react-hook-form';
import { Filters, FiltersState } from 'redux/activityHistory/types';
import React, { useMemo, useState } from 'react';

import { Button } from 'react-bootstrap';
import CheckboxGroup from 'components/CheckboxGroup/CheckboxGroup';
import { CustomRangeDatePicker } from 'components/CustomDatePicker';
import DateFormatter from '../../containers/DateFormatter';
import FieldGroup from 'components/FieldGroup';
import MultiSelect from 'components/MultiSelect/MultiSelect';
import { StandardObject } from 'containers/ActivityHistoryPage';
import { Unit } from 'types/ActivityHistory';
import clsx from 'clsx';
import styles from './ActivityHistoryFilters.module.scss';

export interface ActivityHistoryFiltersProps {
  onSubmit: (data: Filters) => void;
  statuses: StandardObject[];
  actions: StandardObject[];
  units: Unit[];
  filters: FiltersState;
  disabledSubmit: boolean;
}

const ActivityHistoryFilters = ({
  onSubmit,
  statuses,
  actions,
  units,
  filters,
  disabledSubmit,
}: ActivityHistoryFiltersProps) => {
  const { handleSubmit, control, resetField, setValue } = useForm<Filters>({
    defaultValues: {
      ...filters,
      date: {
        startDate: filters.date.startDate ? new Date(filters.date.startDate) : null,
        endDate: filters.date.endDate ? new Date(filters.date.endDate) : null,
      },
    },
  });

  const [isExpandedFilters, setIsExpandedFilters] = useState(false);

  const unitsOptions = useMemo(
    () =>
      units.map((unit) => ({
        label: unit.name,
        options: unit.users.map((user) => ({
          label: `${user.firstName} ${user.lastName}`,
          value: user.id,
        })),
      })),
    [units],
  );

  const unitsCheckboxes = useMemo(
    () => units.map((unit) => ({ label: unit.roleName, id: `${unit.id}` })),
    [units],
  );

  const localOnSubmit = (event?) => handleSubmit(onSubmit)(event);

  return (
    <div className={styles.searchBar}>
      <div className={styles.searchBarRow}>
        <Controller
          control={control}
          name="search"
          render={({ field }) => (
            <FieldGroup placeholder="Search" wrapperClassName={styles.searchInput} {...field} />
          )}
        />
        <Controller
          control={control}
          name="date"
          render={({ field: { onChange, value, ...field } }) => (
            <CustomRangeDatePicker
              endDate={value?.endDate}
              placeholderText="Date from/to"
              startDate={value?.startDate}
              wrapperClassName={styles.dateInput}
              onChange={([startDate, endDate]) => onChange({ startDate, endDate })}
              {...field}
            />
          )}
        />
        <Controller
          control={control}
          name="authors"
          render={({ field }) => (
            <MultiSelect {...field} options={unitsOptions} placeholder="Name" />
          )}
        />
        <Button className={styles.searchBtn} disabled={disabledSubmit} onClick={localOnSubmit}>
          Search
        </Button>
      </div>
      <div className={styles.searchBarRow}>
        {filters.search && (
          <Button
            variant="outline-dark"
            onClick={() => {
              resetField('search');
              localOnSubmit();
            }}>
            {filters.search}
          </Button>
        )}
        {filters.date?.startDate && filters.date?.endDate && (
          <Button
            variant="outline-dark"
            onClick={() => {
              resetField('date');
              localOnSubmit();
            }}>
            <DateFormatter date={filters.date.startDate} formatType="date" />
            {' - '}
            <DateFormatter date={filters.date.endDate} formatType="date" />
          </Button>
        )}
        {!!filters.authors?.length &&
          filters.authors.map((author) => (
            <Button
              key={author.value}
              variant="outline-dark"
              onClick={() => {
                setValue(
                  'authors',
                  filters.authors.filter(({ value }) => value !== author.value),
                );
                localOnSubmit();
              }}>
              {author.label}
            </Button>
          ))}
      </div>
      <div className={styles.searchBarRow}>
        <button
          className={clsx([styles.expandFiltersBtn, isExpandedFilters && styles.expanded])}
          onClick={() => setIsExpandedFilters((isExpandedFilters) => !isExpandedFilters)}>
          <i className="bi bi-arrow-down-circle"></i>
        </button>
        <div className={styles.searchFilters}>
          <Controller
            control={control}
            name="units"
            render={({ field }) => (
              <CheckboxGroup
                checkboxes={unitsCheckboxes}
                expanded={isExpandedFilters}
                label="Comment By"
                onChange={({ checkedList }) => field.onChange(checkedList)}
              />
            )}
          />
          <Controller
            control={control}
            name="tags"
            render={({ field }) => (
              <CheckboxGroup
                checkboxes={actions}
                expanded={isExpandedFilters}
                label="Tag"
                onChange={({ checkedList }) => field.onChange(checkedList)}
              />
            )}
          />
          <Controller
            control={control}
            name="status"
            render={({ field }) => (
              <CheckboxGroup
                checkboxes={statuses}
                expanded={isExpandedFilters}
                label="Status"
                onChange={({ checkedList }) => field.onChange(checkedList)}
              />
            )}
          />
        </div>
      </div>
    </div>
  );
};

export default ActivityHistoryFilters;
