import React, { useMemo, useRef, useState } from 'react';
import { Remark, RemarkWithProject } from 'types/Report';
import { useGetEditable, useGetFilters } from 'redux/report/reportSlice';

import ExpandedRemark from './components/ExpandedRemark';
import Table from '../../components/Table';
import createColumns from './createColumns';
import { isLessThanDay } from 'utils/timeUtils';

export type RemarksTableData = {
  id: string;
  isFromComment: boolean;
  data: Remark | RemarkWithProject;
};

interface RemarksTableProps {
  remarks: RemarksTableData[];
  title: string;
  removeRemark?: (item: RemarksTableData) => any;
  updateRemark?: (item: RemarksTableData, desc: string) => any;
  onLinkClick: (item: RemarksTableData) => any;
}

const RemarksTable = ({
  remarks,
  removeRemark,
  title,
  updateRemark,
  onLinkClick,
}: RemarksTableProps) => {
  const [editing, setEditing] = useState<string | null>(null);
  const descRef = useRef<string>('');

  const setDesc = (text) => {
    descRef.current = text;
  };

  const filters = useGetFilters();
  const editable = useGetEditable();

  const data = useMemo(() => {
    let filteredRemarks = remarks;

    //Entries from Activities only
    if (filters.onlyMyActivities) {
      filteredRemarks = filteredRemarks.filter((remark) => remark.isFromComment);
    }

    if (filters.onlyRecentUpdates) {
      filteredRemarks = filteredRemarks.filter((remark) => isLessThanDay(remark?.data.created));
    }

    if (filters?.author?.length > 0) {
      filteredRemarks = filteredRemarks.filter((remark) =>
        filters.author.includes(remark?.data.author?.id),
      );
    }

    const searchLower = filters?.search?.toLowerCase();

    if (searchLower) {
      filteredRemarks = filteredRemarks.filter((remark) =>
        remark?.data.desc?.toLowerCase().includes(searchLower),
      );
    }

    return filteredRemarks.map((remark) => ({
      ...remark.data,
      ...remark,
    }));
  }, [filters, remarks]);

  const handleSubmit = (item: RemarksTableData) => {
    updateRemark?.(item, descRef.current);

    setEditing(null);
    setDesc(null);
  };

  const handleCancel = () => {
    setEditing(null);
    setDesc(null);
  };

  const columns = createColumns({
    removeRemark,
    title,
    editing,
    setEditing: updateRemark ? setEditing : undefined,
    desc: descRef.current,
    setDesc,
    handleSubmit,
    handleCancel,
    editable,
    handleLinkClick: onLinkClick,
  });

  return <Table columns={columns} data={data} RowSubComponent={ExpandedRemark} expandable />;
};

export default RemarksTable;
