import {
  Area,
  FullOrganisation,
  Labels,
  LockedPeriod,
  ReportingUnit,
  Role,
  Unit,
  User,
} from 'types/Report';
import { checkAllPermissions, checkOneOfPermissions } from '../../utils/permissions';
import {
  createOrganisationLockedPeriod,
  deleteOrganisationLockedPeriod,
  getAllCurrentOrganisationLabels,
  getAllOrganisationLabels,
  getOrganisationAreas,
  getOrganisationLockedPeriods,
  getOrganisationUnits,
  getOrganisationUsers,
  getOrganisations,
  updateOrganisationLockedPeriod,
} from './organisationActions';
import { createSlice, isAnyOf } from '@reduxjs/toolkit';
import {
  lockedPeriodCreated,
  lockedPeriodDeleted,
  lockedPeriodUpdated,
} from 'redux/report/reportActions';

import type { PayloadAction } from '@reduxjs/toolkit';
import { SimpleReduxState } from 'redux/types';
import { UserPermission } from './types';
import sortBy from 'lodash/sortBy';
import { useAppSelector } from 'redux/hooks';

interface OrganisationState {
  organisations: FullOrganisation[];
  sections: ReportingUnit[];
  currentOrganisation: FullOrganisation | null;
  currentSection: ReportingUnit | null;
  users: User[];
  units: SimpleReduxState<Unit[]>;
  labels: Labels;
  lockedPeriods: SimpleReduxState<LockedPeriod[]>;
  areas: SimpleReduxState<Area[]>;
}

const initialState: OrganisationState = {
  organisations: [],
  sections: [],
  currentOrganisation: null,
  currentSection: null,
  users: [],
  units: {
    data: [],
    isLoading: false,
    hasError: false,
  },
  areas: {
    data: [],
    isLoading: false,
    hasError: false,
  },
  lockedPeriods: {
    data: [],
    isLoading: false,
    hasError: false,
  },
  labels: [],
};

export const organisationSlice = createSlice({
  name: 'organisation',
  initialState,
  reducers: {
    setSelectedOrganisation: (state, action) => {
      const currentOrganisation = state.organisations.find(
        ({ id }) => id === parseInt(action.payload, 10),
      );
      if (currentOrganisation) {
        state.currentOrganisation = currentOrganisation;
        const currentSection = currentOrganisation?.reportingUnits?.[0];
        if (currentSection) {
          state.currentSection = { ...currentSection };
        }
      }
    },
    setSelectedSection: (state, action) => {
      const currentSection = state.sections.find(({ id }) => id === parseInt(action.payload, 10));
      if (currentSection) {
        state.currentSection = currentSection;
      } else {
        state.currentSection = null;
      }
    },
  },
  extraReducers: (builder) => {
    builder.addCase(getOrganisations.fulfilled, (state, action) => {
      state.organisations = sortBy(action.payload, (o) => o.name);

      const reportingLevels = action.payload
        .filter((organisation) => organisation.reportingUnits)
        .flatMap((organisation) =>
          organisation.reportingUnits!.map((reportingUnit) => ({
            ...reportingUnit,
            organisation,
          })),
        );

      state.sections = sortBy(reportingLevels, (o) => o.name);
    });
    builder.addCase(getOrganisationUsers.fulfilled, (state, action) => {
      state.users = action.payload;
    });
    builder.addCase(getAllCurrentOrganisationLabels.fulfilled, (state, action) => {
      state.labels = action.payload;
    });
    builder.addCase(getAllOrganisationLabels.fulfilled, (state, action) => {
      state.labels = action.payload;
    });
    builder.addCase(
      getOrganisationUnits.fulfilled,
      ({ units: state }, action: PayloadAction<Unit[]>) => {
        state.data = action.payload;
        state.isLoading = false;
        state.hasError = false;
      },
    );
    builder.addCase(getOrganisationUnits.pending, ({ units: state }) => {
      state.isLoading = true;
      state.hasError = false;
    });
    builder.addCase(getOrganisationUnits.rejected, ({ units: state }) => {
      state.data = [];
      state.isLoading = false;
      state.hasError = true;
    });
    builder.addCase(
      getOrganisationAreas.fulfilled,
      ({ areas: state }, action: PayloadAction<Area[]>) => {
        state.data = action.payload;
        state.isLoading = false;
        state.hasError = false;
      },
    );
    builder.addCase(getOrganisationAreas.pending, ({ areas: state }) => {
      state.isLoading = true;
      state.hasError = false;
    });
    builder.addCase(getOrganisationAreas.rejected, ({ areas: state }) => {
      state.data = [];
      state.isLoading = false;
      state.hasError = true;
    });
    builder.addCase(
      getOrganisationLockedPeriods.fulfilled,
      ({ lockedPeriods: state }, action: PayloadAction<LockedPeriod[]>) => {
        state.data = action.payload;
        state.isLoading = false;
        state.hasError = false;
      },
    );
    builder.addCase(getOrganisationLockedPeriods.pending, ({ lockedPeriods: state }) => {
      state.data = [];
      state.isLoading = true;
      state.hasError = false;
    });
    builder.addCase(getOrganisationLockedPeriods.rejected, ({ lockedPeriods: state }) => {
      state.data = [];
      state.isLoading = false;
      state.hasError = true;
    });
    builder.addMatcher(
      isAnyOf(deleteOrganisationLockedPeriod.fulfilled, lockedPeriodDeleted),
      ({ lockedPeriods: state }, action) => {
        const { id } = action.payload;
        state.data = state.data.filter((lp) => lp.id !== id);
      },
    );
    builder.addMatcher(
      isAnyOf(createOrganisationLockedPeriod.fulfilled, lockedPeriodCreated),
      ({ lockedPeriods: state }, action: PayloadAction<LockedPeriod>) => {
        state.data = [...state.data, action.payload];
      },
    );
    builder.addMatcher(
      isAnyOf(updateOrganisationLockedPeriod.fulfilled, lockedPeriodUpdated),
      ({ lockedPeriods: state }, action: PayloadAction<LockedPeriod>) => {
        const { id } = action.payload;
        state.data = state.data.map((lp) => (lp.id === id ? action.payload : lp));
      },
    );
  },
});

export const useGetOrganisationAreas = (): Area[] =>
  useAppSelector((state) => state.organisation.areas.data);

export const useIsOrganisationAreasLoading = (): boolean =>
  useAppSelector((state) => state.organisation.areas.isLoading);

export const useGetOrganisationLockedPeriods = (): LockedPeriod[] =>
  useAppSelector((state) => state.organisation.lockedPeriods.data);

export const useIsOrganisationLockedPeriodsLoading = (): boolean =>
  useAppSelector((state) => state.organisation.lockedPeriods.isLoading);

export const useGetOrganisations = (): FullOrganisation[] =>
  useAppSelector((state) => state.organisation.organisations);

export const useGetSections = (): Unit[] => useAppSelector((state) => state.organisation.sections);

export const useGetCurrentOrganisation = (): FullOrganisation | null =>
  useAppSelector((state) => state.organisation.currentOrganisation);

export const useGetCurrentSection = (): ReportingUnit | null =>
  useAppSelector((state) => state.organisation.currentSection);

export const useGetOrganisationUsers = (): User[] =>
  useAppSelector((state) => state.organisation.users);

export const useGetCurrentOrganisationUnits = (): Unit[] =>
  useAppSelector((state) => state.organisation.units.data);

export const useIsOrganisationUnitsLoading = (): boolean =>
  useAppSelector((state) => state.organisation.units.isLoading);

export const useGetCurrentOrganisationLabels = (): Labels =>
  useAppSelector((state) => state.organisation.labels);

export const useGetCurrentOrganisationRoles = (): Role[] => {
  const units = useAppSelector((state) => state.organisation.units.data);

  return units.map((unit) => ({
    id: unit.id,
    name: unit.roleName,
  }));
};

export const { setSelectedOrganisation, setSelectedSection } = organisationSlice.actions;

export const useGetTrueCurrentOrganisation = (): FullOrganisation | undefined =>
  useAppSelector((state) => {
    const currentOrganisationId = state.organisation.currentOrganisation?.id;

    return state.organisation.organisations.find(
      (organisation) => organisation.id == currentOrganisationId,
    );
  });

export const useGetAllPermissions = (): UserPermission[] => {
  const section = useGetCurrentSection();
  const keys = Object.keys(UserPermission);

  return (
    section?.permissions?.map((value) => {
      const key = keys.find((key) => UserPermission[key] === value) || UserPermission.UNKNOWN;

      return UserPermission[key];
    }) ?? []
  );
};

export const useCheckAllPermissions = (...permissions: UserPermission[]): boolean => {
  const section = useGetCurrentSection();
  const userPermissions = section?.permissions ?? [];
  if (userPermissions.length === 0) {
    return false;
  }

  return checkAllPermissions(userPermissions, permissions);
};

export const useCheckOneOfPermissions = (...permissions: UserPermission[]): boolean => {
  const section = useGetCurrentSection();
  const userPermissions = section?.permissions ?? [];
  if (userPermissions.length === 0) {
    return false;
  }

  return checkOneOfPermissions(userPermissions, permissions);
};

export const organisationReducer = organisationSlice.reducer;
