export const handleKeyDown = (event, { onEscape, onEnter }) => {
  switch (event.key) {
    case 'Escape':
      return onEscape();
    case 'Enter':
      return onEnter();
    default:
      return;
  }
};
