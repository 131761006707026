import { Button, Form, Modal } from 'react-bootstrap';
import { CustomRangeDatePicker, RangeDate } from 'components/CustomDatePicker';
import { Filters, VisibilityFilter } from 'redux/report/types';
import MultiSelect, { MultiSelectOption } from 'components/MultiSelect/MultiSelect';
import React, { useCallback, useEffect, useState } from 'react';

import { CreateNewActivityPage } from 'containers';
import type { PayloadAction } from '@reduxjs/toolkit';
import SprintSelector from './SprintSelector';
import styles from './MenuBar.module.scss';
import { useDebounce } from 'use-debounce';

export interface MenuBarBaseProps {
  showActivityButtons?: boolean;
  showVisibilityFilter?: boolean;
  showBasicFilters?: boolean;
  showAuthorFilter?: boolean;
  showSearchDateFilter?: boolean;
  hideOnlyActionsFilter?: boolean;
}

interface Props extends MenuBarBaseProps {
  setPrevSprint: () => void;
  setNextSprint: () => void;
  filters: Filters;
  sprint: number | null;
  year: number | null;
  toggleOnlyMyActivities: () => PayloadAction;
  toggleOnlyRecentUpdates: () => PayloadAction;
  toggleOnlyActions: () => PayloadAction;
  setVisibilityFilter: (eventKey: VisibilityFilter) => void;
  changeListMode: (mode: 'collapsed' | 'expanded') => void;
  setSearchFilter: (search: string) => void;
  setAuthorFilter: (author: number[]) => void;
  setSearchDateFilter: (dates: RangeDate) => void;
  listMode: 'collapsed' | 'expanded';
  hasPrevious: boolean | null;
  userOptions: MultiSelectOption[];
  searchDatePlaceholder?: string;
  canCreateNewActivity: boolean;
  spritStatus: string;
  onlyMyActivitiesLabel?: string;
  hideOnlyActionsFilter?: boolean;
  hideOnlyMyActivities?: boolean;
}

const MenuBar = ({
  filters,
  toggleOnlyRecentUpdates,
  toggleOnlyMyActivities,
  toggleOnlyActions,
  sprint,
  year,
  setPrevSprint,
  setNextSprint,
  setSearchFilter,
  setAuthorFilter,
  setSearchDateFilter,
  setVisibilityFilter,
  changeListMode,
  showBasicFilters = true,
  showActivityButtons = false,
  showVisibilityFilter = false,
  showAuthorFilter = false,
  showSearchDateFilter = false,
  hideOnlyActionsFilter = false,
  hideOnlyMyActivities = false,
  listMode,
  hasPrevious,
  userOptions,
  searchDatePlaceholder,
  canCreateNewActivity,
  spritStatus,
  onlyMyActivitiesLabel = 'Entries from Activities only',
}: Props) => {
  const [showCreateForm, setShowCreateForm] = useState(false);
  const [search, setSearch] = useState(filters?.search || '');
  const [searchValue] = useDebounce(search, 200);

  useEffect(() => {
    setSearchFilter(searchValue);
    // Prevent call changeListMode when MenuBar is recreated
    if (searchValue !== search) {
      changeListMode('expanded');
    }
  }, [searchValue]);

  const handleSelectedUsers = (selected: MultiSelectOption[]): void => {
    setAuthorFilter(selected.map((item) => item.value as number));
  };

  const selectedUsers: MultiSelectOption[] = userOptions.filter((usr) =>
    filters?.author?.includes(usr.value as number),
  );

  const handleHideEmpty = useCallback(() => {
    setVisibilityFilter(VisibilityFilter.HIDE_EMPTY);
    changeListMode('expanded');
  }, [setVisibilityFilter, changeListMode]);

  return (
    <div className={styles.menuBar}>
      <SprintSelector
        hasPrevious={hasPrevious}
        label={spritStatus}
        setNextSprint={setNextSprint}
        setPrevSprint={setPrevSprint}
        sprint={sprint}
        year={year}
      />

      {showAuthorFilter && (
        <div className={styles.menuBarNameSearch}>
          <MultiSelect
            options={userOptions}
            placeholder="Name"
            value={selectedUsers}
            onChange={(event) => handleSelectedUsers(event as MultiSelectOption[])}
          />
        </div>
      )}

      {showBasicFilters && (
        <Form.Control
          className={styles.menuBarSearch}
          data-testid="filterSearch"
          placeholder="Search"
          type="text"
          value={search}
          onChange={(event) => setSearch(event.target.value)}
        />
      )}

      {showSearchDateFilter && (
        <CustomRangeDatePicker
          className={styles.menuBarSearchDate}
          endDate={filters.dates?.endDate}
          placeholderText={searchDatePlaceholder ?? 'Date from/to'}
          portalId="dateSearch"
          startDate={filters.dates?.startDate}
          wrapperClassName={styles.menuBarSearchDateWrapper}
          onChange={([startDate, endDate]) => setSearchDateFilter({ startDate, endDate })}
        />
      )}

      {showBasicFilters && (
        <>
          <Form className={styles.menuBarShowOnly}>
            <Form.Check
              checked={filters.onlyRecentUpdates}
              className={styles.menuBarCheckbox}
              id="onlyRecentUpdates"
              label="Show only recent updates"
              type="checkbox"
              onChange={toggleOnlyRecentUpdates}
            />
            {!hideOnlyMyActivities && (
              <Form.Check
                checked={filters.onlyMyActivities}
                className={styles.menuBarCheckbox}
                id="onlyMyActivities"
                label={onlyMyActivitiesLabel}
                type="checkbox"
                onChange={toggleOnlyMyActivities}
              />
            )}
            {!hideOnlyActionsFilter && (
              <Form.Check
                checked={filters.onlyActions}
                className={styles.menuBarCheckbox}
                id="onlyActions"
                label="Show only actions"
                type="checkbox"
                onChange={toggleOnlyActions}
              />
            )}
          </Form>
        </>
      )}

      {showVisibilityFilter && (
        <div className={styles.menuBarVisibilityFilterWrapper}>
          Visibility filter:
          <Form.Group className={styles.menuBarVisibilityFilter} id="visibilityFilter">
            <Form.Check
              checked={filters.visibilityFilter === VisibilityFilter.SHOW_ALL}
              className={styles.menuBarRadio}
              id="showAll"
              label="Show all"
              type="radio"
              value={VisibilityFilter.SHOW_ALL}
              inline
              onChange={() => setVisibilityFilter(VisibilityFilter.SHOW_ALL)}
            />
            <Form.Check
              checked={filters.visibilityFilter === VisibilityFilter.HIDE_EMPTY}
              className={styles.menuBarRadio}
              id="hideEmpty"
              label="Hide empty"
              type="radio"
              value={VisibilityFilter.HIDE_EMPTY}
              inline
              onChange={() => handleHideEmpty()}
            />
            <Form.Check
              checked={filters.visibilityFilter === VisibilityFilter.SHOW_ONLY_EMPTY}
              className={styles.menuBarRadio}
              id="showOnlyEmpty"
              label="Show only empty"
              type="radio"
              value={VisibilityFilter.SHOW_ONLY_EMPTY}
              inline
              onChange={() => setVisibilityFilter(VisibilityFilter.SHOW_ONLY_EMPTY)}
            />
          </Form.Group>
        </div>
      )}
      {showActivityButtons && (
        <>
          <div className={styles.menuBarShowListWrapper}>
            Show list:
            <Form.Group className={styles.menuBarShowList}>
              <Form.Check
                checked={'collapsed' === listMode}
                className={styles.menuBarRadio}
                id="collapse"
                label="collapsed"
                type="radio"
                value="collapse"
                inline
                onChange={() => changeListMode('collapsed')}
              />
              <Form.Check
                checked={'expanded' === listMode}
                className={styles.menuBarRadio}
                id="expanded"
                label="expanded"
                type="radio"
                value="expanded"
                inline
                onChange={() => changeListMode('expanded')}
              />
            </Form.Group>
          </div>
          {canCreateNewActivity && (
            <div className={styles.menuBarButtons}>
              <Button onClick={() => setShowCreateForm(true)}>Add new activity</Button>
            </div>
          )}
          <Modal show={showCreateForm} size="lg" centered onHide={() => setShowCreateForm(false)}>
            <CreateNewActivityPage onClose={() => setShowCreateForm(false)} />
          </Modal>
        </>
      )}
    </div>
  );
};

export default MenuBar;
