import { Badge, Form } from 'react-bootstrap';
import React, { useMemo, useState } from 'react';

import styles from './CheckboxGroup.module.scss';

interface CheckboxProps {
  label: string;
  id: string;
}

interface CheckboxGroupProps {
  checkboxes: Array<CheckboxProps>;
  expanded?: boolean;
  label?: string;
  onChange?: (data: { checked: boolean; checkedList: string[]; data: CheckboxProps }) => void;
}

const CheckboxGroup = ({ checkboxes, label, expanded = true, onChange }: CheckboxGroupProps) => {
  const [checkedList, setCheckedList] = useState<Record<string, boolean>>({});
  const checkedCount = useMemo(
    () => Object.values(checkedList).reduce((acc, item) => (item ? acc + 1 : acc), 0),
    [checkedList],
  );

  return (
    <Form.Group>
      {label && (
        <Form.Label className={styles.checkboxGroupLabel}>
          {label}
          <Badge className={styles.checkboxGroupBadge} pill>
            {checkedCount}
          </Badge>
        </Form.Label>
      )}
      {expanded && (
        <div className={styles.checkboxGroupList}>
          {checkboxes.map((checkboxData) => (
            <Form.Check
              checked={checkedList[checkboxData.id]}
              id={checkboxData.id}
              key={checkboxData.id}
              label={checkboxData.label}
              type="checkbox"
              onChange={(event) => {
                const checked = (event as any as React.ChangeEvent<HTMLInputElement>).target
                  .checked;
                const newCheckedList = { ...checkedList, [checkboxData.id]: checked };
                setCheckedList(newCheckedList);
                onChange?.({
                  checked,
                  checkedList: Object.keys(newCheckedList).filter((key) => newCheckedList[key]),
                  data: checkboxData,
                });
              }}
            />
          ))}
        </div>
      )}
    </Form.Group>
  );
};

export default CheckboxGroup;
