import React, { useState } from 'react';

import CharacterCounter from 'components/CharacterCounter';
import { Form } from 'react-bootstrap';

interface StatefulTextareaProps {
  desc?: string;
  setDesc?: (value: string) => void;
  testId?: string;
  maxLength?: number;
}

export const StatefulTextarea = ({
  desc = '',
  setDesc,
  testId = 'remarkDescriptionInput',
  maxLength,
}: StatefulTextareaProps) => {
  const [valueDesc, setValueDesc] = useState(desc);

  return (
    <CharacterCounter maxLength={maxLength} value={valueDesc}>
      <Form.Control
        as="textarea"
        data-testid={testId}
        value={valueDesc}
        autoFocus
        onChange={(event) => {
          setDesc?.(event.target.value);
          setValueDesc(event.target.value);
        }}
      />
    </CharacterCounter>
  );
};
