/* eslint-disable max-lines */
import * as Yup from 'yup';

import { Comment, Labels, Tags } from '../../types/Report';
import { Controller, useForm } from 'react-hook-form';
import { Form, ToggleButton } from 'react-bootstrap';
import React, { FC } from 'react';

import CreatableLabel from 'containers/CreatableLabel';
import Status from 'components/Status';
import WideInput from 'components/WideInput';
import clsx from 'clsx';
import { commentFormSchema } from 'schemas/CommentForm';
import { stringToStatus } from 'utils/status';
import styles from './EditComment.module.scss';
import { yupResolver } from '@hookform/resolvers/yup';

const ChoiceLabel = ({ status, label }) => (
  <div className={clsx('d-flex gap-1 align-items-center', styles.choiceLabel)}>
    <Status level={status} />
    {label}
  </div>
);

export interface EditCommentBaseProps {
  uniqueId?: string | number;
}

interface EditComment extends EditCommentBaseProps {
  comment?: Comment;
  onSubmit: (data: { content: string; tags: Tags; status: number; labels: Labels }) => any; // TODO: Define type
  limitText: boolean;
}
const EditComment: FC<EditComment> = ({
  onSubmit,
  comment,
  limitText,
  uniqueId = Math.random(),
}) => {
  const { handleSubmit, control, reset } = useForm({
    defaultValues: {
      action: comment?.tags?.action || false,
      decision: comment?.tags?.decision || false,
      needToKnow: comment?.tags?.needToKnow || false,
      content: comment?.content || '',
      status: comment?.status !== undefined ? parseInt(comment?.status, 10) : 1,
      labels: comment?.labels ?? [],
    },
    resolver: yupResolver<Yup.AnyObjectSchema>(commentFormSchema),
  });

  return (
    <>
      <WideInput
        control={control}
        maxLength={limitText ? 2000 : undefined}
        name="content"
        onSubmit={handleSubmit((event) =>
          onSubmit({
            content: event.content,
            status: event.status,
            tags: { action: event.action, decision: event.decision, needToKnow: event.needToKnow },
            labels: event.labels,
          }).then(() => reset()),
        )}
      />
      <Form.Group className="mb-3">
        <Controller
          control={control}
          name="status"
          render={({ field }) => {
            const status = stringToStatus['green'];

            return (
              <Form.Check
                checked={status === field.value}
                className={styles.formCheck}
                id={`inline-with-schedule-${uniqueId}`}
                label={<ChoiceLabel label={'In line with schedule'} status={status} />}
                type="radio"
                value={status}
                inline
                onChange={() => field.onChange(status)}
              />
            );
          }}
        />
        <Controller
          control={control}
          name="status"
          render={({ field }) => {
            const status = stringToStatus['yellow'];

            return (
              <Form.Check
                checked={status === field.value}
                className={styles.formCheck}
                id={`potential-impact-on-schedule-${uniqueId}`}
                label={
                  <ChoiceLabel
                    label={'Potential impact on schedule and costs / issues experienced'}
                    status={status}
                  />
                }
                type="radio"
                value={status}
                inline
                onChange={() => field.onChange(status)}
              />
            );
          }}
        />
        <Controller
          control={control}
          name="status"
          render={({ field }) => {
            const status = stringToStatus['red'];

            return (
              <Form.Check
                checked={status === field.value}
                className={styles.formCheck}
                id={`confirmed-impact-${uniqueId}`}
                label={
                  <ChoiceLabel
                    label={'Confirmed/highly probable impact on schedule and costs'}
                    status={status}
                  />
                }
                type="radio"
                value={status}
                inline
                onChange={() => field.onChange(status)}
              />
            );
          }}
        />
        <Controller
          control={control}
          name="status"
          render={({ field }) => {
            const status = stringToStatus['gray'];

            return (
              <Form.Check
                checked={status === field.value}
                className={styles.formCheck}
                id={`no-status-set-${uniqueId}`}
                label={<ChoiceLabel label={'No status set'} status={status} />}
                type="radio"
                value={status}
                inline
                onChange={() => field.onChange(status)}
              />
            );
          }}
        />
      </Form.Group>
      <Form.Group className="d-flex gap-1">
        <Controller
          control={control}
          name="action"
          render={({ field }) => (
            <ToggleButton
              checked={field.value}
              className={styles.toggleButton}
              id={`action-request-${uniqueId}`}
              type="checkbox"
              value="action-request"
              variant="outline-dark"
              onClick={() => field.onChange(!field.value)}>
              Action request
            </ToggleButton>
          )}
        />
        <Controller
          control={control}
          name="decision"
          render={({ field }) => (
            <ToggleButton
              checked={field.value}
              className={styles.toggleButton}
              id={`decision-request-${uniqueId}`}
              type="checkbox"
              value="decision-request"
              variant="outline-dark"
              onClick={() => field.onChange(!field.value)}>
              Decision request
            </ToggleButton>
          )}
        />
        <Controller
          control={control}
          name="needToKnow"
          render={({ field }) => (
            <ToggleButton
              checked={field.value}
              className={styles.toggleButton}
              id={`need-to-know-${uniqueId}`}
              type="checkbox"
              value="need-to-know"
              variant="outline-dark"
              onClick={() => field.onChange(!field.value)}>
              Need to know
            </ToggleButton>
          )}
        />
      </Form.Group>
      <Form.Group className="my-3">
        <Controller
          control={control}
          name="labels"
          render={({ field: { value, onChange } }) => (
            <CreatableLabel
              customStyles={styles.selectLabel}
              primaryColor="#a6fbf2"
              value={value}
              onChange={onChange}
            />
          )}
        />
      </Form.Group>
    </>
  );
};

export default EditComment;
