import { API_URL, GIT_COMMIT_SHA } from '../../redux/const';

import { Buffer } from 'buffer';

export const createEncodedEnv = (report) => {
  let envDesc = '';
  if (report && report.ownname) {
    envDesc += report.ownname + '; ';
  }

  envDesc += GIT_COMMIT_SHA + '; ';
  envDesc += window.location.hostname + '; ';

  envDesc += API_URL + '; ';
  envDesc += navigator.userAgent;

  return Buffer.from(envDesc).toString('base64');
};
