import { ACTIVITY_HISTORY_QUERY, ACTIVITY_QUERY, ACTIVITY_UNITS_QUERY } from './const';
import { ActivityHistory, ActivityHistoryFetch, Units } from 'types/ActivityHistory';

import { Project } from 'types/Report';
import { ReportAxiosRequestConfig } from 'types/Axios';
import apiCall from '../apiCall';
import { createAsyncThunk } from '@reduxjs/toolkit';
import { decamelize } from 'humps';
import format from 'date-fns/format';
import { stringToStatus } from 'utils/status';
import { validateResponse } from '../../utils/validateResponse';

export const getActivityHistory = createAsyncThunk(
  'activityHistory/activityHistory',
  async ({ id, filters }: ActivityHistoryFetch, { signal, getState }) => {
    const { year, sprint } = getState().report;
    const response = await apiCall.get(ACTIVITY_HISTORY_QUERY(id, year, sprint), {
      signal,
      withOrganisation: true,
      withSection: true,
      params: {
        createdAfter:
          filters.date.startDate && format(new Date(filters.date.startDate), 'yyyy-MM-dd'),
        createdBefore: filters.date.endDate && format(new Date(filters.date.endDate), 'yyyy-MM-dd'),
        content: filters.search,
        unit: filters.units?.join(','),
        author: filters.authors.map((author) => author.value).join(','),
        status: filters.status?.map((status) => stringToStatus[status]).join(','),
        tags: filters.tags?.map((tag) => decamelize(tag)).join(','),
      },
    } as ReportAxiosRequestConfig);

    return validateResponse(ActivityHistory, response.data);
  },
);

export const getActivity = createAsyncThunk(
  'activityHistory/activity',
  async (id: number, { signal, getState }) => {
    const { year, sprint } = getState().report;
    const response = await apiCall.get(ACTIVITY_QUERY(id, year, sprint), {
      signal,
      withOrganisation: true,
      withSection: true,
    } as ReportAxiosRequestConfig);

    return validateResponse(Project, response.data);
  },
);

export const getActivityUnits = createAsyncThunk(
  'activityHistory/activityUnits',
  async (id: number, { signal, getState }) => {
    const { year, sprint } = getState().report;
    const response = await apiCall.get(ACTIVITY_UNITS_QUERY(id, year, sprint), {
      signal,
      withOrganisation: true,
      withSection: true,
    } as ReportAxiosRequestConfig);

    return validateResponse(Units, response.data);
  },
);
