import React, { useRef, useState } from 'react';

import { LockedPeriod } from 'types/Report';
import { SHORT_DATE_FORMAT } from '../../constants';
import Table from '../Table';
import createColumns from './createColumns';
import formatFns from 'date-fns/format';
import { formatRangeDate } from 'utils/timeUtils';

type Props = {
  lockedPeriods: LockedPeriod[];
  removeLockedPeriod?: (id: number) => any;
  updateLockedPeriod?: (
    id: number,
    desc: string,
    startDate: string,
    endDate: string,
    unit: number,
  ) => any;
  options: { label: string; value: any }[];
};

const LockedPeriodsTable = ({
  lockedPeriods,
  removeLockedPeriod,
  updateLockedPeriod,
  options,
}: Props) => {
  const [desc, setDesc] = useState<string | null>(null);
  const [editing, setEditing] = useState<number | null>(null);
  const rangeDateRef = useRef<(Date | null)[]>([null, null]);
  const [selectedOption, setSelectedOption] = useState<number>(0);

  const setRangeDate = (value) => {
    rangeDateRef.current = value;
  };

  const handleCancel = () => {
    setRangeDate([null, null]);
    setDesc(null);
    setEditing(null);
  };

  const handleEdit = (id, startDate, endDate, desc, unit) => {
    setRangeDate([new Date(startDate), new Date(endDate)]);
    setDesc(desc);
    setEditing(id);
    setSelectedOption(unit);
  };

  const handleSubmit = () => {
    if (editing && rangeDateRef.current[0] && rangeDateRef.current[1] && desc) {
      updateLockedPeriod?.(
        editing,
        desc,
        formatFns(rangeDateRef.current[0], SHORT_DATE_FORMAT),
        formatFns(rangeDateRef.current[1], SHORT_DATE_FORMAT),
        selectedOption,
      );
      setRangeDate([null, null]);
      setDesc(null);
      setEditing(null);
    }
  };

  const handleSetOption = (selected: number) => {
    setSelectedOption(selected);
  };

  const columns = createColumns({
    removeLockedPeriod,
    editing,
    handleCancel,
    handleEdit: updateLockedPeriod ? handleEdit : undefined,
    handleSubmit,
    setDesc,
    desc,
    rangeDate: rangeDateRef.current,
    setRangeDate,
    options,
    setOption: handleSetOption,
    selectedOption,
  });

  return (
    <Table
      columns={columns}
      data={lockedPeriods.map((item) => ({
        ...item,
        date: formatRangeDate(item.startDate, item.endDate) || null,
        originalStartDate: item.startDate,
        originalEndDate: item.endDate,
      }))}
    />
  );
};

export default LockedPeriodsTable;
