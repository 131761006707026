import React, { FunctionComponent } from 'react';

import SidebarButton from './SidebarButton';
import styles from './SidebarSection.module.scss';

interface Props {
  title: string;
  buttons: Array<{
    Icon: FunctionComponent;
    label: string;
    disabled: boolean;
    path?: string;
  }>;
}

const SidebarSection = ({ title, buttons }: Props) => {
  return (
    <div className={styles.sidebarSection}>
      <div className={styles.sidebarSectionTitle}>{title}</div>
      {buttons.map((button) => (
        <SidebarButton key={button.label} {...button} permissions={[]} />
      ))}
    </div>
  );
};

export default SidebarSection;
