import type { AxiosInstance } from 'axios';
import { ReportAxiosRequestConfig } from '../types/Axios';
import store from '../redux/store';
export default (axiosInstance: AxiosInstance) => {
  axiosInstance.interceptors.request.use((config: ReportAxiosRequestConfig) => {
    const { currentOrganisation, currentSection } = store.getState?.()?.organisation;
    const { withOrganisation, withSection } = config;

    let url = '';

    if (withOrganisation) {
      url = `organisations/${currentOrganisation?.id}`;
    }

    if (withSection) {
      url = `${url}/units/${currentSection?.id}`;
    }

    if (url) {
      config.url = `reptool/${url}/${config.url}`;
    }

    return config;
  });
};
