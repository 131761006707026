import { HourFormat, IdeaEmailFrequency } from 'utils/createDateFormat';
import { fetchConfig, updateConfig } from './userConfigActions';

import { USER_CONFIG } from './const';
import { createSlice } from '@reduxjs/toolkit';
import { logout } from '../session/sessionSlice';
import { useAppSelector } from 'redux/hooks';

export interface UserConfigState {
  dateFormat: string | null;
  hourFormat: HourFormat;
  frequencyIdeasSummaryMail: IdeaEmailFrequency;
}

const initialState: UserConfigState = {
  dateFormat: null,
  hourFormat: null,
  frequencyIdeasSummaryMail: null,
};

export const userConfigSlice = createSlice({
  name: USER_CONFIG,
  initialState,
  reducers: {
    removeConfig: (state) => {
      state.dateFormat = null;
    },
  },
  extraReducers: (builder) => {
    builder.addCase(fetchConfig.fulfilled, (state, action) => {
      state.dateFormat = action.payload.dateFormat;
      state.hourFormat = action.payload.hourFormat as HourFormat;
      state.frequencyIdeasSummaryMail = action.payload
        .frequencyIdeasSummaryMail as IdeaEmailFrequency;
    });
    builder.addCase(updateConfig.fulfilled, (state, action) => {
      state.dateFormat = action.payload.dateFormat;
      state.hourFormat = action.payload.hourFormat as HourFormat;
      state.frequencyIdeasSummaryMail = action.payload
        .frequencyIdeasSummaryMail as IdeaEmailFrequency;
    });
    builder.addCase(logout, (state) => {
      state.dateFormat = null;
      state.hourFormat = null;
      state.frequencyIdeasSummaryMail = null;
    });
  },
});

export const useGetUserConfig = () => useAppSelector((state) => state.userConfig);

export const { removeConfig } = userConfigSlice.actions;

export const userConfigReducer = userConfigSlice.reducer;
