import {
  Area,
  CostCodeList,
  FullActisList,
  Labels,
  Organisation,
  Role,
  Unit,
  User,
} from 'types/Report';
import { Controller, useForm } from 'react-hook-form';
import React, { useEffect, useMemo, useState } from 'react';

import { Button } from 'react-bootstrap';
import CustomRangePicker from 'components/CustomDatePicker';
import FieldGroup from 'components/FieldGroup';
import LoadingSelect from 'components/LoadingSelect';
import MultiSelect from 'components/MultiSelect/MultiSelect';
import { SHORT_DATE_FORMAT } from '../../constants';
import SearchFormFilterSection from './SearchFormFilterSection';
import { SearchReportParams } from 'redux/report/types';
import clsx from 'clsx';
import createSections from './createSections';
import formatFns from 'date-fns/format';
import { sortBy } from 'lodash';
import styles from './SearchPage.module.scss';

interface Props {
  areas: Area[];
  users: User[];
  labels: Labels;
  actis: FullActisList;
  costCodes: CostCodeList;
  searchReport: (params: SearchReportParams) => any;
  getUsers: () => any;
  getLabels: () => any;
  getActis: () => Promise<any>;
  getCostCodes: () => Promise<any>;
  getAreas: () => Promise<any>;
  getRoles: () => Promise<any>;
  organisation: Organisation | null;
  currentSection: Unit | null;
  roles: Role[];
  disabled: boolean;
  canViewArea: boolean;
}

const getSectionValues = (section) =>
  Object.keys(section)
    .filter((o) => section[o])
    .join(',');

const SearchForm = ({
  areas,
  users,
  searchReport,
  getUsers,
  getLabels,
  organisation,
  currentSection,
  roles,
  actis,
  labels,
  costCodes,
  disabled,
  getRoles,
  getCostCodes,
  getActis,
  getAreas,
  canViewArea,
}: Props) => {
  const [expanded, setExpanded] = useState(true);
  const [sectionStates, setSectionStates] = useState({
    ['type']: {},
    ['tag']: {},
    ['status']: {},
    ['role']: {},
  });

  const { handleSubmit, control, getValues, watch, setValue } = useForm();

  const handleOptionToggle = (section, option) => {
    setSectionStates((prev) => ({
      ...prev,
      [section]: {
        ...prev[section],
        [option]: !prev[section][option],
      },
    }));
  };

  const authorOptions = useMemo(
    () => users?.map((user) => ({ label: `${user.firstName} ${user.lastName}`, value: user.id })),
    [users],
  );

  const rolesOptions = useMemo(
    () => roles?.map((role) => ({ label: role.name, value: role.id })),
    [roles],
  );

  const labelOptions = useMemo(
    () => labels?.map((label) => ({ value: label.id, label: label.name })),
    [labels],
  );

  const sections = useMemo(() => createSections(), []);

  const areaOptions = useMemo(
    () => areas?.map((area) => ({ value: area.id, label: area.name })),
    [areas],
  );

  const actisOptions = useMemo(
    () => actis?.map((item) => ({ value: item.id, label: item.actisId })),
    [actis],
  );

  const costCodesOptions = useMemo(
    () => costCodes?.map((costCode) => ({ value: costCode.id, label: costCode.name })),
    [costCodes],
  );

  const subareaOptions = useMemo(() => {
    const selectedAreas = getValues('area')?.map((area) => area.value);
    const areasMap = selectedAreas?.flatMap((id) => areas.find((area) => area.id === id));
    const options = areasMap?.map((area) => ({
      label: area.name,
      options: sortBy(
        area.subareas?.map((subarea) => ({
          label: subarea.name,
          value: subarea.id,
          area: area.id,
        })),
        'label',
      ),
    }));

    return options;
  }, [areas, watch('area')]);

  useEffect(() => {
    const selectedAreas = getValues('area')?.map((area) => area.value);
    const selectedSubAreas = getValues('subarea');
    const newSubareas = selectedSubAreas?.filter((sa) =>
      selectedAreas.some((area) => area === sa.area),
    );

    setValue('subarea', newSubareas);
  }, [watch('area')]);

  useEffect(() => {
    if (!organisation || !currentSection) {
      return;
    }
    getUsers();
    getLabels();
    getRoles();
  }, [organisation?.id, currentSection?.id]);

  const handleSearch = (data) => {
    const { search, date } = data;
    const area = data.area?.map((a) => a.value).join(',');
    const subarea = data.subarea?.map((a) => a.value).join(',');
    const tags = getSectionValues(sectionStates.tag);
    const actis = data.actis?.map((o) => o.value).join(',');
    const costCode = data.costCode?.map((o) => o.value).join(',');
    const types = getSectionValues(sectionStates.type);
    const status = getSectionValues(sectionStates.status);
    const roles = data.role?.map((label) => label.label).join(',');
    const createdAfter = date?.startDate && formatFns(date.startDate, SHORT_DATE_FORMAT);
    const createdBefore = date?.endDate && formatFns(date.endDate, SHORT_DATE_FORMAT);
    const labels = data.label?.map((label) => label.value).join(',');

    searchReport({
      search,
      area,
      subarea,
      types,
      tags,
      labels,
      status,
      roles,
      actis,
      costCode,
      createdAfter,
      createdBefore,
    });
  };

  return (
    <div className={styles.searchPageForm}>
      <div className={styles.searchPageFormTitle}>ADVANCED SEARCH</div>
      <div className={styles.searchPageFormInputs}>
        <div className={styles.searchPageFormInputsRow}>
          <Controller
            control={control}
            name="search"
            render={({ field }) => (
              <FieldGroup
                className={styles.searchPageFormSearchInput}
                placeholder="Search"
                {...field}
              />
            )}
          />
          <Controller
            control={control}
            name="date"
            render={({ field: { onChange, value, ...field } }) => (
              <CustomRangePicker
                endDate={value?.endDate}
                placeholderText="Date from/to"
                startDate={value?.startDate}
                wrapperClassName={styles.searchPageFormDateInput}
                onChange={([startDate, endDate]) => onChange({ startDate, endDate })}
                {...field}
              />
            )}
          />
          <Controller
            control={control}
            name="author"
            render={({ field }) => (
              <MultiSelect {...field} options={authorOptions} placeholder="Author" />
            )}
          />
          <Button disabled={disabled} onClick={handleSubmit(handleSearch)}>
            Search
          </Button>
        </div>
        <div className={styles.searchPageFormInputsRow}>
          <LoadingSelect onLoad={getActis}>
            <Controller
              control={control}
              name="actis"
              render={({ field }) => (
                <MultiSelect {...field} options={actisOptions} placeholder="Actis" />
              )}
            />
          </LoadingSelect>
          <LoadingSelect onLoad={getCostCodes}>
            <Controller
              control={control}
              name="costCode"
              render={({ field }) => (
                <MultiSelect {...field} options={costCodesOptions} placeholder="Cost Codes" />
              )}
            />
          </LoadingSelect>
          <Controller
            control={control}
            name="label"
            render={({ field }) => (
              <MultiSelect {...field} options={labelOptions} placeholder="Label" />
            )}
          />
          <Controller
            control={control}
            name="role"
            render={({ field }) => (
              <MultiSelect {...field} options={rolesOptions} placeholder="Role" />
            )}
          />
        </div>
        {canViewArea && (
          <div className={styles.searchPageFormInputsRow}>
            <LoadingSelect onLoad={getAreas}>
              <Controller
                control={control}
                name="area"
                render={({ field }) => (
                  <MultiSelect {...field} options={areaOptions} placeholder="Area" />
                )}
              />
            </LoadingSelect>
            <Controller
              control={control}
              name="subarea"
              render={({ field }) => (
                <MultiSelect {...field} options={subareaOptions} placeholder="Subarea" />
              )}
            />
          </div>
        )}
      </div>
      <div className={styles.searchPageFormSections}>
        <button onClick={() => setExpanded((prev) => !prev)}>
          <i className={clsx('bi bi-arrow-down-circle', expanded && 'expanded')} />
        </button>
        {sections.map((section) => (
          <SearchFormFilterSection
            expanded={expanded}
            key={section.name}
            sectionName={section.name}
            state={sectionStates[section.name]}
            onOptionChange={handleOptionToggle}
            {...section}
          />
        ))}
      </div>
    </div>
  );
};

export default SearchForm;
