import 'react-datepicker/dist/react-datepicker.css';

import FieldGroup, { FieldGroupProps } from 'components/FieldGroup';
import React, { forwardRef, useRef } from 'react';

import { Button } from 'react-bootstrap';
import DatePicker from 'react-datepicker';
import type { ReactDatePickerProps } from 'react-datepicker';
import clsx from 'clsx';
import { createDateFormat } from 'utils/createDateFormat';
import styles from './CustomDatePicker.module.scss';
import { useGetUserConfig } from 'redux/userConfig/userConfigSlice';

// eslint-disable-next-line react/display-name
const CustomInput = forwardRef((props: any, ref) => <FieldGroup ref={ref} {...props} />);

interface CustomReactDatePickerProps<WithRange>
  extends Omit<ReactDatePickerProps, 'selectsRange' | 'onChange'> {
  selectsRange?: WithRange;
  onChange(
    date: WithRange extends false | undefined ? Date | null : [Date | null, Date | null],
    event: React.SyntheticEvent<any> | undefined,
  ): void;
  InputProps?: FieldGroupProps;
  wrapperClassName?: string;
  placeholderText?: string;
  error?: string;
  format?: 'dateTime' | 'date';
  showConfirmBtn?: boolean;
}

export const CustomDatePicker = (props: CustomReactDatePickerProps<false>) => {
  const { dateFormat, hourFormat } = useGetUserConfig();
  const startRef = useRef<any>();

  const handleClose = () => {
    startRef.current?.setOpen(false);
  };

  return (
    <div className={clsx(props.wrapperClassName, styles.customDateWrapper)}>
      <DatePicker
        calendarStartDay={1}
        className={props.wrapperClassName}
        customInput={<CustomInput {...props.InputProps} />}
        dateFormat={createDateFormat({
          formatType: props.format ?? 'date',
          dateFormat,
          hourFormat,
        })}
        ref={startRef}
        shouldCloseOnSelect={!props.showConfirmBtn}
        {...props}>
        {props.showConfirmBtn && (
          <div className={styles.actions}>
            <Button onClick={handleClose}>OK</Button>
          </div>
        )}
      </DatePicker>
      <div className={styles.inputError}>{props.error}</div>
    </div>
  );
};
