import * as yup from 'yup';

import { EventCreationFormData } from 'components/EventsPanel/NewEventCreation';

export const eventFormSchema: yup.SchemaOf<EventCreationFormData> = yup
  .object({
    desc: yup.string().required().max(200),
    startDate: yup.mixed().required(),
    endDate: yup.mixed().required(),
    // dateRange: yup.object({
    //   start: yup.mixed().required(),
    //   end: yup.mixed().required(),
    // }),
  })
  .required();
