/* eslint-disable max-statements */
import './App.scss';
import 'react-toastify/dist/ReactToastify.css';

import { ActionsPage, ActivitiesPage, DecisionsPage, EventsPage, NeedToKnowsPage } from 'pages';
import {
  ActivityHistoryPage,
  ArchivedIdeasPage,
  IdeasPage,
  SamlLogin,
  SearchPage,
} from './containers';
import React, { useEffect } from 'react';
import { Route, useHistory } from 'react-router-dom';
import {
  clearNewUnarchivedActivieties,
  useGetCurrentReport,
  useGetHasReportError,
  useGetNewUnarchivedActivities,
} from 'redux/report/reportSlice';
import {
  setLocationBeforeLogin,
  useGetLocationBeforeLogin,
  useGetToken,
} from 'redux/session/sessionSlice';
import {
  setSelectedOrganisation,
  setSelectedSection,
  useGetCurrentOrganisation,
  useGetCurrentSection,
  useGetSections,
} from 'redux/organisation/organisationSlice';
import {
  useGetAllOrganisationLabelsApi,
  useGetOrganisationAreaApi,
  useGetOrganisationsApi,
} from 'utils/hooks/organisation';

import AdminPage from 'containers/AdminPage';
import ArchivedProjectsPage from 'containers/ArchivedProjectsPage';
import InstructionPage from 'pages/InstructionPage';
import NoReportUnitPage from 'pages/NoReportUnitPage';
import { Organisation } from 'types/Report';
import Sidebar from 'containers/Sidebar';
import StartPage from 'pages/StartPage';
import StatisticsPage from 'containers/StatisticsPage';
import { ToastContainer } from 'react-toastify';
import { connectReportSocket } from 'redux/report/reportSocketMiddleware';
import { fetchConfig as fetchConfigActions } from 'redux/userConfig/userConfigActions';
import { getLatestReport as getLatestReportAction } from 'redux/report/reportActions';
import { pages } from 'pages/consts';
import { useAppDispatch } from 'redux/hooks';
import { useFetchData } from 'utils/fetchData';
import { useIsFirstLoaded } from 'utils/hooks/isFirstLoaded';

const App = () => {
  const history = useHistory();
  const token = useGetToken();
  const locationBeforeLogin = useGetLocationBeforeLogin();
  const dispatch = useAppDispatch();
  const report = useGetCurrentReport();
  const organisation = useGetCurrentOrganisation();
  const section = useGetCurrentSection();
  const sections = useGetSections();

  const getLatestReport = useFetchData(getLatestReportAction, { model: 'Current Report' });
  const getOrganisations = useGetOrganisationsApi();
  const fetchConfig = useFetchData(fetchConfigActions, { model: 'Config' });
  const getOrganisationLabels = useGetAllOrganisationLabelsApi();
  const getOrganisationAreas = useGetOrganisationAreaApi();
  const newUnarchivedActivities = useGetNewUnarchivedActivities();
  const hasErrorReport = useGetHasReportError();
  const [isFirstReportLoading, setIsFirstReportLoading] = useIsFirstLoaded();

  useEffect(() => {
    const location = history.location.pathname;
    // Redirect to /login if user is not logged in
    if (!token && !location.includes(pages.loginPage)) {
      dispatch(setLocationBeforeLogin(location));
      history.push(pages.loginPage);
    }

    // Redirect back to origin path after logging in
    if (token && locationBeforeLogin && locationBeforeLogin !== location) {
      dispatch(setLocationBeforeLogin(null));
      history.push(locationBeforeLogin);
    }

    if (token) {
      fetchConfig();
    }
  }, [token]);

  const handleSetDefaultSection = () => {
    const hasSelectedUnit = organisation?.reportingUnits.find(({ id }) => id === section?.id);

    if ((!hasSelectedUnit && section) || !section) {
      if (organisation?.reportingUnits?.length) {
        dispatch(setSelectedSection(organisation?.reportingUnits[0]?.id));
      } else {
        dispatch(setSelectedSection(undefined));
        history.push(pages.noReportUnitPage);
      }
    } else {
      if (history.location.pathname.includes(pages.noReportUnitPage)) {
        history.push(pages.activitiesPage);
      }
    }
  };

  useEffect(() => {
    if (token) {
      const promiseOrganisations = getOrganisations();
      promiseOrganisations.then((action) => {
        const organisations = action.payload as Organisation[];
        if (organisations?.length && !organisation) {
          dispatch(setSelectedOrganisation(organisations[0].id));
        }
      });

      return () => {
        promiseOrganisations.abort();
      };
    }
  }, [token?.refreshToken]);

  useEffect(() => {
    if (token && organisation) {
      const promiseLabels = getOrganisationLabels(organisation.id);

      return () => {
        promiseLabels.abort();
      };
    }
  }, [token?.refreshToken, organisation?.id]);

  useEffect(() => {
    if (section) {
      const promiseAreas = getOrganisationAreas();

      return () => {
        promiseAreas.abort();
      };
    }
  }, [section?.id]);

  useEffect(() => {
    if (token && sections?.length && organisation?.id) {
      handleSetDefaultSection();
    }
  }, [token?.refreshToken, sections, organisation?.id]);

  useEffect(() => {
    if (token && section) {
      const promise = getLatestReport();
      promise.finally(() => {
        setIsFirstReportLoading(false);
      });

      return () => promise?.abort();
    }
  }, [token?.refreshToken, section?.id]);

  useEffect(() => {
    if (report && token) {
      dispatch(connectReportSocket(report.id, token?.accessToken));
    }
  }, [token?.refreshToken, report?.id]);

  useEffect(() => {
    if (report && token && newUnarchivedActivities.length > 0) {
      dispatch(clearNewUnarchivedActivieties());
    }
  }, [token?.refreshToken, report?.id, newUnarchivedActivities]);

  const isLoginPath = history.location.pathname.includes(pages.loginPage);

  if (!token && !isLoginPath) {
    return <StartPage />;
  }

  if (isFirstReportLoading && !hasErrorReport && !isLoginPath) {
    return <StartPage pageLabel="" />;
  }

  return (
    <div className="AppContainer">
      {!isLoginPath && <Sidebar />}
      <div className="AppContainerContent">
        <Route
          path={pages.ideasPage}
          render={() => <IdeasPage goBack={() => history.push(pages.activitiesPage)} />}
          exact
        />
        <Route component={SamlLogin} path={pages.loginPage} />
        <Route path={pages.archivedIdeasPage} render={() => <ArchivedIdeasPage />} />
        <Route path={pages.adminPage} render={() => <AdminPage />} />
        <Route path={pages.instructionPage} render={() => <InstructionPage />} />
        <Route path={pages.searchPage} render={() => <SearchPage />} />
        {(report?.id || hasErrorReport) && (
          <>
            <Route path={pages.activityHistoryPage} render={() => <ActivityHistoryPage />} />
            <Route path={pages.activitiesPage} render={() => <ActivitiesPage />} exact />
            <Route path={pages.decisionsPage} render={() => <DecisionsPage />} />
            <Route path={pages.actionsPage} render={() => <ActionsPage />} />
            <Route path={pages.needToKnowsPage} render={() => <NeedToKnowsPage />} />
            <Route path={pages.eventsPage} render={() => <EventsPage />} />
            <Route path={pages.archivedActivitiesPage} render={() => <ArchivedProjectsPage />} />
            <Route path={pages.statisticsPage} render={() => <StatisticsPage />} />
          </>
        )}
        <Route path={pages.noReportUnitPage} render={() => <NoReportUnitPage />} />
      </div>
      <ToastContainer
        autoClose={5000}
        hideProgressBar={false}
        newestOnTop={false}
        pauseOnHover={false}
        position="top-right"
        rtl={false}
        theme="light"
      />
    </div>
  );
};

export default App;
