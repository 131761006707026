import { REFRESH_TOKEN_QUERY, SAML_LOGIN } from './const';

import { API_URL } from '../const';
import { RefreshTokenResponse } from './../../types/Session';
import apiCall from '../apiCall';
import { createAsyncThunk } from '@reduxjs/toolkit';

export const fetchNewToken = createAsyncThunk('session/refreshToken', async (_, { getState }) => {
  const refresh = getState().session.token?.refreshToken;
  const response = await apiCall.post<RefreshTokenResponse>(REFRESH_TOKEN_QUERY, { refresh });

  return response.data;
});

export const loginSaml = createAsyncThunk('session/saml/login', () =>
  window.location.replace(`${API_URL}/${SAML_LOGIN}`),
);
