import { Button, ListGroup } from 'react-bootstrap';
import React, { useEffect } from 'react';

import { Link } from 'react-router-dom';
import { Project } from 'types/Report';
import { activityHistoryUrl } from './consts';
import clsx from 'clsx';
import styles from './ArchivedProjectsPage.module.scss';

interface ArchivedProjectsPageProps {
  projects: Project[];
  getArchivedProjects: () => any;
  unarchive: (id: number) => void;
}

const ArchivedProjectsPage = ({
  projects,
  getArchivedProjects,
  unarchive,
}: ArchivedProjectsPageProps) => {
  useEffect(() => {
    const promise = getArchivedProjects();

    return () => promise.abort();
  }, []);

  return (
    <div className="standard-container-wrapper">
      <div className={clsx(['standard-container', styles.archivedProjectsPage])}>
        {projects.length ? (
          <ListGroup className={styles.listGroup}>
            {projects.map((project) => (
              <Link key={project.id} to={activityHistoryUrl(project.id)}>
                <ListGroup.Item className={styles.item}>
                  <b>{`${project.title}`}</b>
                  {` - ${project.area.name}${project.subarea && ` - ${project.subarea.name}`} - ${
                    project.author.firstName
                  } ${project.author.lastName}`}
                  <Button
                    className={styles.unarchive}
                    onClick={(event) => {
                      event.preventDefault();
                      event.stopPropagation();
                      unarchive(project.id);
                    }}>
                    Unarchive
                  </Button>
                </ListGroup.Item>
              </Link>
            ))}
          </ListGroup>
        ) : (
          <div className={styles.noneArchivedInfo}>None of the projects have been archived</div>
        )}
      </div>
    </div>
  );
};

export default ArchivedProjectsPage;
