import { Button, Modal } from 'react-bootstrap';
import React, { useEffect, useMemo, useState } from 'react';
import { filterIdeas, getTypesOption, getUserOptions } from 'utils/ideaHelpers';

import CreateNewIdeaPage from 'containers/CreateNewIdeaPage';
import EditIdeaPage from 'containers/EditIdeaPage';
import { Idea } from 'types/Ideas';
import IdeasSearch from 'containers/IdeasSearch';
import IdeasTable from 'components/IdeasTable';
import { MultiSelectOption } from 'components/MultiSelect/MultiSelect';
import { RangeDate } from 'components/CustomDatePicker';
import createColumns from './createColumns';
import styles from './IdeasPage.module.scss';

type Props = {
  ideas: Idea[];
  getIdeas: () => any;
  archiveIdea?: (id: number) => any;
  removeIdea?: (id: number) => any;
  canAdd: boolean;
  canEdit: boolean;
  canDelete: boolean;
  canArchive: boolean;
  canViewIdea: boolean;
};

const Ideas = ({
  ideas,
  getIdeas,
  archiveIdea,
  removeIdea,
  canAdd,
  canEdit,
  canDelete,
  canArchive,
  canViewIdea,
}: Props) => {
  const [showCreateForm, setShowCreateForm] = useState(false);
  const [editIdea, setEditIdea] = useState<number | null>(null);
  const [dates, setDates] = useState<RangeDate>({
    startDate: null,
    endDate: null,
  });
  const [selectedUsers, setSelectedUsers] = useState<MultiSelectOption[]>([]);
  const [selectedTypes, setSelectedTypes] = useState<MultiSelectOption[]>([]);
  const [search, setSearch] = useState<string>('');
  useEffect(() => {
    if (canViewIdea) {
      const promise = getIdeas();

      return () => promise.abort();
    }
  }, []);

  const onIdeaEdit = (data) => setEditIdea(data.id);

  const userOptions = useMemo(() => getUserOptions(ideas), [ideas]);
  const columns = useMemo(
    () =>
      createColumns({
        archiveIdea: canArchive ? archiveIdea : undefined,
        onIdeaEdit: canEdit ? onIdeaEdit : undefined,
        removeIdea: canDelete ? removeIdea : undefined,
      }),
    [],
  );
  const data = useMemo(
    () => filterIdeas(ideas, { search, dates, selectedUsers, selectedTypes }),
    [ideas, search, selectedUsers, dates, selectedTypes],
  );

  const typeOptions = useMemo(() => getTypesOption(), []);

  return (
    <>
      <div className={styles.ideasPage}>
        <div className={styles.ideasPageMenu}>
          {canViewIdea ? (
            <IdeasSearch
              dates={dates}
              selectedTypes={selectedTypes}
              selectedUsers={selectedUsers}
              setDates={setDates}
              setSearchText={setSearch}
              setSelectedTypes={setSelectedTypes}
              setSelectedUsers={setSelectedUsers}
              typeOptions={typeOptions}
              userOptions={userOptions}
            />
          ) : (
            <div />
          )}
          <Button
            className={styles.ideasPageMenuAddButton}
            disabled={!canAdd}
            onClick={() => setShowCreateForm(true)}>
            Add New Idea
          </Button>
        </div>
        <IdeasTable columns={columns} ideas={canViewIdea ? data : []} />
      </div>
      <Modal show={showCreateForm} size="lg" centered onHide={() => setShowCreateForm(false)}>
        <CreateNewIdeaPage onClose={() => setShowCreateForm(false)} />
      </Modal>
      <Modal show={!!editIdea} size="lg" centered onHide={() => setEditIdea(null)}>
        <EditIdeaPage id={editIdea} onClose={() => setEditIdea(null)} />
      </Modal>
    </>
  );
};

export default Ideas;
