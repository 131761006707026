import { baseURL } from './apiCall';
import extractToken from '../utils/extractToken';
import { fetchNewToken } from './session/sessionActions';
import fromUnixTime from 'date-fns/fromUnixTime';
import isAfter from 'date-fns/isAfter';
import isValid from 'date-fns/isValid';
import store from './store';

export default async (url: RequestInfo, options?: RequestInit) => {
  let accessToken = store.getState().session.token?.accessToken;
  const readyUrl = new URL(`${baseURL}${url}`);
  if (accessToken) {
    const exp = extractToken(accessToken)?.expirationDate;
    const expirationDate = exp && fromUnixTime(exp);
    const isExpired =
      expirationDate && isValid(expirationDate) && isAfter(new Date(), expirationDate);

    if (isExpired) {
      await store.dispatch(fetchNewToken());
      accessToken = store.getState().session.token?.accessToken;
    }
  }

  return fetch(readyUrl.toString(), {
    ...(options || {}),
    headers: { ...(options?.headers || {}), Authorization: `Bearer ${accessToken}` },
  }).then(async (response) => {
    const file = await response.blob();

    return {
      ...response,
      status: response.status,
      file,
      filename: response.headers.get('content-disposition')?.split(';')[1]?.split('=')[1],
    };
  });
};
