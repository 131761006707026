/* eslint-disable react/jsx-max-depth */
/* eslint-disable max-statements */

import { Badge, Button } from 'react-bootstrap';
import { Comment as CommentType, User } from '../../types/Report';
import React, { useState } from 'react';

import { ReactComponent as CloseIcon } from 'assets/icons/status/close.svg';
import DateFormatter from '../../containers/DateFormatter';
import Edit from 'assets/icons/edit.svg';
import EditComment from 'containers/EditComment';
import Status from '../Status';
import Trash from 'assets/icons/trash.svg';
import clsx from 'clsx';
import { isLessThanDay } from 'utils/timeUtils';
import styles from './Comment.module.scss';

export interface CommentBaseProps {
  comment: CommentType;
  isLocked?: boolean;
  simpleView?: boolean;
}

interface Props extends CommentBaseProps {
  onDelete?: () => void;
  user: User | null;
  canEdit: boolean;
}

export const Comment = ({ comment, onDelete, user, isLocked, simpleView, canEdit }: Props) => {
  const [isEditing, setIsEditing] = useState(false);
  const [showPreviousComment, setShowPreviousComment] = useState(false);
  if (isEditing) {
    return (
      <div className={styles.comment}>
        <Button
          className={styles.cancelBtn}
          variant="icon-small"
          onClick={() => setIsEditing(false)}>
          <CloseIcon />
        </Button>
        <div className={styles.commentEdit}>
          <EditComment
            comment={comment}
            uniqueId={`${comment.activity}-${comment.id}`}
            onClose={() => setIsEditing(false)}
          />
        </div>
      </div>
    );
  } else {
    return (
      <div className={clsx(styles.comment)} data-testid="comment">
        <main className={styles.main}>
          <header className="d-flex gap-2 align-items-center">
            <Status className={styles.status} level={comment.status} />
            <span className={styles.author}>{`${comment?.author?.firstName || ''} ${
              comment?.author?.lastName || ''
            }`}</span>
            {!simpleView && (
              <>
                <span className={styles.date}>
                  <DateFormatter date={comment.created} formatType={'dateTime'} />
                </span>
                <span className={styles.dateModified}>
                  {' - modified: '}
                  <DateFormatter date={comment.modified} formatType={'dateTime'} />
                </span>
                {comment.previous && (
                  <button
                    className={clsx(['clear-button', styles.projectIconButton])}
                    title="Show a previous version of the comment"
                    onClick={() => {
                      setShowPreviousComment((isVisible) => !isVisible);
                    }}>
                    <i className={`bi ${showPreviousComment ? 'bi-eye-slash' : 'bi-eye'}`}></i>
                  </button>
                )}
                {isLessThanDay(comment.modified) && (
                  <Badge bg="primary" className={clsx('badge-xs', styles.badgeNew)}>
                    {'New'}
                  </Badge>
                )}
              </>
            )}
          </header>
          <main className={clsx(['d-flex', 'gap-1', 'py-2', styles.contentWrapper])}>
            <span className={styles.content}>{comment.content}</span>
          </main>
          <footer>
            <div className="d-flex gap-1">
              {comment?.tags?.action && (
                <Badge bg="action" role="status">
                  {'Action request'}
                </Badge>
              )}
              {comment?.tags?.decision && (
                <Badge bg="decision" role="status">
                  {'Decision request'}
                </Badge>
              )}
              {comment?.tags?.needToKnow && (
                <Badge bg="needToKnow" role="status">
                  {'Need to know'}
                </Badge>
              )}
            </div>
            <div className="d-flex gap-1 mt-2">
              {comment?.labels?.map((label) => (
                <Badge bg="label" key={label.id} role="status">
                  {label.name}
                </Badge>
              ))}
            </div>
          </footer>
          {showPreviousComment && comment.previous && (
            <div className={clsx(['py-3', styles.previousComment])}>
              <div className={styles.previousStatus}>
                <Status className={styles.status} level={comment.previous.status} />A previous
                version of the comment
              </div>
              <div className={clsx(['d-flex', 'gap-1', 'py-3', styles.contentWrapper])}>
                <span className={styles.previousContent}>{comment.previous.content}</span>
              </div>
              <footer>
                <div className="d-flex gap-1">
                  {comment?.previous?.tags?.action && (
                    <Badge bg="label" role="status">
                      {'Action request'}
                    </Badge>
                  )}
                  {comment?.previous?.tags?.decision && (
                    <Badge bg="label" role="status">
                      {'Decision request'}
                    </Badge>
                  )}
                  {comment?.previous?.tags?.needToKnow && (
                    <Badge bg="label" role="status">
                      {'Need to know'}
                    </Badge>
                  )}
                </div>
                <div className="d-flex gap-1 mt-2">
                  {comment?.previous?.labels?.map((label) => (
                    <Badge bg="label" key={label.id} role="status">
                      {label.name}
                    </Badge>
                  ))}
                </div>
              </footer>
            </div>
          )}
        </main>
        {user?.id === comment.author.id && comment.unit && !isLocked && (
          <aside className={styles.aside}>
            <div className="d-flex gap-3">
              {canEdit && (
                <button
                  className="clear-button"
                  data-testid="edit-comment"
                  onClick={() => setIsEditing(true)}>
                  <img alt="edit" src={Edit} />
                </button>
              )}
              {onDelete && (
                <button className="clear-button" data-testid="delete-comment" onClick={onDelete}>
                  <img alt="trash" src={Trash} />
                </button>
              )}
            </div>
          </aside>
        )}
      </div>
    );
  }
};
