import type { AxiosInstance } from 'axios';
import { REFRESH_TOKEN_QUERY } from '../redux/session/const';
import extractToken from '../utils/extractToken';
import { fetchNewToken } from '../redux/session/sessionActions';
import fromUnixTime from 'date-fns/fromUnixTime';
import isAfter from 'date-fns/isAfter';
import isValid from 'date-fns/isValid';
import store from '../redux/store';
// TODO: I think there is better way to prevent repeated token refresh
let pendingRefreshToken;
export default (axiosInstance: AxiosInstance) =>
  axiosInstance.interceptors.request.use(async (config) => {
    const accessToken = store.getState?.()?.session?.token?.accessToken;
    const exp = extractToken(accessToken)?.expirationDate;
    const expirationDate = exp && fromUnixTime(exp);
    const isExpired =
      expirationDate && isValid(expirationDate) && isAfter(new Date(), expirationDate);

    if (isExpired && accessToken && !config.url?.includes(REFRESH_TOKEN_QUERY)) {
      if (!pendingRefreshToken) {
        pendingRefreshToken = store.dispatch(fetchNewToken());
      }
      await pendingRefreshToken;
      pendingRefreshToken = undefined;
    }

    return config;
  });
