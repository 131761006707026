import { EventCreate } from 'redux/report/types';
import { default as NewEventCreationComponent } from '../components/EventsPanel/NewEventCreation';
import React from 'react';
import { createEvent as createEventAction } from '../redux/report/reportActions';
import { useFetchDataWithResult } from 'utils/fetchData';
import { useGetEditable } from '../redux/report/reportSlice';

interface NewEventCreationProps {
  canAdd: boolean;
}

const NewEventCreation = ({ canAdd }: NewEventCreationProps) => {
  const editable = useGetEditable();

  const createEvent = useFetchDataWithResult<EventCreate>(createEventAction, {
    model: 'Event',
    errorMessage: 'Failed to add event',
    successMessage: 'Event has been added successfully',
  });

  return (
    <NewEventCreationComponent
      createEvent={(desc: string, startDate: Date, endDate: Date) =>
        createEvent({ desc, startDate, endDate })
      }
      editable={editable || canAdd}
    />
  );
};

export default NewEventCreation;
