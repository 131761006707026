import { ActivityHistoryFetch, History, Unit } from 'types/ActivityHistory';
import { Breadcrumb, Spinner } from 'react-bootstrap';
import { Filters, FiltersState } from 'redux/activityHistory/types';
import React, { useEffect, useMemo } from 'react';

import ActivityHistoryFilters from 'components/ActivityHistoryFilters/ActivityHistoryFilters';
import ActivityHistoryTable from 'components/ActivityHistoryTable/ActivityHistoryTable';
import { Project } from 'types/Report';
import { StandardObject } from 'containers/ActivityHistoryPage';
import StatusIcon from 'components/StatusIcon';
import clsx from 'clsx';
import { findHighestStatus } from 'utils/status';
import styles from './ActivityHistoryPage.module.scss';
import { useParams } from 'react-router-dom';

interface ActivityHistoryParams {
  id: string;
}

interface ActivityHistoryPageProps {
  statuses: StandardObject[];
  actions: StandardObject[];
  units: Unit[];
  history: History[];
  filters: FiltersState;
  activity: Project | null;
  setFilters: (filters: Filters) => void;
  getActivity: (id: number) => any;
  getActivityHistory: (params: ActivityHistoryFetch) => any;
  getActivityUnits: (id: number) => any;
  isDataLoading: boolean;
  isActivityLoading: boolean;
}

const ActivityHistoryPage = ({
  statuses,
  actions,
  history,
  units,
  filters,
  setFilters,
  getActivityHistory,
  getActivityUnits,
  getActivity,
  activity,
  isDataLoading,
  isActivityLoading,
}: ActivityHistoryPageProps) => {
  const { id } = useParams<ActivityHistoryParams>();
  const activityId = parseInt(id, 10);

  useEffect(() => {
    const activityPromise = getActivity(activityId);
    const activityUnitsPromise = getActivityUnits(activityId);

    return () => {
      activityPromise.abort();
      activityUnitsPromise.abort();
    };
  }, []);

  useEffect(() => {
    const promise = getActivityHistory({ id: activityId, filters });

    return () => {
      promise.abort();
    };
  }, [filters]);

  const highestStatus = useMemo(() => {
    const allComments = history.flatMap((entry) => entry.activityComments);

    return findHighestStatus(allComments);
  }, [history]);

  if (isActivityLoading) {
    return (
      <div className={styles.activityHistoryPageLoading}>
        <Spinner animation="border" />
      </div>
    );
  }

  return (
    <div className={styles.activityHistoryPage}>
      <div className={clsx(['shadow-sm', styles.activityHistoryPageInner])}>
        <header className={styles.header}>
          <div>
            <Breadcrumb>
              <Breadcrumb.Item className={styles.breadCrumbArea}>
                {activity?.area.name}
              </Breadcrumb.Item>
              <Breadcrumb.Item className={styles.breadCrumbSubArea}>
                {activity?.subarea.name}
              </Breadcrumb.Item>
              <Breadcrumb.Item active>
                <div className={styles.breadCrumbTitle}>
                  <StatusIcon level={highestStatus} /> {activity?.title}
                </div>
              </Breadcrumb.Item>
            </Breadcrumb>
          </div>
          <ActivityHistoryFilters
            actions={actions}
            disabledSubmit={isDataLoading}
            filters={filters}
            statuses={statuses}
            units={units}
            onSubmit={setFilters}
          />
        </header>
        {isDataLoading ? (
          <div className={styles.activityHistoryPageLoading}>
            <Spinner animation="border" />
          </div>
        ) : (
          <main className={styles.tableScrollable}>
            <ActivityHistoryTable data={history} units={units} />
          </main>
        )}
      </div>
    </div>
  );
};

export default ActivityHistoryPage;
