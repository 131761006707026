import React from 'react';
import { toast } from 'react-toastify';

interface NotificationFunc {
  success: (message: string) => void;
  error: (message: string, title: string) => void;
}

const ErrorMsg = ({ data }) => {
  return (
    <div>
      <div>
        <strong>{data.title}</strong>
      </div>
      <div>{data.text}</div>
    </div>
  );
};

export const useNotification = (): NotificationFunc => {
  return {
    success: (message: string) => toast.success(message),
    error: (message: string, title: string) =>
      toast.error(ErrorMsg, {
        data: {
          title: title,
          text: message,
        },
      }),
  };
};
