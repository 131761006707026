import {
  useCheckOneOfPermissions,
  useGetOrganisationAreas,
  useIsOrganisationAreasLoading,
} from 'redux/organisation/organisationSlice';
import {
  useGetCurrentReport,
  useGetEditable,
  useGetIsReportLoading,
} from '../redux/report/reportSlice';

import { default as AreasPanelComponent } from '../components/AreasPanel/AreasPanel';
import React from 'react';
import { UserPermission } from 'redux/organisation/types';
import { useAllCommentCopyPreviousApi } from 'utils/hooks/report';
import { useAreaVisibilityData } from 'utils/reportHelpers';

const AreasPanel = (props) => {
  const areas = useGetOrganisationAreas();
  const isAreasLoading = useIsOrganisationAreasLoading();
  const isReportLoading = useGetIsReportLoading();
  const report = useGetCurrentReport();
  const canCopyComments = useCheckOneOfPermissions(UserPermission.ADD_COMMENT);

  const visibilityData = useAreaVisibilityData(report?.activities || []);

  const copyPreviousComments = useAllCommentCopyPreviousApi();
  const isEditable = useGetEditable();

  const isLoading = isReportLoading || (isAreasLoading && areas.length === 0);

  return (
    <AreasPanelComponent
      {...props}
      areas={areas}
      copyPreviousComments={copyPreviousComments}
      isLoading={isLoading}
      report={report}
      showMenu={isEditable && !report?.locked && canCopyComments}
      visibilityData={visibilityData}
    />
  );
};

export default AreasPanel;
