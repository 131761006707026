import {
  NewRemarkCreationBaseProps,
  default as NewRemarkCreationComponent,
} from '../components/NewRemarkCreation';
import { RemarkCreate, RemarkPath } from '../redux/report/types';

import React from 'react';
import { capitalize } from 'lodash';
import { createRemark as createRemarkAction } from '../redux/report/reportActions';
import { useFetchData } from 'utils/fetchData';

interface NewRemarkCreationContainerProps extends NewRemarkCreationBaseProps {
  path: RemarkPath;
  canAdd: boolean;
}

const getTagTypeName = (path: RemarkPath): string => {
  if (path == 'needToKnows') {
    return 'need to know';
  } else if (path == 'actions') {
    return 'action';
  } else if (path == 'decisions') {
    return 'decision';
  }

  return path;
};

const NewRemarkCreation = ({ path, canAdd, ...props }: NewRemarkCreationContainerProps) => {
  const tagTypeName = getTagTypeName(path);
  const remarkTitle = capitalize(tagTypeName);

  const createRemark = useFetchData<RemarkCreate>(createRemarkAction, {
    model: remarkTitle,
    errorMessage: `Failed to add ${tagTypeName}`,
    successMessage: `${remarkTitle} has been added successfully`,
  });

  return (
    <NewRemarkCreationComponent
      {...props}
      createRemark={(desc: string) => createRemark({ desc, path })}
      editable={canAdd}
    />
  );
};

export default NewRemarkCreation;
