import { Area, CostCodeList, FullActisList, Project, SubArea } from 'types/Report';
import React, { ElementType, useMemo } from 'react';

import CustomForm from 'components/CustomForm';
import { NewProject } from 'redux/report/types';
import { UseFormWatch } from 'react-hook-form';
import { activityFormSchema } from 'schemas/ActivityForm';

interface ModifiedProject extends Omit<Project, 'area' | 'subarea' | 'actis' | 'costCode'> {
  area: number;
  subarea: number;
  actis?: number;
  costCode?: number;
}

interface ActivityFormPageProps {
  areas: Area[];
  onSubmit: (data: NewProject) => Promise<void>;
  initialState?: ModifiedProject;
  title: string;
  onCancel: () => void;
  actis: FullActisList;
  costCodes: CostCodeList;
  getActis: () => Promise<any>;
  getCostCodes: () => Promise<any>;
  getAreas: () => Promise<any>;
}

const ActivityFormPage = ({
  areas,
  initialState,
  onSubmit,
  title,
  onCancel,
  actis,
  costCodes,
  getActis,
  getCostCodes,
  getAreas,
}: ActivityFormPageProps) => {
  const sections = useMemo(
    () => [
      { label: 'Activity name', name: 'title', required: true, column: 1 },
      {
        label: 'Area',
        name: 'area',
        type: 'select' as ElementType<any>,
        required: true,
        getOptionValue: (option: Area) => option.id,
        getOptionLabel: (option: Area) => option.name,
        options: areas.map((area) => ({ name: area.name, id: area.id })),
        column: 1,
        fetchDataFunc: getAreas,
      },
      {
        label: 'Cost code',
        name: 'costCode',
        type: 'select' as ElementType<any>,
        column: 2,
        getOptionValue: (option: SubArea) => option.id,
        getOptionLabel: (option: SubArea) => option.name,
        getOptions: () => costCodes,
        fetchDataFunc: getCostCodes,
      },
      {
        label: 'Subarea',
        name: 'subarea',
        type: 'select' as ElementType<any>,
        required: true,
        column: 2,
        getOptionValue: (option: SubArea) => option.id,
        getOptionLabel: (option: SubArea) => option.name,
        getOptions: (watch: UseFormWatch<any>) => {
          const areaId = watch('area');

          return areas.find((area) => area.id === parseInt(areaId, 10))?.subareas || [];
        },
      },
      {
        label: 'Actis',
        name: 'actis',
        type: 'select' as ElementType<any>,
        column: 1,
        getOptionValue: (option) => option.id,
        getOptionLabel: (option) => option.actisId,
        getOptions: () => actis,
        fetchDataFunc: getActis,
      },
    ],
    [areas, actis],
  );

  return (
    <CustomForm
      columns={2}
      initialState={initialState}
      schema={activityFormSchema}
      sections={sections}
      title={title}
      resultValidate
      onCancel={onCancel}
      onSubmit={onSubmit}
    />
  );
};

export default ActivityFormPage;
