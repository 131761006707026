import { USER_CONFIG, USER_CONFIG_QUERY } from './const';

import { UserConfig } from '../../types/userConfig';
import apiCall from '../apiCall';
import { camelizeKeys } from 'humps';
import { createAsyncThunk } from '@reduxjs/toolkit';
import { validateResponse } from '../../utils/validateResponse';

export const fetchConfig = createAsyncThunk(`${USER_CONFIG}/fetchConfig`, async (_, { signal }) => {
  const response = await apiCall.get<UserConfig>(USER_CONFIG_QUERY, { signal });
  const data = await response.data;
  const validatedData = validateResponse(UserConfig, camelizeKeys(data) as UserConfig);

  return validatedData;
});

export const updateConfig = createAsyncThunk(
  `${USER_CONFIG}/updateConfig`,
  async (newConfig: UserConfig) => {
    const response = await apiCall.patch<UserConfig>(USER_CONFIG_QUERY, newConfig);
    const data = await response.data;
    const validatedData = validateResponse(UserConfig, camelizeKeys(data) as UserConfig);

    return validatedData;
  },
);
