import {
  RemarkData,
  RemarksPanelBaseProps,
  default as RemarksPanelComponent,
} from '../components/RemarksPanel';
import { RemarkWithProject, Tags } from 'types/Report';
import {
  changeListMode,
  useGetAllRemarksFromProjects,
  useGetEditable,
  useGetIsReportLoading,
  useGetRemarks,
} from '../redux/report/reportSlice';
import {
  deleteProjectComment as deleteProjectCommentAction,
  removeRemark as removeRemarkAction,
  updateProjectComment as updateProjectCommentAction,
  updateRemark as updateRemarkAction,
} from 'redux/report/reportActions';

import React from 'react';
import { TagType } from 'redux/report/types';
import { capitalize } from 'lodash';
import { useAppDispatch } from 'redux/hooks';
import { useFetchData } from 'utils/fetchData';
import { useHistory } from 'react-router-dom';

const getTagTypeName = (tagType: TagType): string => {
  if (tagType == 'needToKnow') {
    return 'need to know';
  }

  return tagType;
};

interface RemarksPanelProps extends RemarksPanelBaseProps {
  canEdit: boolean;
  canDelete: boolean;
  canAdd: boolean;
}

const RemarksPanel = ({ tagType, canAdd, canEdit, canDelete, ...props }: RemarksPanelProps) => {
  const history = useHistory();
  const dispatch = useAppDispatch();

  const remarks = useGetRemarks(props.path);
  const remarksFromComments = useGetAllRemarksFromProjects(tagType, props.path);
  const isLoading = useGetIsReportLoading();

  const editable = useGetEditable();

  const tagTypeName = getTagTypeName(tagType);
  const remarkTitle = capitalize(tagTypeName);

  const removeRemark = useFetchData(removeRemarkAction, {
    model: remarkTitle,
    errorMessage: `Failed to remove ${tagTypeName}`,
    successMessage: `${remarkTitle} has been removed successfully`,
  });

  const updateRemark = useFetchData(updateRemarkAction, {
    model: remarkTitle,
    errorMessage: `Failed to update ${tagTypeName}`,
    successMessage: `${remarkTitle} has been updated successfully`,
  });

  const deleteProjectComment = useFetchData(deleteProjectCommentAction, {
    model: 'Comment',
    errorMessage: 'Failed to remove comment',
    successMessage: 'Comment has been removed successfully',
  });

  const updateProjectComment = useFetchData(updateProjectCommentAction, {
    model: 'Comment',
    errorMessage: 'Failed to update comment',
    successMessage: 'Comment has been updated successfully',
  });

  const remarksWithUniqueId: RemarkData[] = remarks.map((remark) => ({
    id: `remark-${remark.id}`,
    isFromComment: false,
    data: remark,
  }));

  const commentsWithUniqueId: RemarkData[] = remarksFromComments.map((comment) => ({
    id: `comment-${comment.id}`,
    isFromComment: true,
    data: comment,
  }));

  const handleRemoveRemark = (item: RemarkData) => {
    if (item.isFromComment) {
      const currentComment = (item.data as RemarkWithProject).comment;

      return deleteProjectComment({
        commentId: currentComment.id,
        projectId: currentComment.activity,
        unit: currentComment.unit,
      });
    } else {
      return removeRemark({ id: item.data.id, path: props.path });
    }
  };

  const handleUpdateRemark = (item: RemarkData, desc: string) => {
    if (item.isFromComment) {
      const currentComment = (item.data as RemarkWithProject).comment;

      return updateProjectComment({
        commentId: currentComment.id,
        content: desc,
        tags: currentComment.tags as Tags,
        unit: currentComment.unit,
        status: currentComment.status,
        projectId: currentComment.activity,
      });
    } else {
      return updateRemark({ id: item.data.id, desc, path: props.path });
    }
  };

  const handelLinkClick = (item: RemarkData) => {
    const currentComment = (item.data as RemarkWithProject).comment;
    dispatch(changeListMode('collapsed'));
    history.push(
      `/?selectedProject=${currentComment.activity}&selectedUnit=${currentComment.unit}`,
    );
  };

  return (
    <RemarksPanelComponent
      {...props}
      canAdd={canAdd && editable}
      isLoading={isLoading}
      remarks={[...remarksWithUniqueId, ...commentsWithUniqueId]}
      removeRemark={canDelete ? handleRemoveRemark : undefined}
      tagType={tagType}
      updateRemark={canEdit ? handleUpdateRemark : undefined}
      onLinkClick={handelLinkClick}
    />
  );
};

export default RemarksPanel;
